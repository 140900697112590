import { typeStatus } from '../typeStatus'
import { TableStyle } from './typeTables'

const constAdminParticipant: TableStyle[] = [
    {
        name: 'Nom',
        isSortable: true,
        filters: null,
        index: 'contact.lastname',
    },
    {
        name: 'Prénom',
        isSortable: true,
        filters: null,
        index: 'contact.firstname',
    },
    {
        name: 'Email',
        isSortable: false,
        filters: null,
        index: 'contact.email',
    },
    {
        name: 'Code postal',
        isSortable: false,
        filters: null,
        index: 'contact.zip_code',
    },
    {
        name: 'Catégorie',
        isSortable: false,
        filters: null,
        index: 'category.name',
    },
    {
        name: "Status de l'adhésion",
        isSortable: false,
        filters: [
            typeStatus.WAITING_FOR_VALIDATION,
            typeStatus.VALIDATED,
            typeStatus.MEMBER_FOR_VALIDATION,
        ],
        index: 'status',
    },

    {
        name: 'Établissement / Club',
        isSortable: false,
        filters: null,
        index: 'establishment.name',
        secondIndex: 'club.name',
    },
]

export default constAdminParticipant
