import { iClubUser, iEstablishementUser } from '../../../types/user'
import Input from '../common/Input'

function verifyUaiCode(codeUai: string): boolean {
    const alphabet23: string[] = [
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'j',
        'k',
        'l',
        'm',
        'n',
        'p',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
    ]

    const codeUaiPropre: string = codeUai.toLowerCase().trim()

    const codeUaiPropreChiffres: string = codeUaiPropre.slice(0, 7)
    const codeUaiPropreLettre: string = codeUaiPropre.slice(7, 8)

    const rangCalcule: number = parseInt(codeUaiPropreChiffres) % 23

    const lettreCalculee: string = alphabet23[rangCalcule]
    return codeUaiPropreLettre === lettreCalculee
}

function ClubInfosForm({
    user,
    setUser,
}: {
    user: iClubUser
    setUser: (args: iClubUser) => void
}) {
    return (
        <>
            <Input
                title="Nom"
                value={user.club.name}
                defaultValue={user.club.name}
                onChange={(e) => {
                    setUser({
                        ...user,
                        club: {
                            ...user.club,
                            name: e.target.value,
                        },
                    })
                }}
                required
            />
            <Input
                title="Rue"
                value={user.club.street}
                defaultValue={user.club.street}
                onChange={(e) =>
                    setUser({
                        ...user,
                        club: {
                            ...user.club,
                            street: e.target.value,
                        },
                    })
                }
                required
            />
            <Input
                title="Ville"
                value={user.club.city}
                defaultValue={user.club.city}
                onChange={(e) =>
                    setUser({
                        ...user,
                        club: {
                            ...user.club,
                            city: e.target.value,
                        },
                    })
                }
                required
            />
            <Input
                title="Code postal"
                value={parseInt(user.club.zipCode) || undefined}
                defaultValue={parseInt(user.club.zipCode) || undefined}
                onChange={(e) =>
                    setUser({
                        ...user,
                        club: {
                            ...user.club,
                            zipCode: e.target.value,
                        },
                    })
                }
                error={
                    user?.club?.zipCode && user.club?.zipCode.length !== 5
                        ? 'Le code postal doit contenir 5 chiffres'
                        : ''
                }
                required
                type="text"
            />

            <Input
                title="Pays"
                value={user.club.country}
                defaultValue={user.club.country}
                onChange={(e) =>
                    setUser({
                        ...user,
                        club: {
                            ...user.club,
                            country: e.target.value,
                        },
                    })
                }
                required
            />
        </>
    )
}

export default ClubInfosForm
