import { FormEvent, useEffect, useState } from 'react'
import './connexion.css'
import { useNavigate } from 'react-router-dom'
import axios from '../../axios/axios'
import { eroles } from '../../types/enums'
import view from '../../assets/view.svg'
import hide from '../../assets/hidden.svg'
import Modal from '../../components/Modal'
import Faq from '../../components/Faq'
import ParticipantLayout from '../layouts/ParticipantLayout'
function Connexion() {
    useEffect(() => {
        document.title = 'Connexion'
    }, [])

    const navigate = useNavigate()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [resetMode, setResetMode] = useState(false)

    useEffect(() => {
        setError('')
    }, [username, password, resetMode])
    async function resetMdp(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        axios
            .post('/authentification/reset-password', {
                username: username,
            })
            .catch((e) => {})
        alert(
            "Si l'identifiant existe alors un mail contenant un nouveau mot de passe a été envoyé."
        )

        setResetMode(false)
    }
    async function connexion(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        axios
            .post('/authentification/log-in', {
                username: username,
                password: password,
            })
            .then((res) => {
                let baseurl = ''
                switch (res.data.user.role) {
                    case eroles.USER:
                        localStorage.setItem('role', 'user')
                        baseurl = '/participant'
                        break
                    case eroles.ESTABLISHEMENT:
                        localStorage.setItem('role', 'establishment')
                        baseurl = '/etablissement'
                        break
                    case eroles.ADMIN:
                        localStorage.setItem('role', 'admin')
                        baseurl = '/admin'
                        break
                    case eroles.CLUB:
                        localStorage.setItem('role', 'club')
                        baseurl = '/club'
                        break
                }
                const redirectTo = localStorage.getItem('redirectTo')
                localStorage.removeItem('redirectTo')

                navigate(redirectTo || baseurl)

                localStorage.setItem('accessToken', res.data.accessToken)
                localStorage.setItem('refreshToken', res.data.refreshToken)
            })
            .catch(({ response }) => {
                response &&
                    setError(
                        'Identifiants invalides. \nÊtes-vous bien inscrit sur ce site internet ?'
                    )
            })
    }

    const [hidden, setHidden] = useState(true)

    const [faqShown, setFaqShown] = useState(false)
    function showFAQ() {
        setFaqShown(true)
    }
    return (
        <ParticipantLayout noDisconnectButton color="red">
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {/* <div className="header">
                    <img className="logo" />
                </div> */}
                {/* <div
                    className="body"
                    style={{
                        display: 'flex',
                        height: '80%',
                        alignItems: 'center',
                        // flexWrap: 'wrap',
                    }}
                > */}

                <div
                    style={{
                        width: '80%',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}
                >
                    <div
                        style={{
                            backgroundColor: 'rgba(280, 100, 5, 0.5)',
                            padding: 15,
                            borderRadius: 10,
                        }}
                    >
                        Participant scolaire ou individuel : Pour renouveler
                        votre inscription aux championnats successifs ,
                        connectez-vous à l'aide de vos identifiants existants et
                        cliquez ensuite sur le bouton "Renouveler inscription"
                    </div>
                    <form
                        className="columns center"
                        onSubmit={(e) =>
                            resetMode ? resetMdp(e) : connexion(e)
                        }
                        style={{
                            height: 'fit-content',
                        }}
                    >
                        <h1>
                            {resetMode
                                ? 'Réinitialisation de mot de passe'
                                : 'Connexion'}
                        </h1>

                        {resetMode && <div>Identifiant</div>}

                        <input
                            type="text"
                            className="co-input"
                            id="username"
                            name="username"
                            placeholder="Identifiant"
                            required
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        {!resetMode && (
                            <div
                                style={{
                                    position: 'relative',
                                    display: 'flex',
                                    flexGrow: 2,
                                    width: '328px',
                                }}
                            >
                                <input
                                    style={{ flexGrow: 1 }}
                                    type={hidden ? 'password' : 'text'}
                                    className="co-input"
                                    id="password"
                                    name="password"
                                    placeholder="Mot de passe"
                                    required
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                                <img
                                    onClick={() => setHidden(!hidden)}
                                    src={hidden ? view : hide}
                                    style={{
                                        position: 'absolute',
                                        width: 30,
                                        right: 10,
                                        top: 5,
                                        cursor: 'pointer',
                                    }}
                                />
                            </div>
                        )}
                        {error && (
                            <p id="error">
                                Identifiants invalides.
                                <br />
                                Êtes-vous bien inscrit sur ce site internet ?
                            </p>
                        )}
                        <div>
                            {' '}
                            {!resetMode ? (
                                <div id="butt-connexion">
                                    <button
                                        className="buttons-blue school-button"
                                        type="submit"
                                    >
                                        Se connecter
                                    </button>
                                    <button
                                        className="buttons-white school-button-border-off"
                                        onClick={() => navigate('/inscription')}
                                    >
                                        S'inscrire
                                    </button>
                                </div>
                            ) : (
                                <div id="butt-connexion">
                                    <button
                                        className="buttons-blue school-button"
                                        type="submit"
                                    >
                                        Recevoir un nouveau mot de passe
                                    </button>
                                </div>
                            )}
                            <div
                                style={{
                                    padding: 5,
                                    fontSize: 15,
                                    fontStyle: 'italic',
                                    cursor: 'pointer',
                                }}
                                onClick={() => setResetMode(!resetMode)}
                            >
                                {resetMode ? 'retour' : 'Mot de passe oublié'}
                            </div>
                        </div>
                    </form>
                    <div className="columns text">
                        <p>
                            Bienvenue sur le site du Championnat de Jeux
                            Mathématiques et Logiques organisé par la FFJM.
                        </p>
                        <p>
                            Que vous soyez un participant individuel, un
                            enseignant représentant un établissement scolaire ou
                            bien un participant scolaire, vous pourrez ici vous
                            inscrire au championnat, soumettre vos réponses au
                            quart de finale individuel et accéder aux
                            informations dont vous aurez besoin pour les
                            différentes phases qualificatives (documentation,
                            résultats, qualifications, convocations, ...)
                        </p>
                        <button
                            className="buttons-blue school-button-border-off"
                            onClick={showFAQ}
                        >
                            Une question ? Un problème ?
                        </button>
                        <Modal isActive={faqShown} setActive={setFaqShown}>
                            <Faq />
                        </Modal>
                    </div>
                </div>

                {/* <div id="contact">
                    <div className="contact-contain">
                        <h1 id="contact-title">Contact</h1>
                        <a id="contact-mail" href="mailto:contact@ffjm.org">
                            contact@ffjm.org
                        </a>
                    </div>
                </div> */}
            </div>
        </ParticipantLayout>
    )
}
export default Connexion
