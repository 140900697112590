import { useEffect, useState } from 'react'
import Select from 'react-select'
import { ExamCenter } from '../../pages/admin/AdminExam'
import { TypeEstablishementOptions } from '../../pages/inscription/Inscription'
import { eestablishements } from '../../types/enums'
import {
    iClub,
    iClubWithControllers,
    iEstablishementWContact,
    iSchoolEditUser,
} from '../../types/user'
import Input from '../forms/common/Input'
import Modal from '../Modal'
import UpdateUser from './UpdateUser'
import axios from '../../axios/axios'
import { toast } from 'react-toastify'
import ControllersListTable from './ControllersListTable'

export default function UpdateClub({
    close,
    confirm,
    initialValue,
}: {
    close: () => void
    confirm: (data: iClub) => void
    initialValue: iClubWithControllers
}) {
    const [data, setData] = useState<iClubWithControllers>(initialValue)

    function refreshList() {
        axios
            .get('/clubs/' + initialValue.id)
            .then((res) => {
                console.log('res', res)
                setData({
                    ...data,
                    controllers: res.data.controllers,
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div style={{ width: '80vw' }}>
            <h1>Modifier ce club</h1>
            <form>
                <div className="add-modal modal-content-scrollable">
                    <Input
                        title="Nom"
                        onChange={(e) =>
                            setData({
                                ...data,
                                name: e.target.value,
                            })
                        }
                        value={data.name}
                        required
                    />
                    <Input
                        title="Adresse"
                        type="text"
                        onChange={(e) =>
                            setData({
                                ...data,
                                street: e.target.value,
                            })
                        }
                        value={data?.street}
                    />
                    <Input
                        title="Ville"
                        type="text"
                        onChange={(e) =>
                            setData({
                                ...data,
                                city: e.target.value,
                            })
                        }
                        value={data.city}
                    />
                    <Input
                        title="Code Postal"
                        type="text"
                        onChange={(e) =>
                            setData({
                                ...data,
                                zipCode: e.target.value,
                            })
                        }
                        value={data.zipCode}
                        error={
                            data.zipCode && data.zipCode.length !== 5
                                ? 'Le code postal doit contenir 5 chiffres'
                                : ''
                        }
                    />
                    <Input
                        title="Pays"
                        type="text"
                        onChange={(e) =>
                            setData({
                                ...data,
                                country: e.target.value,
                            })
                        }
                        value={data.country}
                    />
                </div>
                <div className="buttons-modal-multiple">
                    <button
                        className="buttons-border-off admin-border-off"
                        onClick={close}
                        type="button"
                    >
                        Annuler
                    </button>
                    <button
                        className="buttons-blue buttons-blue blue-admin"
                        onClick={() => {
                            if (data?.name != undefined) {
                                confirm(data)
                            } else {
                                console.log('Données non conformes')
                            }
                        }}
                        type="button"
                    >
                        Confirmer
                    </button>
                </div>
                <ControllersListTable
                    users={data.controllers}
                    refreshList={refreshList}
                />
            </form>
        </div>
    )
}
