import { iIndividualUser } from '../../../types/user'
import '../formsInscription.css'
import IndividualAuthorisationForm from './IndividualAuthorisationForm'
import IndividualContactForm from './IndividualContactForm'
import IndividualInfosForm from './IndividualInfosForm'
import IndividualRecapForm from './IndividualRecapForm'

function IndividualForm({
    active,
    user,
    setUser,
}: {
    active: string
    user: iIndividualUser
    setUser: (args: iIndividualUser) => void
}) {
    return (
        <form className="forms-insc" id="formulaires-inscription">
            <h2 className="forms-title-insc">{active}</h2>
            <div className="forms-inputs">
                {active == 'Informations' && (
                    <IndividualInfosForm user={user} setUser={setUser} />
                )}

                {active == 'Coordonnées' && (
                    <IndividualContactForm user={user} setUser={setUser} />
                )}

                {active == 'Autorisations' && (
                    <IndividualAuthorisationForm
                        user={user}
                        setUser={setUser}
                    />
                )}

                {active == 'Confirmation' && (
                    <IndividualRecapForm user={user} />
                )}
            </div>
            <button
                type="submit"
                id="Inscription-sub-button"
                onClick={(e) => e.preventDefault()}
            ></button>
        </form>
    )
}
export default IndividualForm
