import { JSX } from 'react/jsx-runtime'
import { iSchoolEditUser, iUser } from '../../../types/user'
import { confirmRegistrationUser } from './actions'

export const confirmRegistrationModal = (
    year: string | number,
    user: iUser,
    setModalType: (arg0: JSX.Element) => void,
    setModalActive: (state: boolean) => void,
    triggerEffect: boolean,
    setTriggerEffect: (state: boolean) => void,
    from: string
) => {
    setModalType(
        <>
            <span>
                Êtes vous sûr de vouloir confirmer la participation de ce
                participant pour cette année ?
                <br />
                Confirmer un participant scolaire fera qu'il sera qualifié pour
                la demi-finale
            </span>
            <div className="buttons-modal-multiple">
                <button
                    className="buttons-border-off admin-border-off"
                    onClick={() => setModalActive(false)}
                >
                    Annuler
                </button>
                <button
                    className="buttons-blue blue-admin"
                    onClick={async () => {
                        await confirmRegistrationUser(year, user.id)
                        setModalActive(false)
                        setTimeout(() => setTriggerEffect(!triggerEffect), 500)
                    }}
                >
                    Confirmer
                </button>
            </div>
        </>
    )
    setModalActive(true)
}
