import { iClubUser, iEstablishementUser } from '../../../types/user'
import Input from '../common/Input'

function ClubRecapForm({ user }: { user: iClubUser }) {
    return (
        <>
            <Input title="Nom du club" defaultValue={user.club.name} disabled />
            <Input title="Adresse" defaultValue={user.club.street} disabled />
            <Input title="Ville" defaultValue={user.club.city} disabled />
            <Input
                title="Code postal"
                defaultValue={user.club.zipCode}
                disabled
                error={
                    user.contact.zip_code && user.contact.zip_code.length !== 5
                        ? 'Le code postal doit contenir 5 chiffres'
                        : ''
                }
            />
            <Input title="Pays" defaultValue={user.club.country} disabled />
            <Input
                title="Nom du correspondant"
                defaultValue={user.contact.lastname}
                disabled
            />
            <Input
                title="Prénom du correspondant"
                defaultValue={user.contact.firstname}
                disabled
            />
            <Input
                title="Courriel du correspondant"
                defaultValue={user.contact.email}
                disabled
            />
            <Input
                title="Téléphone du correspondant"
                defaultValue={user.contact.phone}
                disabled
            />
        </>
    )
}

export default ClubRecapForm
