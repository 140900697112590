import axios from 'axios'

const instance = axios.create({
    baseURL:
        window.location.hostname == 'localhost'
            ? 'http://localhost:8080/'
            : window.location.hostname == 'championnat.ffjm.org'
            ? 'https://championnat.ffjm.org/api'
            : 'https://api.ffjm.juniorisep.com/',

    maxBodyLength: Infinity,
    headers: {
        'Content-Type': 'application/json',
    },
})
instance.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem('accessToken') || undefined
        const refreshToken = localStorage.getItem('refreshToken') || undefined
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)
export default instance
