
import '../modal.css'
export default function ConfirmAction({
    text,
    onValidate,
    onClose,
    children,
}: {
    text: string
    onClose: () => void
    onValidate?: () => void
    active: boolean
    children?: JSX.Element | JSX.Element[]
}) {
    return (
        <div id="modal-window">
            <div id="modal-content">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    id="cross-modal"
                    onClick={() => onClose()}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
                <>
                    <span>{text}</span>
                    {children ? <span>{children}</span> : ''}
                    <div className="buttons-modal-multiple">
                        <button
                            className="buttons-border-off school-button-border-off"
                            onClick={() => onClose()}
                        >
                            {onValidate ? 'Annuler' : 'Fermer'}
                        </button>
                        {onValidate ? (
                            <button
                                className="buttons-blue school-button"
                                onClick={() => onValidate()}
                            >
                                Confirmer
                            </button>
                        ) : (
                            ''
                        )}
                    </div>
                </>
            </div>
        </div>
    )
}
