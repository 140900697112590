import { typeStatus } from '../typeStatus'
import { TableStyle } from './typeTables'

const constSchoolParticipant: TableStyle[] = [
    {
        name: 'Nom',
        isSortable: true,
        filters: null,
        index: 'contact.lastname',
    },
    {
        name: 'Prénom',
        isSortable: true,
        filters: null,
        index: 'contact.firstname',
    },
    {
        name: 'E-mail',
        isSortable: false,
        filters: null,
        index: 'contact.email',
    },
    {
        name: 'Catégorie',
        isSortable: false,
        filters: null,
        index: 'category',
    },
    {
        name: "Status de l'inscription",
        isSortable: false,
        filters: [typeStatus.WAITING_FOR_VALIDATION, typeStatus.VALIDATED],
        index: 'status',
    },
]

export default constSchoolParticipant
