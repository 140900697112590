export default function DeleteIcon(props: { action: () => void }) {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 29 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => props.action()}
        >
            <g id="&#240;&#159;&#166;&#134; icon &#34;trash&#34;">
                <g id="Group">
                    <path
                        id="Vector"
                        d="M27.9991 6.96811C23.0043 6.47313 17.9795 6.21814 12.9698 6.21814C9.99987 6.21814 7.03 6.36813 4.06012 6.66812L1.00024 6.96811"
                        stroke="#292D32"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_2"
                        d="M9.25 5.45482L9.57999 3.4899C9.81998 2.06496 9.99997 1 12.5349 1H16.4647C18.9996 1 19.1946 2.12495 19.4196 3.5049L19.7496 5.45482"
                        stroke="#292D32"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_3"
                        d="M24.775 11.7108L23.8 26.8152C23.635 29.1701 23.5 31 19.3152 31H9.68561C5.50078 31 5.36578 29.1701 5.20079 26.8152L4.22583 11.7108"
                        stroke="#292D32"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_4"
                        d="M11.9941 22.7489H16.9889"
                        stroke="#292D32"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_5"
                        d="M10.749 16.7485H18.2487"
                        stroke="#292D32"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </svg>
    )
}
