import axios from '../axios/axios'

export async function getLanguages(): Promise<
    { value: string; label: string }[]
> {
    const langs = await axios.get<{ language: string }[]>('/languages/list')

    return langs.data.map((lang) => ({
        value: lang.language,
        label: lang.language,
    }))
}
