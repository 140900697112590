import './participantInformation.css'
import axios from '../../axios/axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { eStatus } from '../../types/enums'

import { iIndividualUser, iRegistration, iSchoolUser } from '../../types/user'
import authService from '../../services/AuthService'
import Modal from '../../components/Modal'
import RenewInscriptionModal from './RenewInscriptionModal'
import ParticipantLayout from '../layouts/ParticipantLayout'
import arrowUp from '../../assets/arrowUp.svg'
function ParticipantInformationChampionnat() {
    useEffect(() => {
        document.title = 'Informations'
    }, [])

    const navigate = useNavigate()

    const [data, setData] = useState<iSchoolUser | iIndividualUser | null>(null)

    const [regOpen, setRegOpen] = useState(false)
    const [activeYear, setActiveYear] = useState<string | null>(null)
    const [activeRegistration, setActiveRegistration] = useState<
        iRegistration | undefined
    >(undefined)
    const [complexModalActive, setComplexModalActive] = useState(false)
    function closeModalAndRefresh() {
        setComplexModalActive(false)
        fetchData()
    }
    function fetchData() {
        axios
            .get('/users/me')
            .then((res) => {
                if (res.data.controlledCenter.length > 0) {
                    navigate(
                        `/examcenter/participants/${res.data.controlledCenter[0].examCenterId}`
                    )
                }
                setData(res.data)
            })
            .catch((e) => {
                if (e.response.status === 401) {
                    authService.logout()
                    navigate('/Connexion')
                }
            })

        // get Active Year and check if registrations are still open
    }

    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {
        getActive(data)
    }, [data])

    function getActive(data: iSchoolUser | iIndividualUser | null) {
        axios.get('/configurations/public/ACTIVE_YEAR').then((res) => {
            setActiveYear(res.data.value)
            if (data) {
                setActiveRegistration(
                    data.registrations?.filter(
                        (registration) =>
                            registration?.year === parseInt(res.data.value)
                    )?.[0]
                )
            }
        })
        axios
            .get('/configurations/public/ACTIVE_REGISTRATION_INDIVIDUAL')
            .then((res) => {
                console.log(res.data)
                axios
                    .get('/configurations/public/ACTIVE_REGISTRATION_STUDENT')
                    .then((res2) => {
                        const date1 = new Date(res.data.value)
                        const date2 = new Date(res2.data.value)
                        const date = new Date()
                        if (date <= date1 || date <= date2) {
                            setRegOpen(true)
                        } else {
                            setRegOpen(false)
                        }
                    })
            })
    }

    function renewRegistration() {
        setComplexModalActive(true)
    }

    function openLink() {
        window.open('https://www.ffjm.org/fr/')
    }
    if (!data) return <>Chargement...</>
    return (
        <ParticipantLayout
            headerButtons={
                !activeRegistration ||
                activeRegistration?.establishment ||
                !activeRegistration?.category.canSeeQuestionnaire
                    ? [
                          {
                              title: 'Informations Championnat',
                              link: '/participant/informations-championnat',
                          },
                          {
                              title: 'Informations Personnelles',
                              link: '/participant/informations-personnelles',
                          },
                      ]
                    : [
                          {
                              title: 'Informations Championnat',
                              link: '/participant/informations-championnat',
                          },
                          {
                              title: 'Informations Personnelles',
                              link: '/participant/informations-personnelles',
                          },
                          {
                              title: 'Mes questionnaires',
                              link: '/participant/questionnaire',
                          },
                      ]
            }
            color="yellow"
        >
            <div style={{ width: '100%' }}>
                <div
                    className="body"
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    <h1>Vos informations championnat</h1>
                    {/* check if any registration related to actual year AND registrations still open */}
                    <div style={{ padding: 10 }}>
                        {regOpen &&
                            activeYear &&
                            data?.registrations &&
                            data.registrations?.filter(
                                (registration) =>
                                    registration?.year === parseInt(activeYear)
                            ).length === 0 && (
                                <>
                                    <h2 style={{ marginBottom: 10 }}>
                                        Championnat {activeYear}
                                    </h2>
                                    <div>
                                        Vous n'êtes pas encore inscrit au
                                        championnat {activeYear}, inscrivez-vous
                                        dès maintenant !
                                        <br />
                                        <br />
                                        <button
                                            className="buttons-blue  part-button"
                                            onClick={renewRegistration}
                                        >
                                            Renouveler l’inscription{' '}
                                            {activeYear}
                                        </button>
                                    </div>
                                </>
                            )}
                        {data?.registrations?.map((registration) => (
                            <div key={registration.year}>
                                <h2 style={{ marginBottom: 10 }}>
                                    Championnat {registration.year}
                                </h2>
                                {registration.establishment ? (
                                    <>
                                        <span style={{ fontWeight: 600 }}>
                                            Etablissement scolaire :
                                        </span>{' '}
                                        {registration.establishment.name}
                                        <br />
                                    </>
                                ) : (
                                    ''
                                )}
                                <span style={{ fontWeight: 600 }}>
                                    Catégorie :
                                </span>{' '}
                                {registration.category.name}
                                <br />
                                {registration.club && (
                                    <>
                                        <span style={{ fontWeight: 600 }}>
                                            Club :
                                        </span>{' '}
                                        {registration.club.name}
                                        <br />
                                    </>
                                )}
                                <span style={{ fontWeight: 600 }}>
                                    Statut :
                                </span>{' '}
                                {registration.establishment ? (
                                    registration.status == eStatus.PENDING ? (
                                        'Votre inscription est en attente de validation par votre établissement scolaire.'
                                    ) : (
                                        'Votre inscription a été validée par votre établissement scolaire.'
                                    )
                                ) : registration.status == eStatus.PENDING ? (
                                    registration.year === +(activeYear || 0) ? (
                                        <>
                                            {registration.club
                                                ? 'Votre inscription est en attente de validation par votre club'
                                                : 'Votre inscription est en attente de validation par la FFJM'}
                                            <br />
                                            {!registration.club && (
                                                <button
                                                    className="buttons-blue part-button"
                                                    onClick={() => openLink()}
                                                    style={{
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <div>
                                                        Adhérer à la FFJM{' '}
                                                        {activeYear} en tant que
                                                        nouvel adhérent FFJM ou
                                                        renouveler votre
                                                        adhésion en vous
                                                        connectant à votre
                                                        compte FFJM
                                                    </div>
                                                </button>
                                            )}
                                        </>
                                    ) : registration.club ? (
                                        "Votre inscription n'a pas été validée par votre club"
                                    ) : (
                                        "Votre inscription n'a pas été validée par la FFJM"
                                    )
                                ) : registration.club ? (
                                    'Votre inscription a été validée par votre club'
                                ) : (
                                    'Votre inscription a été validée par la FFJM.'
                                )}
                                <br />
                                {registration.status == eStatus.CONFIRMED && (
                                    <>
                                        {data.results?.map((result) => {
                                            if (
                                                result.questionnaire?.year !==
                                                registration.year
                                            )
                                                return <></>
                                            return (
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            'lightgrey',
                                                        padding: '5px',
                                                        margin: '10px',
                                                        borderRadius: '5px',
                                                    }}
                                                    key={result.questionnaireId}
                                                >
                                                    <h4
                                                        style={{ marginTop: 0 }}
                                                    >
                                                        {
                                                            result.questionnaire
                                                                ?.name
                                                        }
                                                    </h4>
                                                    {result.qualified ===
                                                    true ? (
                                                        result.examCenter ? (
                                                            <div>
                                                                {
                                                                    result
                                                                        .questionnaire
                                                                        ?.qualifiedForNextStep
                                                                }
                                                                {result
                                                                    .questionnaire
                                                                    ?.qualifiedForNextStep && (
                                                                    <br />
                                                                )}
                                                                <br />

                                                                {
                                                                    result
                                                                        .questionnaire
                                                                        ?.convoquedForNextStep
                                                                }
                                                                <br />

                                                                {result
                                                                    .questionnaire
                                                                    ?.convoquedForNextStep &&
                                                                    result
                                                                        .questionnaire
                                                                        ?.convoquedForNextStep !==
                                                                        '' && (
                                                                        <>
                                                                            <div className="inputs-infos">
                                                                                <span className="inputs-title">
                                                                                    Lieu
                                                                                    :
                                                                                </span>
                                                                                <span className="inputs-infos-data">
                                                                                    {
                                                                                        result
                                                                                            .examCenter
                                                                                            .name
                                                                                    }

                                                                                    ,{' '}
                                                                                    {
                                                                                        result
                                                                                            .examCenter
                                                                                            .street
                                                                                    }

                                                                                    ,{' '}
                                                                                    {
                                                                                        result
                                                                                            .examCenter
                                                                                            .zipCode
                                                                                    }{' '}
                                                                                    {
                                                                                        result
                                                                                            .examCenter
                                                                                            .city
                                                                                    }

                                                                                    ,{' '}
                                                                                    {
                                                                                        result
                                                                                            .examCenter
                                                                                            .country
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="inputs-infos">
                                                                                <span className="inputs-title">
                                                                                    date
                                                                                    :
                                                                                </span>
                                                                                <span className="inputs-infos-data">
                                                                                    {
                                                                                        result
                                                                                            .questionnaire
                                                                                            ?.date
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className="inputs-infos">
                                                                                <span className="inputs-title">
                                                                                    Heure
                                                                                    :
                                                                                </span>
                                                                                <span className="inputs-infos-data">
                                                                                    {
                                                                                        result
                                                                                            .questionnaire
                                                                                            ?.hour
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                            </div>
                                                        ) : (
                                                            <p>
                                                                {
                                                                    result
                                                                        .questionnaire
                                                                        ?.qualifiedForNextStep
                                                                }
                                                            </p>
                                                        )
                                                    ) : result.qualified ===
                                                      false ? (
                                                        <p>
                                                            {
                                                                result
                                                                    .questionnaire
                                                                    ?.notQualifiedForNextStep
                                                            }
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            {
                                                                result
                                                                    .questionnaire
                                                                    ?.waitingForQualificationInformation
                                                            }
                                                        </p>
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <Modal
                    isActive={complexModalActive}
                    setActive={setComplexModalActive}
                    children={
                        <RenewInscriptionModal
                            lastRegistration={data?.registrations?.[0]}
                            closeModal={() => closeModalAndRefresh()}
                        />
                    }
                />
            </div>
        </ParticipantLayout>
    )
}
export default ParticipantInformationChampionnat
