import { typeStatus } from '../typeStatus'
import { TableStyle } from './typeTables'

const constAdminParticipantResults: TableStyle[] = [
    {
        name: 'Nom',
        isSortable: true,
        filters: null,
        index: 'contact.lastname',
    },
    {
        name: 'Prénom',
        isSortable: true,
        filters: null,
        index: 'contact.firstname',
    },
    {
        name: 'Email',
        isSortable: false,
        filters: null,
        index: 'contact.email',
    },
    {
        name: 'Code postal',
        isSortable: false,
        filters: null,
        index: 'contact.zip_code',
    },
    {
        name: 'Catégorie',
        isSortable: false,
        filters: null,
        index: 'category',
    },
    {
        name: 'Qualifié',
        isSortable: false,
        filters: null,
        index: 'qualified',
    },
    {
        name: 'Notes',
        isSortable: true,
        filters: null,
        index: 'grade',
    },
    {
        name: 'Coefficient',
        isSortable: true,
        filters: null,
        index: 'coefficient',
    },
    {
        name: "Status de l'adhésion",
        isSortable: false,
        filters: [
            typeStatus.WAITING_FOR_VALIDATION,
            typeStatus.VALIDATED,
            typeStatus.MEMBER_FOR_VALIDATION,
        ],
        index: 'status',
    },
]

export default constAdminParticipantResults
