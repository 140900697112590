import { SetStateAction, useEffect, useState } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import axios from '../../axios/axios'
import {
    iContact,
    iRegistration,
    iSchoolEditUser,
    iUser,
} from '../../types/user'
import Input from '../forms/common/Input'

import { getLanguages } from '../../helpers/getLanguages'
import { eroles } from '../../types/enums'
import EditRegistrationsOfUser from '../EditRegistrationsOfUser'
import EditRegistrationsOfUserBySchool from '../EditRegistrationsOfUserBySchool'

export default function UpdateUser({
    close,
    confirm,
    initialValue,
    from,
}: {
    close: () => void
    confirm: () => void
    initialValue: Partial<iUser>
    from: string
}) {
    console.log(initialValue)
    const [userExamCenterId, setUserExamCenterId] = useState<string | null>(
        null
    )

    const [user, setUser] = useState<iUser | null>(null)

    const [dataToSend, setData] = useState<{
        lastname: string
        firstname: string
        birthday: Date | string
        categoryName?: string | null
        languageName?: string | null
        language?: string | null
        city: string
        phone: string
        zip_code: string
        address: string
        email: string
        username?: string
        country?: string
        examCenterId: string
        registrations?: iRegistration[]
    }>({
        username: initialValue.username,
        lastname: initialValue?.contact?.lastname ?? '',
        firstname: initialValue?.contact?.firstname ?? '',
        birthday: initialValue?.birthday || '',
        categoryName: initialValue.categoryName ?? null,
        languageName: initialValue.language?.language ?? null,
        language: initialValue.language?.language ?? null,
        city: initialValue?.contact?.city ?? '',
        phone: initialValue.contact?.phone ?? '',
        zip_code: initialValue.contact?.zip_code ?? '',
        address: initialValue.contact?.address ?? '',
        email: initialValue.contact?.email ?? '',
        country: initialValue.contact?.country ?? '',
        examCenterId: userExamCenterId ?? '',
        registrations: initialValue.registrations,
    })

    const [langsOptions, setLangsOptions] = useState<
        { value: string; label: string }[]
    >([])
    useEffect(() => {
        getLanguages().then((langs) => setLangsOptions(langs))
    }, [])

    useEffect(() => {
        setData({
            lastname: initialValue.contact?.lastname ?? '',
            firstname: initialValue.contact?.firstname ?? '',
            birthday: (initialValue.birthday as string) || '',
            categoryName: initialValue.categoryName ?? null,
            languageName: initialValue.languageName ?? null,
            language: initialValue.language?.language ?? null,
            city: initialValue.contact?.city ?? '',
            phone: initialValue.contact?.phone ?? '',
            zip_code: initialValue.contact?.zip_code ?? '',
            address: initialValue.contact?.address ?? '',
            email: initialValue.contact?.email ?? '',
            username: initialValue.username ?? '',
            country: initialValue.contact?.country ?? '',
            examCenterId: userExamCenterId ?? '',
            registrations: initialValue.registrations,
        })
    }, [initialValue])

    const [examCentersOptions, setExamCentersOptions] = useState<
        { value: string; label: string }[] | null
    >(null)
    useEffect(() => {
        console.log('INITIAL VALUE', initialValue)
        axios
            .get('/examcenter?limit=0')
            .then((res) => {
                const options: SetStateAction<
                    { value: string; label: string }[] | null
                > = []
                res.data.data.forEach(
                    (element: { id: string; name: string; city: string }) => {
                        options.push({
                            value: element.id,
                            label: element.name + ', ' + element.city,
                        })
                    }
                )
                setExamCentersOptions(options)
            })
            .catch((error) => {
                console.error(error)
            })

        axios
            .get('/users/' + initialValue.id)
            .then((res) => {
                setUser(res.data)
                setData((prev) => ({
                    ...prev,
                    languageName: res.data.language?.language,
                }))
                setUserExamCenterId(res.data.examCenter?.id ?? null)
            })
            .catch((error) => console.error(error))
    }, [])

    function reformatDate(date: string) {
        return (
            date.split('-')[1] +
            '/' +
            date.split('-')[2] +
            '/' +
            date.split('-')[0]
        )
    }

    const updateUser = async (
        id: string,
        dataSent: {
            lastname: string
            firstname: string
            birthday: string | Date
            categoryName?: string | null
            languageName?: string | null
            city: string
            phone: string
            zip_code: string
            address: string
            email: string
            username?: string
            country?: string
            examCenterId: string
        }
    ) => {
        const requestBody = {
            birthday: dataSent?.birthday
                ? reformatDate(dataSent?.birthday as string)
                : undefined,
            contact: {
                lastname: dataSent?.lastname,
                firstname: dataSent?.firstname,
                phone: dataSent?.phone,
                city: dataSent?.city,
                address: dataSent?.address,
                zip_code: dataSent?.zip_code,
                email: dataSent?.email,
                country: dataSent?.country,
            },
            username: dataSent?.username,
            categoryName: dataSent?.categoryName,
            languageName: dataSent?.languageName,
            establishement: {},
        }
        if (!dataSent.username) {
            delete dataSent.username
            delete requestBody.username
        }
        if (!dataSent?.lastname) {
            delete (requestBody as { contact?: iContact }).contact
        }

        console.log('REQUESTED', requestBody)
        axios
            .patch('/users/' + id, requestBody)
            .then((res) => {
                //console.log('EDITED', res.data)
                if (from === 'adminEtablissement') {
                    toast.success(
                        "L'utilisateur associé a été mis à jour, rechargez la page pour voir les changements."
                    )
                } else {
                    toast.success(
                        'Le participant a été mis à jour, rechargez la page pour voir les changements.'
                    )
                }
            })
            .catch(() => {
                //console.log('NOPE')
                if (from === 'adminEtablissement') {
                    toast.error(
                        "L'utilisateur associé n'a pas été modifié : Vérifiez que les données sont correctes"
                    )
                } else
                    toast.error(
                        "Le participant n'a pas été modifié : Vérifiez que les données sont correctes"
                    )
            })
        // register to exam center
        if (dataSent.examCenterId && from === 'admin') {
            axios
                .post(
                    '/examcenter/register/' + id + '/' + dataSent.examCenterId
                )
                .then((res) => {
                    console.log('EDITED', res.data)
                    toast.success(
                        "L'utilisateur a été assigné au centre d'épreuve voulu."
                    )
                })
                .catch(() => {
                    toast.error(
                        "L'utilisateur n'a pas été assigné au centre d'épreuve voulu : Vérifiez que les données sont correctes"
                    )
                })
        }
        confirm()
    }

    if (!dataToSend) return <>Chargement...</>
    return (
        <div style={{ width: '90vw', height: '90vh' }}>
            <h1>
                {from === 'adminEtablissement'
                    ? 'Modifier le correspondant de cet établissement'
                    : from === 'clubAdmin'
                    ? 'Modifier les données de ce compte'
                    : 'Modifier les données de ce participant'}
            </h1>
            <form id="editUser" style={{ height: '100%' }}>
                <h3>Informations</h3>
                <div className=" modal-content-scrollable">
                    <div className="add-modal ">
                        <Input
                            title="Identifiant"
                            onChange={(e) =>
                                setData({
                                    ...dataToSend,
                                    username: e.target.value,
                                })
                            }
                            value={dataToSend.username}
                            disabled={from == 'school'}
                            required
                        />

                        {initialValue.contact && (
                            <>
                                <Input
                                    title="Nom"
                                    onChange={(e) =>
                                        setData({
                                            ...dataToSend,
                                            lastname: e.target.value,
                                        })
                                    }
                                    value={dataToSend.lastname}
                                    required
                                />
                                <Input
                                    title="Prénom"
                                    onChange={(e) =>
                                        setData({
                                            ...dataToSend,
                                            firstname: e.target.value,
                                        })
                                    }
                                    value={dataToSend?.firstname}
                                    required
                                />
                                <Input
                                    title="Email"
                                    type="email"
                                    onChange={(e) =>
                                        setData({
                                            ...dataToSend,
                                            email: e.target.value,
                                        })
                                    }
                                    value={dataToSend?.email}
                                    required
                                />
                            </>
                        )}
                        <Input
                            title="Date de naissance"
                            type="date"
                            onChange={(e) =>
                                setData({
                                    ...dataToSend,
                                    birthday: e.target.value,
                                })
                            }
                            value={dataToSend.birthday as string}
                            required={
                                from !== 'adminEtablissement' &&
                                user?.role === eroles.USER
                            }
                        />

                        <div className="insc-inputs">
                            <span
                                className={
                                    from !== 'adminEtablissement' &&
                                    user?.role === eroles.USER
                                        ? 'inputs-title-insc required'
                                        : 'inputs-title-insc'
                                }
                            >
                                Langue
                            </span>
                            <Select
                                options={langsOptions}
                                isSearchable={true}
                                placeholder=""
                                onChange={(e) =>
                                    setData((prev) => ({
                                        ...prev,
                                        languageName: e?.value || null,
                                    }))
                                }
                                value={langsOptions.find((option) =>
                                    dataToSend.languageName
                                        ? option.label ===
                                          dataToSend.languageName
                                        : option.label ===
                                          initialValue.language?.language
                                )}
                                name="language"
                                required={
                                    from !== 'adminEtablissement' &&
                                    user?.role === eroles.USER
                                }
                            />
                        </div>
                        {initialValue.contact && (
                            <>
                                <Input
                                    title="Ville"
                                    type="text"
                                    onChange={(e) =>
                                        setData({
                                            ...dataToSend,
                                            city: e.target.value,
                                        })
                                    }
                                    value={dataToSend.city}
                                />
                                <Input
                                    title="Téléphone"
                                    type="text"
                                    onChange={(e) =>
                                        setData({
                                            ...dataToSend,
                                            phone: e.target.value,
                                        })
                                    }
                                    value={dataToSend.phone}
                                />
                                <Input
                                    required={
                                        from !== 'adminEtablissement' &&
                                        user?.role === eroles.USER
                                    }
                                    title="Code postal"
                                    type="text"
                                    error={
                                        dataToSend.zip_code &&
                                        dataToSend.zip_code.length !== 5
                                            ? 'Le code postal doit contenir 5 chiffres'
                                            : ''
                                    }
                                    onChange={(e) =>
                                        setData({
                                            ...dataToSend,
                                            zip_code: e.target.value,
                                        })
                                    }
                                    value={dataToSend.zip_code}
                                />
                                <Input
                                    title="Adresse"
                                    type="text"
                                    onChange={(e) =>
                                        setData({
                                            ...dataToSend,
                                            address: e.target.value,
                                        })
                                    }
                                    value={dataToSend.address}
                                />

                                <Input
                                    title="Pays"
                                    value={dataToSend?.country}
                                    onChange={(e) =>
                                        setData({
                                            ...dataToSend,
                                            country: e.target.value,
                                        })
                                    }
                                    required={
                                        from !== 'adminEtablissement' &&
                                        user?.role === eroles.USER
                                    }
                                />
                            </>
                        )}
                    </div>
                    <div className="buttons-modal-multiple">
                        <>
                            <button
                                className="buttons-border-off admin-border-off"
                                onClick={close}
                            >
                                Annuler
                            </button>
                            <button
                                className="buttons-blue blue-admin"
                                // type="reset"
                                onClick={(e) => {
                                    e.preventDefault()
                                    const formElement = document.getElementById(
                                        'editUser'
                                    ) as HTMLFormElement

                                    if (formElement.checkValidity()) {
                                        console.log('DATA', dataToSend)
                                        if (initialValue?.id)
                                            updateUser(
                                                initialValue?.id,
                                                dataToSend
                                            )
                                    } else {
                                        toast.error(
                                            'Veuillez remplir les champs obligatoires (*)'
                                        )
                                    }
                                }}
                            >
                                Confirmer
                            </button>
                        </>
                    </div>
                    {from === 'school' && initialValue.registrations && (
                        <EditRegistrationsOfUserBySchool
                            registrations={initialValue.registrations}
                        />
                    )}
                    {user && user.role === eroles.USER && (
                        <EditRegistrationsOfUser userId={user.id} />
                    )}
                </div>
            </form>
        </div>
    )
}
