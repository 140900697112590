import React from 'react'
import { iUser } from '../../types/user'
import Modal from '../Modal'
import UpdateUser from './UpdateUser'
import EditIcon from '../utils/icons/EditIcon'

interface ControllersListTableProps {
    // Define the props for your component here
    users: iUser[]
    refreshList: () => void
}

const ControllersListTable: React.FC<ControllersListTableProps> = ({
    users,
    refreshList,
}) => {
    const [modalActive, setModalActive] = React.useState(false)
    const [selectedUser, setSelectedUser] = React.useState<iUser | null>(null)
    function editAssociatedUser(user: iUser) {
        setSelectedUser(user)
        setModalActive(true)
    }
    return (
        <div className="mt-2">
            <h2>Utilisateurs en charge</h2>
            <div className="mt-2">
                {users.map((user) => (
                    <div key={user.id} className="flex gap-2 items-center">
                        <EditIcon
                            action={() => {
                                editAssociatedUser(user)
                            }}
                            size={'20'}
                        />
                        <div>
                            {user.contact.firstname} {user.contact.lastname}
                        </div>
                    </div>
                ))}
            </div>
            {selectedUser && (
                <Modal isActive={modalActive} setActive={setModalActive}>
                    <UpdateUser
                        close={() => setModalActive(false)}
                        confirm={async () => {
                            setModalActive(false)
                            setTimeout(() => refreshList(), 500)
                        }}
                        initialValue={selectedUser}
                        from="clubAdmin"
                    />
                </Modal>
            )}
        </div>
    )
}

export default ControllersListTable
