import { useRef, useState } from 'react'
import axios from '../../axios/axiosFile'

export default function AddUserByExcel({
    close,
    importSuccess,
}: {
    close: () => void
    importSuccess: (msg: string) => void
}) {
    const [error, setError] = useState('')
    const inputFile = useRef<HTMLInputElement>(null)
    const loader = useRef<HTMLDivElement>(null)
    const onButtonClick = () => {
        if (inputFile?.current) inputFile.current.click()
    }

    async function upload() {
        if (
            inputFile.current &&
            inputFile.current?.files &&
            inputFile.current?.files.length != 0
        ) {
            //console.log(inputFile.current?.files)
            const formData = new FormData()
            formData.append('excel', inputFile.current?.files[0])
            const response = await axios
                .post(
                    '/authentification/establishment/add-from-excel',
                    formData
                )
                .then((response) => {
                    // handle the response
                    //console.log(response)
                    importSuccess('Le fichier a bien été importé!')
                })
                .catch((e) => {
                    console.log(e)
                    setError("Le fichier n'a pas été importé :(")
                })
        } else {
            //console.log('fichier invalide')
            setError('Merci de séléctionner un fichier valide...')
        }
    }
    return (
        <>
            <h1>Ajouter plusieurs participants</h1>

            <div className="succesful-upload d-none" id="successfulUpload">
                Le fichier a bien été choisi! Cliquez sur "confirmer" pour
                effectuer l'import!
            </div>
            {error != '' ? <div className="error-upload">{error}</div> : <></>}
            <button
                className="buttons-blue school-button"
                onClick={onButtonClick}
            >
                Selectionner le fichier
            </button>

            <input
                type="file"
                id="file"
                name="file"
                onChange={() => {
                    if (
                        inputFile?.current != null &&
                        document.getElementById('successfulUpload') != undefined
                    ) {
                        console.log(inputFile.current.value)
                        document
                            .getElementById('successfulUpload')
                            ?.classList.remove('d-none')
                        document
                            .getElementById('successfulUpload')
                            ?.classList.add('d-block')
                    }
                }}
                ref={inputFile}
                style={{ display: 'none' }}
            />

            <a
                href="https://ffjmath.sharepoint.com/:f:/s/ChampionnatFFJM/Eny6ilutdqBGnvuf2pCcqJwBSH2hb3sYDUbbNRyYcR0a4g?e=ASe2PA"
                target={'_blank'}
            >
                <button
                    className="buttons-blue school-button"
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                    }}
                >
                    Télécharger le modèle
                </button>
            </a>
            <div className="buttons-modal-multiple">
                <button
                    className="buttons-border-off school-button-border-off"
                    onClick={close}
                >
                    Annuler
                </button>
                <button
                    className="buttons-blue school-button"
                    onClick={async () => {
                        console.log('Sending the request...')
                        loader.current?.classList.remove('d-none')
                        loader.current?.classList.add('d-inline-block')
                        const response = await upload()
                        loader.current?.classList.add('d-none')
                        loader.current?.classList.remove('d-inline-block')
                    }}
                >
                    <span className="d-inline-block">Confirmer</span>{' '}
                    <div ref={loader} className="loader d-none"></div>
                </button>
            </div>
        </>
    )
}
