import React from 'react'
import './input.css'

function Input({
    onChange,
    defaultValue,
    title,
    required = false,
    disabled = false,
    type = 'text',
    multiple = false,
    value,
    name,
    isChecked,
    error,
    step,
}: {
    onChange?: (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => void
    defaultValue?: string | number
    disabled?: boolean
    required?: boolean
    title?: string
    value?: string | number
    multiple?: boolean
    name?: string
    isChecked?: boolean
    type?:
        | 'text'
        | 'number'
        | 'email'
        | 'checkbox'
        | 'date'
        | 'datetime-local'
        | 'tel'
        | 'radio'
        | 'password'
    error?: string
    step?: string
}) {
    if (type == 'checkbox' || type == 'radio')
        return (
            <div className="insc-inputs-checkbox">
                <input
                    className="chexkbox-insc"
                    type={type}
                    required={required}
                    onChange={onChange ? (e) => onChange(e) : undefined}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    value={value}
                    name={name}
                    checked={isChecked}
                />
                <span
                    className={`inputs-title-insc-notBold${
                        required ? ' required' : ''
                    }`}
                >
                    {title}
                </span>
                <div className={'error-inputs'}>{error}</div>
            </div>
        )
    return (
        <div className="inputs">
            {title && (
                <span
                    className={`inputs-title-insc${
                        required ? ' required' : ''
                    }`}
                >
                    {title}
                </span>
            )}
            {multiple ? (
                <textarea
                    onChange={onChange ? (e) => onChange(e) : undefined}
                    className="insc-input"
                    defaultValue={defaultValue}
                    required={required}
                    disabled={disabled}
                    value={value}
                ></textarea>
            ) : (
                <>
                    <input
                        onChange={onChange ? (e) => onChange(e) : undefined}
                        className={`insc-input input-${type}`}
                        defaultValue={defaultValue}
                        required={required}
                        type={type}
                        disabled={disabled}
                        value={value}
                        step={step}
                    />
                </>
            )}
            <div className={'error-inputs'}>{error}</div>
        </div>
    )
}

export default Input
