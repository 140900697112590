import Input from '../forms/common/Input'
import IQuestionnaire, { IQuestionnaireText } from '../../types/IQuestionnaire'

import { useState } from 'react'
import axios from '../../axios/axios'

export default function EditQuestionnaireTexts({
    close,
    quizz,
}: {
    close: () => void
    quizz: IQuestionnaire
}) {
    const [data, setData] = useState<IQuestionnaireText>({
        date: quizz.date || '',
        hour: quizz.hour || '',
        qualifiedForNextStep: quizz.qualifiedForNextStep || '',
        notQualifiedForNextStep: quizz.notQualifiedForNextStep || '',
        waitingForQualificationInformation:
            quizz.waitingForQualificationInformation || '',
        convoquedForNextStep: quizz.convoquedForNextStep || '',
    })

    function confirm(data: IQuestionnaireText) {
        console.log(data)
        axios.patch(`/questionnaire/${quizz.id}`, data).then((res) => {
            console.log(res)
            close()
        })
    }

    return (
        <div style={{ width: '100%' }}>
            <h1>Editer les textes du questionnaire</h1>
            <form className="" id="addSingleUser">
                <div
                    className="add-modal modal-content-scrollable flex"
                    style={{ flexDirection: 'column' }}
                >
                    <Input
                        title="Utilisateur qualifié pour le prochain questionnaire"
                        value={data.qualifiedForNextStep}
                        multiple={true}
                        onChange={(e) =>
                            setData({
                                ...data,
                                qualifiedForNextStep: e.target.value,
                            })
                        }
                        required
                    />
                    <Input
                        title="Utilisateur non qualifié pour le prochain questionnaire"
                        value={data.notQualifiedForNextStep}
                        multiple={true}
                        onChange={(e) =>
                            setData({
                                ...data,
                                notQualifiedForNextStep: e.target.value,
                            })
                        }
                        required
                    />
                    <Input
                        title="Utilisateur en attente de savoir s'il est qualifié ou non pour le prochain questionnaire"
                        value={data.waitingForQualificationInformation}
                        multiple={true}
                        onChange={(e) =>
                            setData({
                                ...data,
                                waitingForQualificationInformation:
                                    e.target.value,
                            })
                        }
                        required
                    />
                    <Input
                        title="Utilisateur convoqué pour le prochain questionnaire"
                        value={data.convoquedForNextStep}
                        multiple={true}
                        onChange={(e) =>
                            setData({
                                ...data,
                                convoquedForNextStep: e.target.value,
                            })
                        }
                        required
                    />
                    <Input
                        title="Date de la convocation"
                        value={data.date}
                        multiple={true}
                        onChange={(e) =>
                            setData({
                                ...data,
                                date: e.target.value,
                            })
                        }
                        required
                    />
                    <Input
                        title="Heure de la convocation"
                        value={data.hour}
                        multiple={true}
                        onChange={(e) =>
                            setData({
                                ...data,
                                hour: e.target.value,
                            })
                        }
                        required
                    />
                </div>
                <div className="buttons-modal-multiple">
                    <button
                        className="buttons-border-off school-button-border-off"
                        onClick={close}
                    >
                        Annuler
                    </button>
                    <button
                        className="buttons-blue school-button"
                        type="submit"
                        onClick={(e) => {
                            e.preventDefault()
                            const formElement = document.getElementById(
                                'addSingleUser'
                            ) as HTMLFormElement

                            if (formElement.checkValidity()) {
                                confirm(data)
                            } else {
                                formElement.requestSubmit()
                            }
                        }}
                    >
                        Modifier
                    </button>
                </div>
            </form>
        </div>
    )
}
