import { useEffect, useState } from 'react'
import Input from '../common/Input'
import SaveButton from '../../utils/components/SaveButton'
import IGradesToQualify from '../../../types/IGradesToQualify'
import axios from '../../../axios/axios'
import { toast } from 'react-toastify'

export default function QuestionnaireThresholds({
    category,
    defaultNumberPoint,
    defaultCoef,
    questionnaireId,
}: {
    category: string
    defaultNumberPoint: number
    defaultCoef: number
    questionnaireId: string
}) {
    const [numberPoint, setNumberPoint] = useState(defaultNumberPoint)
    const [coeff, setCoeff] = useState(defaultCoef)
    const [thresholds, setThresholds] = useState<number>(0)
    async function saveGrades() {
        axios
            .post(`/users/results/threshold/${questionnaireId}`, [
                {
                    category: category,
                    grade: numberPoint,
                    weightedGrade: coeff,
                },
            ])
            .then((data) => {
                toast.success('Le seuil a bien été mis a jour')
            })
    }
    async function saveChanges() {
        axios
            .post(
                `/users/results/qualify/${questionnaireId}?category=${category}`
            )
            .then((data) => {
                toast.success('Les changements ont bien été enregistrés')
                toast.info('Il y a eu ' + data.data.length + ' qualifié(s)')
            })
    }
    async function resetChanges() {
        axios
            .post(
                `/users/results/reset/qualify/${questionnaireId}?category=${category}`
            )
            .then((data) => {
                toast.success(
                    "Tous les qualifiés ont été réinitialisés, comme s'il ne l'avait jamais été"
                )
            })
    }
    useEffect(() => {
        if (numberPoint >= 0 && coeff >= 0)
            axios
                .get(
                    `/users/results/threshold/${questionnaireId}?grade=${numberPoint}&weighted-grade=${coeff}&category=${category}`,
                    {}
                )
                .then((data) => {
                    setThresholds(data.data.count[1])
                })
    }, [numberPoint, coeff])
    return (
        <>
            <div className="row" key={category}>
                <div
                    style={{ maxWidth: '90px' }}
                    className="col threshold-title threshold-element"
                >
                    {category}
                </div>

                <div className="col threshold-element px-2">
                    <Input
                        value={numberPoint + ''}
                        onChange={(e) =>
                            setNumberPoint(parseInt(e.target.value || '0'))
                        }
                        title="Ex: 0, 1, 2, 3"
                    />
                </div>
                <div className="col threshold-element px-2">
                    <Input
                        value={coeff + ''}
                        onChange={(e) =>
                            setCoeff(parseInt(e.target.value || '0'))
                        }
                        title="Ex: 0, 1, 2, 3"
                    />
                </div>
                <div
                    className="col threshold-element"
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    <div>{thresholds}</div>
                </div>
                <div className="col threshold-element">
                    <SaveButton
                        threshold={null as unknown as IGradesToQualify}
                        saveChanges={(e) => saveGrades()}
                        title="Enregistrer les seuils"
                    />
                    <SaveButton
                        threshold={null as unknown as IGradesToQualify}
                        saveChanges={(e) => saveChanges()}
                        title="Enregistrer les qualifiés"
                    />
                    <SaveButton
                        threshold={null as unknown as IGradesToQualify}
                        saveChanges={(e) => resetChanges()}
                        title="Réinitialiser les qualifiés"
                    />
                </div>
            </div>
        </>
    )
}
