import Select from 'react-select'
import axios from '../../../axios/axios'
import { useEffect, useState } from 'react'
import { iSchoolUser } from '../../../types/user'
import { ecategories, elangs } from '../../../types/enums'
import Input from '../common/Input'
import { getCategories } from '../../../helpers/getCategories'
import { getLanguages } from '../../../helpers/getLanguages'

function ScolaireForm({
    active,
    user,
    setUser,
}: {
    active: string
    user: iSchoolUser
    setUser: (args: iSchoolUser) => void
}) {
    const [EstablishementOptions, setEstablishementOptions] = useState([
        { value: '0', label: 'Aucun établissement trouvé', id: 'x' },
    ])

    const [catsOptions, setCatsOptions] = useState<
        { value: string; label: string }[]
    >([])
    const [langsOptions, setLangsOptions] = useState<
        { value: string; label: string }[]
    >([])

    useEffect(() => {
        getCategories().then((cats) => setCatsOptions(cats))
        getLanguages().then((langs) => setLangsOptions(langs))

        axios
            .get(`/establishement/inscription`)
            .then((res) => {
                setEstablishementOptions(() =>
                    res.data.map(
                        (e: {
                            rneCode: string
                            name: string
                            id: string
                            city: string
                        }) => {
                            return {
                                value: e.rneCode,
                                label: `${e.name} (${e.city})`,
                                id: e.id,
                            }
                        }
                    )
                )
            })
            .catch()
    }, [])

    return (
        <form className="forms-insc" id="formulaires-inscription">
            <h2 className="forms-title-insc">{active}</h2>
            <div className="forms-inputs">
                {active == 'Informations' && (
                    <>
                        <Input
                            title="Nom"
                            defaultValue={user.contact.lastname}
                            value={user.contact.lastname}
                            onChange={(e) => {
                                setUser({
                                    ...user,
                                    contact: {
                                        ...user.contact,
                                        lastname: e.target.value,
                                    },
                                })
                            }}
                            required
                        />
                        <Input
                            title="Prénom"
                            defaultValue={user.contact.firstname}
                            value={user.contact.firstname}
                            onChange={(e) => {
                                setUser({
                                    ...user,
                                    contact: {
                                        ...user.contact,
                                        firstname: e.target.value,
                                    },
                                })
                            }}
                            required
                        />
                        <Input
                            title="Date de naissance"
                            defaultValue={
                                user.birthday != null
                                    ? `${(
                                          user.birthday as Date
                                      ).getFullYear()}-${(
                                          (user.birthday as Date).getMonth() + 1
                                      )
                                          .toString()
                                          .padStart(2, '0')}-${(
                                          user.birthday as Date
                                      )
                                          .getDate()
                                          .toString()
                                          .padStart(2, '0')}`
                                    : undefined
                            }
                            type="date"
                            onChange={(e) => {
                                setUser({
                                    ...user,
                                    birthday: new Date(e.target.value),
                                })
                            }}
                            required
                        />

                        <div className="insc-inputs">
                            <span className="inputs-title-insc required">
                                Établissement
                            </span>
                            <Select
                                options={EstablishementOptions}
                                isSearchable={true}
                                placeholder=""
                                onChange={(e) => {
                                    //console.log(e?.value)
                                    setUser({
                                        ...user,
                                        establishmentId: e?.value,
                                        languageName: elangs.FRANCAIS,
                                    })
                                }}
                                value={
                                    EstablishementOptions.find(
                                        (option) =>
                                            option.value ===
                                            user.establishmentId
                                    ) || null
                                }
                                required
                            />
                        </div>
                        <div className="insc-inputs">
                            <span className="inputs-title-insc required">
                                Catégorie
                            </span>
                            <Select
                                required
                                options={catsOptions}
                                isSearchable={true}
                                placeholder=""
                                onChange={(e) =>
                                    setUser({
                                        ...user,
                                        categoryName: e?.value as ecategories,
                                    })
                                }
                                value={
                                    catsOptions.find(
                                        (option) =>
                                            option.label === user.categoryName
                                    ) || null
                                }
                            />
                        </div>
                        <div className="insc-inputs">
                            <span className="inputs-title-insc required">
                                Langue de participation
                            </span>
                            <Select
                                options={langsOptions}
                                isSearchable={true}
                                placeholder=""
                                onChange={(e) =>
                                    setUser({
                                        ...user,
                                        languageName: e?.value,
                                    })
                                }
                                value={
                                    langsOptions.find(
                                        (option) =>
                                            option.label === user.languageName
                                    ) || null
                                }
                                required
                            />
                        </div>
                    </>
                )}

                {active == 'Coordonnées' && (
                    <>
                        <Input
                            title="Courriel"
                            defaultValue={user.contact.email}
                            type="email"
                            value={user.contact.email}
                            onChange={(e) =>
                                setUser({
                                    ...user,
                                    contact: {
                                        ...user.contact,
                                        email: e.target.value,
                                    },
                                })
                            }
                            required
                        />
                        <Input
                            title="Téléphone"
                            defaultValue={user.contact.phone}
                            type="tel"
                            value={user.contact.phone}
                            onChange={(e) =>
                                setUser({
                                    ...user,
                                    contact: {
                                        ...user.contact,
                                        phone: e.target.value,
                                    },
                                })
                            }
                            required
                        />
                        <Input
                            title="Rue"
                            defaultValue={user.contact.address}
                            value={user.contact.address}
                            onChange={(e) =>
                                setUser({
                                    ...user,
                                    contact: {
                                        ...user.contact,
                                        address: e.target.value,
                                    },
                                })
                            }
                            required
                        />
                        <Input
                            title="Ville"
                            defaultValue={user.contact.city}
                            value={user.contact.city}
                            onChange={(e) =>
                                setUser({
                                    ...user,
                                    contact: {
                                        ...user.contact,
                                        city: e.target.value,
                                    },
                                })
                            }
                            required
                        />
                        <Input
                            title="Code postal"
                            error={
                                user.contact.zip_code &&
                                user.contact.zip_code.length !== 5
                                    ? 'Le code postal doit contenir 5 chiffres'
                                    : ''
                            }
                            defaultValue={user.contact.zip_code}
                            value={user.contact.zip_code}
                            onChange={(e) =>
                                setUser({
                                    ...user,
                                    contact: {
                                        ...user.contact,
                                        zip_code: e.target.value,
                                    },
                                })
                            }
                            required
                        />
                        <Input
                            title="Pays"
                            defaultValue={user.contact.country}
                            value={user.contact.country}
                            onChange={(e) =>
                                setUser({
                                    ...user,
                                    contact: {
                                        ...user.contact,
                                        country: e.target.value,
                                    },
                                })
                            }
                            required
                        />
                    </>
                )}

                {active == 'Autorisations' && (
                    <>
                        <div style={{ gridArea: 'none' }}>
                            <div className="insc-inputs-checkbox">
                                <input
                                    className="chexkbox-insc"
                                    onChange={(e) =>
                                        setUser({
                                            ...user,
                                            imageCapture: !user.imageCapture,
                                        })
                                    }
                                    type="checkbox"
                                    defaultChecked={
                                        user.imageCapture ? true : false
                                    }
                                    required
                                />
                                <span className="inputs-title-insc required">
                                    J'accepte (ou mon responsable légal
                                    accepte.) que mon image soit utilisée par la
                                    FFJM à des fins de communication.
                                </span>
                            </div>
                            <div className="insc-inputs-checkbox">
                                <input
                                    className="chexkbox-insc"
                                    type="checkbox"
                                    defaultChecked={false}
                                    required
                                />
                                <span className="inputs-title-insc required">
                                    J'accepte de recevoir des communications de
                                    la FFJM par mail (indispensable pour le
                                    championnat)
                                </span>
                            </div>
                            <div className="insc-inputs-checkbox">
                                <input
                                    className="chexkbox-insc"
                                    onChange={(e) => {
                                        setUser({
                                            ...user,
                                            communication: !user.communication,
                                        })
                                    }}
                                    type="checkbox"
                                    defaultChecked={
                                        user.communication ? true : false
                                    }
                                />
                                <span className="inputs-title-insc ">
                                    J'accepte de recevoir des communications des
                                    partenaires de la FFJM
                                </span>
                            </div>
                        </div>
                    </>
                )}

                {active == 'Confirmation' && (
                    <>
                        <Input
                            title="Nom"
                            value={user.contact.lastname}
                            disabled
                        />

                        <Input
                            title="Prénom"
                            value={user.contact.firstname}
                            disabled
                        />
                        <Input
                            title=" Date de naissance"
                            value={
                                user.birthday != null
                                    ? `${(
                                          user.birthday as Date
                                      ).getFullYear()}-${(
                                          (user.birthday as Date).getMonth() + 1
                                      )
                                          .toString()
                                          .padStart(2, '0')}-${(
                                          user.birthday as Date
                                      )
                                          .getDate()
                                          .toString()
                                          .padStart(2, '0')}`
                                    : undefined
                            }
                            disabled
                        />
                        <Input
                            title="Établissement"
                            value={
                                EstablishementOptions.find(
                                    (establishement) =>
                                        establishement.value ===
                                        user.establishmentId
                                )?.label
                            }
                            disabled
                        />
                        <Input
                            title="Catégorie"
                            value={
                                user.categoryName == null
                                    ? undefined
                                    : user.categoryName
                            }
                            disabled
                        />
                        <Input
                            title="Courriel"
                            value={user.contact.email}
                            disabled
                        />
                        <Input
                            title="Téléphone"
                            value={user.contact.phone}
                            disabled
                        />
                        <Input
                            title="Adresse"
                            value={user.contact.address}
                            disabled
                        />
                        <Input
                            title="Ville"
                            value={user.contact.city}
                            disabled
                        />
                        <Input
                            title="Code postal"
                            error={
                                user.contact.zip_code &&
                                user.contact.zip_code.length !== 5
                                    ? 'Le code postal doit contenir 5 chiffres'
                                    : ''
                            }
                            value={user.contact.zip_code}
                            disabled
                        />
                        <Input
                            title="Pays"
                            value={user.contact.country}
                            disabled
                        />
                        <Input
                            title="Droit à l'image"
                            value={user.imageCapture ? 'Oui' : 'Non'}
                            disabled
                        />
                        <Input
                            title="Communication de la FFJM"
                            value={'Oui'}
                            disabled
                        />
                        <Input
                            title="Communication partenaires"
                            value={user.communication ? 'Oui' : 'Non'}
                            disabled
                        />
                    </>
                )}
            </div>
            <button
                id="Inscription-sub-button"
                type="submit"
                style={{ display: 'none' }}
                onClick={(e) => e.preventDefault()}
            ></button>
        </form>
    )
}
export default ScolaireForm
