import { iIndividualUser } from '../../../types/user'
import Input from '../common/Input'

function IndividualRecapForm({ user }: { user: iIndividualUser }) {
    return (
        <>
            <Input value={user.contact.lastname} title="Nom" disabled />
            <Input value={user.contact.firstname} title="Prénom" disabled />
            <Input
                value={
                    user.birthday != null
                        ? `${(user.birthday as Date).getFullYear()}-${(
                              (user.birthday as Date).getMonth() + 1
                          )
                              .toString()
                              .padStart(2, '0')}-${(user.birthday as Date)
                              .getDate()
                              .toString()
                              .padStart(2, '0')}`
                        : undefined
                }
                title="Date de naissance"
                disabled
            />
            <Input value={user.genre} title="Genre" disabled />

            {/* <Input value={user.numFFJM} title="Numéro FFJM" disabled /> */}
            <Input value={user.categoryName} title="Catégorie" disabled />
            <Input value={user.contact.email} title="Courriel" disabled />
            <Input value={user.contact.phone} title="Téléphone" disabled />
            <Input value={user.contact.address} title="Adresse" disabled />
            <Input value={user.contact.city} title="Ville" disabled />
            <Input
                value={user.contact.zip_code}
                title="Code postal"
                disabled
                error={
                    user.contact.zip_code && user.contact.zip_code.length !== 5
                        ? 'Attention le code postal ne contient pas 5 chiffres'
                        : ''
                }
            />
            <Input value={user.contact.country} title="Pays" disabled />
            <Input
                value={user.imageCapture ? 'Oui' : 'Non'}
                title="Droit à l'image"
                disabled
            />
            <Input value={'Oui'} title="Communication de la FFJM" disabled />
            <Input
                value={user.communication ? 'Oui' : 'Non'}
                title="Communication des partenaires"
                disabled
            />
        </>
    )
}
export default IndividualRecapForm
