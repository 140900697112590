import { useEffect, useState } from 'react'
import NavbarAdmin from '../../components/NavbarAdmin'
import './adminParticipant.css'
import axios from '../../axios/axios'
import IQuestionnaire from '../../types/IQuestionnaire'
import Select from 'react-select'

function AdminExports() {
    const [questionnaires, setQuestionnaires] = useState<IQuestionnaire[]>([])

    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<{
        value: string
        label: string
    } | null>(null)
    useEffect(() => {
        axios.get('/questionnaire/').then((res) => {
            setQuestionnaires(res.data)
        })
    }, [])
    return (
        <>
            <div className="admin-container">
                <div className="navbar">
                    <div className="nav-title">Accès Administrateur</div>
                    <NavbarAdmin active="exports" />
                </div>
                <div className="page-body">
                    <p>
                        Depuis cette page vous pouvez exporter facilement
                        différentes données sous format excel. Séléctionnez vos
                        paramètre puis cliquez sur "Exporter".
                    </p>
                    <div
                        style={{
                            paddingInline: 17,
                            display: 'flex',
                            gap: 20,
                            justifyItems: 'center',
                            alignContent: 'center',
                            flexGrow: 1,
                            height: '80vh',
                            overflow: 'visible',
                        }}
                    >
                        {questionnaires.length > 0 && (
                            <div
                                className="insc-inputs"
                                style={{
                                    height: 'min-content',
                                }}
                            >
                                <span className="inputs-title-insc ">
                                    Notes séléctionnée
                                </span>
                                <Select
                                    options={[
                                        {
                                            value: 'Aucun filtre',
                                            label: 'Aucun filtre',
                                        },
                                        ...questionnaires.map(
                                            (questionnaire) => ({
                                                value: questionnaire.id,
                                                label: questionnaire.name,
                                            })
                                        ),
                                    ]}
                                    placeholder=""
                                    onChange={(e) => {
                                        setSelectedQuestionnaire(
                                            e as unknown as {
                                                value: string
                                                label: string
                                            }
                                        )
                                    }}
                                    value={selectedQuestionnaire}
                                />
                            </div>
                        )}
                        <div
                            className="insc-inputs"
                            style={{
                                display: 'flex',
                                height: 'min-content',
                            }}
                        >
                            <input type="checkbox" />
                            <span className="inputs-title-insc ">
                                Inclure le centre d'examen de l'étape suivante
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminExports
