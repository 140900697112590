import { useEffect, useState } from 'react'
import { TypeEstablishementOptions } from '../../../pages/inscription/Inscription'
import { eestablishements } from '../../../types/enums'
import { iEstablishementUser } from '../../../types/user'
import Select from 'react-select'
import Input from '../common/Input'

function verifyUaiCode(codeUai: string): boolean {
    const alphabet23: string[] = [
        'a',
        'b',
        'c',
        'd',
        'e',
        'f',
        'g',
        'h',
        'j',
        'k',
        'l',
        'm',
        'n',
        'p',
        'r',
        's',
        't',
        'u',
        'v',
        'w',
        'x',
        'y',
        'z',
    ]

    const codeUaiPropre: string = codeUai.toLowerCase().trim()

    const codeUaiPropreChiffres: string = codeUaiPropre.slice(0, 7)
    const codeUaiPropreLettre: string = codeUaiPropre.slice(7, 8)

    const rangCalcule: number = parseInt(codeUaiPropreChiffres) % 23

    const lettreCalculee: string = alphabet23[rangCalcule]
    return codeUaiPropreLettre === lettreCalculee
}

const getEstablishement = async (codeRNE: string) => {
    codeRNE = codeRNE.toLocaleUpperCase()
    if (codeRNE.length != 8 || !verifyUaiCode(codeRNE)) {
        return undefined
    }
    const res = await fetch(
        `https://data.education.gouv.fr/api/records/1.0/search/?dataset=fr-en-annuaire-education&q=&rows=1&refine.identifiant_de_l_etablissement=${codeRNE}`
    )
    const data = await res.json()
    if (data.records.length == 0) {
        return undefined
    } else {
        return data.records[0].fields
    }
}

function EtablishementInfosForm({
    user,
    setUser,
}: {
    user: iEstablishementUser
    setUser: (args: iEstablishementUser) => void
}) {
    const [type, setType] = useState<{
        value: eestablishements
        label: string
    } | null>(null)

    const [rneCodeState, setrneCodeState] = useState('')

    useEffect(() => {
        setrneCodeState(user?.establishment?.rneCode || '')
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        getEstablishement(rneCodeState).then((data) => {
            if (data) {
                setUser({
                    ...user,
                    establishment: {
                        rneCode: data.identifiant_de_l_etablissement,
                        name: user.establishment.name || data.nom_etablissement,
                        type:
                            user.establishment.type || data.type_etablissement,
                        street: user.establishment.street || data.adresse_1,
                        city: user.establishment.city || data.nom_commune,
                        zipCode: user.establishment.zipCode || data.code_postal,
                        country: user.establishment.country || 'France',
                    },
                })
                setType(
                    TypeEstablishementOptions.find(
                        (option) => option.label === data.type_etablissement
                    ) || null
                )
            }
        }) // eslint-disable-next-line
    }, [rneCodeState])
    return (
        <>
            <Input
                value={rneCodeState}
                title="Code RNE"
                onChange={(e) => {
                    setrneCodeState(e.target.value.toLocaleUpperCase())
                    setUser({
                        ...user,
                        establishment: {
                            ...user.establishment,
                            rneCode: e.target.value,
                        },
                    })
                }}
                defaultValue={user.establishment.rneCode}
            />
            <Input
                title="Nom"
                value={user.establishment.name}
                defaultValue={user.establishment.name}
                onChange={(e) => {
                    setUser({
                        ...user,
                        establishment: {
                            ...user.establishment,
                            name: e.target.value,
                        },
                    })
                }}
                required
            />

            <div className="insc-inputs">
                <span className="inputs-title-insc required">Type</span>
                <Select
                    options={TypeEstablishementOptions}
                    isSearchable={true}
                    placeholder=""
                    onChange={(e) =>
                        setUser({
                            ...user,
                            establishment: {
                                ...user.establishment,
                                type: e?.value as eestablishements,
                            },
                        })
                    }
                    value={
                        TypeEstablishementOptions.find(
                            (option) => option.label === user.establishment.type
                        ) || null
                    }
                    required
                />
            </div>
            <Input
                title="Rue"
                value={user.establishment.street}
                defaultValue={user.establishment.street}
                onChange={(e) =>
                    setUser({
                        ...user,
                        establishment: {
                            ...user.establishment,
                            street: e.target.value,
                        },
                    })
                }
                required
            />
            <Input
                title="Ville"
                value={user.establishment.city}
                defaultValue={user.establishment.city}
                onChange={(e) =>
                    setUser({
                        ...user,
                        establishment: {
                            ...user.establishment,
                            city: e.target.value,
                        },
                    })
                }
                required
            />
            <Input
                title="Code postal"
                value={parseInt(user.establishment.zipCode) || undefined}
                error={
                    user.establishment.zipCode &&
                    user.establishment.zipCode.length !== 5
                        ? 'Le code postal doit contenir 5 chiffres'
                        : ''
                }
                defaultValue={parseInt(user.establishment.zipCode) || undefined}
                onChange={(e) =>
                    setUser({
                        ...user,
                        establishment: {
                            ...user.establishment,
                            zipCode: e.target.value,
                        },
                    })
                }
                required
                type="text"
            />

            <Input
                title="Pays"
                value={user.establishment.country}
                defaultValue={user.establishment.country}
                onChange={(e) =>
                    setUser({
                        ...user,
                        establishment: {
                            ...user.establishment,
                            country: e.target.value,
                        },
                    })
                }
                required
            />
        </>
    )
}

export default EtablishementInfosForm
