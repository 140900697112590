import { JSX } from 'react/jsx-runtime'
import { iSchoolEditUser } from '../../../types/user'
import UpdateUser from '../../../components/modals/UpdateUser'

export const modifyModal = (
    participant: iSchoolEditUser,
    setModalType: (arg0: JSX.Element) => void,
    setModalActive: (state: boolean) => void,
    setTriggerEffect: (arg0: boolean) => void,
    triggerEffect: boolean,
    from: string
) => {
    setModalType(
        <>
            <UpdateUser
                close={async () => {
                    setModalActive(false)
                    setTimeout(() => setTriggerEffect(!triggerEffect), 500)
                }}
                confirm={async () => {
                    setModalActive(false)
                    setTimeout(() => setTriggerEffect(!triggerEffect), 500)
                }}
                initialValue={participant}
                from={from}
            />
        </>
    )
    setModalActive(true)
}
