import React, { SetStateAction, useEffect, useState } from 'react'
import { iRegistration, iResult } from '../types/user'
import { eStatus } from '../types/enums'
import Select from 'react-select'
import axios from '../axios/axios'

interface DetailRegistrationProps {
    registration: iRegistration
    categories?: { value: string; label: string }[]
}

const DetailRegistration: React.FC<DetailRegistrationProps> = ({
    registration,
    categories,
}) => {
    // Add your component logic here

    const [reg, setReg] = useState<iRegistration>(registration)
    const [isIndividual, setIsIndividual] = useState<boolean>(false)
    useEffect(() => {
        setReg(registration)
        setIsIndividual(!registration.establishment?.name)
    }, [registration])
    console.log('red', reg)

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
                {categories ? (
                    <div className="insc-inputs">
                        <span className={'inputs-title-insc required'}>
                            Catégorie
                        </span>
                        <Select
                            options={categories}
                            isSearchable={true}
                            placeholder=""
                            onChange={(e) => {
                                setReg({
                                    ...reg,
                                    category: {
                                        ...reg.category,
                                        name: e?.value || '',
                                    },
                                })
                                registration.category.name = e?.value || ''

                                axios.patch(
                                    `/registrations/category/${reg.id}/${e?.value}`
                                )
                            }}
                            value={
                                (reg.category &&
                                    categories.find(
                                        (option) =>
                                            option.label === reg.category.name
                                    )) ||
                                null
                            }
                            name="category"
                        />
                    </div>
                ) : (
                    <>{'Catégorie : ' + reg.category.name}</>
                )}
            </div>
            <div>
                {isIndividual
                    ? reg.club
                        ? `Club : ${reg.club?.name}`
                        : 'Participant individuel'
                    : `Etablissement : ${reg.establishment?.name}`}
            </div>
            <div>
                Statut :{' '}
                {reg.status === eStatus.CONFIRMED
                    ? 'Confirmé'
                    : isIndividual
                    ? `En attente de confirmation de l'adhésion ${
                          reg.club ? 'par le club' : ''
                      }`
                    : "En attente de confirmation de l'inscription par l'établissement"}
            </div>
        </div>
    )
}

export default DetailRegistration
