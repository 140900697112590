import ConfirmAction from './ConfirmAction'
import IUserWithQuestionnaireAnwsers, {
    iParticipantWithResultsDetails,
} from '../../types/IParticipant'

export default function SeeResultDetail({
    modalActive,
    setModalActive,
    detailsParticipantResult,
    participant,
}: {
    modalActive: boolean
    setModalActive: (active: boolean) => void
    detailsParticipantResult: iParticipantWithResultsDetails
    participant: IUserWithQuestionnaireAnwsers
}) {
    return (
        <ConfirmAction
            text="Réponses du Participant :"
            onClose={() => setModalActive(false)}
            active={modalActive}
            children={
                <div
                    style={{
                        maxHeight: '80vh',
                        overflowY: 'scroll',
                    }}
                >
                    <div>
                        <div>
                            Nombre de point :{' '}
                            {detailsParticipantResult?.result?.grade}
                        </div>
                        <div>
                            Nombre de coefficient :{' '}
                            {detailsParticipantResult?.result?.weightedGrade}
                        </div>
                        <div>
                            Catégorie :{' '}
                            {participant.registrations[0]?.category?.name}
                        </div>
                    </div>
                    <table className="table w-100">
                        <thead>
                            <tr className="px-5">
                                <th scope="col">Question</th>
                                <th scope="col">Réponses</th>
                                <th scope="col">Point</th>
                                <th scope="col">Coefficient</th>
                            </tr>
                        </thead>
                        {detailsParticipantResult?.details?.questions?.map(
                            (question, index) => (
                                <tbody>
                                    <tr key={index} className="px-5">
                                        {[
                                            <th key="title" scope="col">
                                                {question.answer.question.title}
                                            </th>,
                                            <td key="answer" scope="col">
                                                {' '}
                                                {question.answer.answers.join(
                                                    ', '
                                                )}{' '}
                                                {question.answer.totalAnswer
                                                    ? `(${question.answer.totalAnswer})`
                                                    : ''}{' '}
                                            </td>,
                                            <td key="point" scope="col">
                                                {question.addedGrade || 0}{' '}
                                            </td>,
                                            <td key="coeff" scope="col">
                                                {question.addedWeightedGrade ||
                                                    0}{' '}
                                            </td>,
                                        ]}
                                    </tr>
                                </tbody>
                            )
                        )}
                    </table>
                </div>
            }
        />
    )
}
