import { iIndividualUser } from '../../../types/user'
import Input from '../common/Input'

function IndividualContactForm({
    user,
    setUser,
}: {
    user: iIndividualUser
    setUser: (args: iIndividualUser) => void
}) {
    return (
        <>
            <Input
                defaultValue={user.contact.email}
                value={user.contact.email}
                title="Courriel"
                type="email"
                required
                onChange={(e) => {
                    setUser({
                        ...user,
                        contact: {
                            ...user.contact,
                            email: e.target.value,
                        },
                    })
                }}
            />
            <Input
                defaultValue={user.contact.phone}
                value={user.contact.phone}
                title="Téléphone"
                type="tel"
                onChange={(e) => {
                    setUser({
                        ...user,
                        contact: {
                            ...user.contact,
                            phone: e.target.value,
                        },
                    })
                }}
            />
            <Input
                defaultValue={user.contact.address}
                value={user.contact.address}
                title="Rue"
                required
                onChange={(e) => {
                    setUser({
                        ...user,
                        contact: {
                            ...user.contact,
                            address: e.target.value,
                        },
                    })
                }}
            />
            <Input
                defaultValue={user.contact.city}
                value={user.contact.city}
                title="Ville"
                required
                onChange={(e) => {
                    setUser({
                        ...user,
                        contact: {
                            ...user.contact,
                            city: e.target.value,
                        },
                    })
                }}
            />
            <Input
                defaultValue={user.contact.zip_code}
                value={user.contact.zip_code}
                title="Code postal"
                type="text"
                required
                onChange={(e) => {
                    setUser({
                        ...user,
                        contact: {
                            ...user.contact,
                            zip_code: e.target.value,
                        },
                    })
                }}
                error={
                    user.contact.zip_code && user.contact.zip_code.length !== 5
                        ? 'Le code postal doit contenir 5 chiffres'
                        : ''
                }
            />
            <Input
                defaultValue={user.contact.country}
                value={user.contact.country}
                title="Pays"
                required
                onChange={(e) => {
                    setUser({
                        ...user,
                        contact: {
                            ...user.contact,
                            country: e.target.value,
                        },
                    })
                }}
            />
        </>
    )
}
export default IndividualContactForm
