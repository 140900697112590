import axios from '../axios/axios'
import { useEffect, useState } from 'react'
import Table, { PageObject, TableStyle } from '../components/Table'
import { useNavigate, useParams } from 'react-router-dom'
import './admin/adminEtablissement.css'
import './SchoolExamCenter.css'
import Modal from '../components/Modal'
import * as XLSX from 'xlsx'
import Select from 'react-select'
import { Participant, ParticipantWithResults } from '../types/IParticipant'
import AddGradeToUser from '../components/modals/AddGradeToUser'
import IQuestionnaire from '../types/IQuestionnaire'
import { useSearchParams } from 'react-router-dom'
import { getCategories } from '../helpers/getCategories'
import ParticipantLayout from './layouts/ParticipantLayout'

type ExamCenter = {
    id: string
    name: string
    rneCode: string
    street: string
    city: string
    zipCode: string
    country: string
    seats: number
    typeCenters: {
        year: number
        examCenterId: string
        center: number
    }[]
    users: []
}
export default function SchoolExamCenter() {
    const navigate = useNavigate()
    const { id, centerId, questionnaireId } = useParams()

    const [pages, setPages] = useState<PageObject>({
        totalItems: 0,
        pages: 1,
        actual: 1,
    })
    const [searchParams, setSearchParams] = useSearchParams()

    const [examCenter, setExamCenter] = useState<ExamCenter | null>(null)
    const [data, setData] = useState<ParticipantWithResults[] | null>(null)
    const [triggerEffect, setTriggerEffect] = useState(false)

    const [ModalActive, setModalActive] = useState(false)
    const [modalType, setModalType] = useState<JSX.Element | null>(null)

    const [sessions, setSessions] = useState<
        | {
              value: string
              label: string
              previousQuestionnaireId: string
          }[]
        | null
    >(null)
    const [selectedSession, setSelectedSession] = useState<{
        value: string
        label: string
        previousQuestionnaireId: string
    } | null>(null)
    async function setCurrentUser() {
        console.log('id', id, centerId)
        const fetch = await axios.get(`/examcenter/${id || centerId || 'me'}`)
        const me = fetch.data
        setExamCenter(me)
    }

    const [catsOptions, setCatsOptions] = useState<
        { value: string; label: string }[]
    >([])
    useEffect(() => {
        getCategories().then((cats) => setCatsOptions(cats))
    }, [])
    const filterOptions = [
        { value: 'Aucun filtre', label: 'Aucun filtre' },
        { value: 'contact.lastname', label: 'Nom' },
        {
            value: 'contact.firstname',
            label: 'Prénom',
        },
        {
            value: 'category',
            label: 'Catégorie',
        },
    ]

    const [search, setSearch] = useState('')
    const [filterPrimary, setFilterPrimary] = useState<{
        value: string
        label: string
    } | null>(null)
    const [filterSecondary, setFilterSecondary] = useState<{
        value: string
        label: string
    } | null>(null)

    const [selectedCat, setSelectedCat] = useState({
        value: 'Toute',
        label: 'Toute',
    })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function setDataTable(selectedQuestionnaireId: string) {
        if ((!id && !centerId) || !selectedQuestionnaireId) return
        const fetch = await axios.get(
            `/examcenter/${
                id || centerId ? (id || centerId) + '/' : ''
            }participants/${selectedQuestionnaireId}?limit=10&page=${
                pages.actual ?? '1'
            }` +
                (search !== '' ? '&search=' + search : '') +
                (filterPrimary
                    ? '&sortBy=' + filterPrimary.value + ':ASC'
                    : '') +
                (filterSecondary
                    ? '&sortBy=' + filterSecondary.value + ':ASC'
                    : '') +
                (selectedCat?.value !== 'Toute'
                    ? '&filter.registrations.category=' + selectedCat.value
                    : '')
        )

        const meta = fetch.data.meta
        const users = fetch.data.data
        console.log('users', fetch, users)
        // const newusers: ParticipantWithResults[] = []
        for (let i = 0; i < users.length; i++) {
            const user = users[i]
            const grades = await axios
                .get(
                    `users/grades/${user.id}/${selectedQuestionnaireId}/${
                        centerId || id
                    }`
                )
                .catch(() => console.log('No grades : ' + user.id))

            users[i] = {
                ...user,
                result: grades?.data ? grades.data : null,
                category: user.registrations[0]?.category?.name,
                establishment: user.registrations[0]?.establishment,
            }
        }

        if (meta?.totalPages > -1 && meta?.currentPage > -1)
            setPages({
                totalItems: meta.totalItems,
                pages: meta.totalPages,
                actual:
                    meta?.totalPages < meta?.currentPage
                        ? meta.totalPages
                        : meta.currentPage,
            })

        setData(users)

        if (users?.length == 0) {
            setData(null)
            console.log('SET DATA TO NULL')
        }
    }

    const Titles: TableStyle[] = [
        {
            name: 'Nom',
            isSortable: true,
            filters: null,
            index: 'contact.lastname',
        },
        {
            name: 'Prénom',
            isSortable: true,
            filters: null,
            index: 'contact.firstname',
        },
        {
            name: 'Code postal',
            isSortable: false,
            filters: null,
            index: 'contact.zip_code',
        },
        {
            name: 'Date de naissance',
            isSortable: false,
            filters: null,
            index: 'birthday',
        },
        {
            name: 'Catégorie',
            isSortable: false,
            filters: null,
            index: 'category',
        },
        {
            name: 'RNE Établissement',
            isSortable: false,
            filters: null,
            index: 'establishment.rneCode',
        },
        {
            name: 'Nom Établissement',
            isSortable: false,
            filters: null,
            index: 'establishment.name',
        },
        {
            name: 'Ville Établissement',
            isSortable: false,
            filters: null,
            index: 'establishment.city',
        },
        {
            name: 'Langue',
            isSortable: false,
            filters: null,
            index: 'language.language',
        },
        {
            name: 'Notes',
            isSortable: false,
            filters: null,
            index: 'result.grade',
        },
        {
            name: 'Coefficient',
            isSortable: false,
            filters: null,
            index: 'result.weightedGrade',
        },
        {
            name: 'Temps',
            isSortable: false,
            filters: null,
            index: 'result.time',
        },
    ]

    const Pagination = (page: number) => {
        console.log('PAGES', page, pages)
        setPages({
            ...pages,
            pages: pages.pages,
            actual: pages.pages <= page ? pages.pages : page,
        })
    }

    const Export = () => {
        setModalType(
            <>
                <span>Voulez-vous exporter ces données ?</span>
                <div className="buttons-modal-multiple">
                    <button
                        className="buttons-border-off button-blue school-border-off"
                        onClick={() => setModalActive(false)}
                    >
                        Annuler
                    </button>
                    <button
                        className="buttons-blue school-button"
                        onClick={async () =>
                            selectedSession
                                ? await exportExcelFile(
                                      selectedSession?.previousQuestionnaireId,
                                      selectedSession.label
                                  )
                                : alert('Veuillez sélectionner une session')
                        }
                    >
                        Confirmer
                    </button>
                </div>
            </>
        )
        setModalActive(true)
    }

    const exportExcelFile = async (
        selectedQuestionnaireId: string,
        label: string
    ) => {
        const result: {
            Nom: string
            Prénom: string
            'Code Postal': string
            'Date de naissance': string
            Catégorie: string
            'RNE Établissement'?: string
            'Nom Établissement'?: string
            'Ville Établissement'?: string
            Langue?: string
            Note?: string
            Coefficient?: string
            Temps?: string
        }[] = []
        console.log(data)
        const allData = await axios.get(
            `/examcenter/${
                id || centerId
            }/participants/${selectedQuestionnaireId}?limit=0`
        )
        const allUsers: Participant[] = allData.data.data
        for (let i = 0; i < allUsers.length; i++) {
            const res = allUsers[i]
            console.log(selectedQuestionnaireId, questionnaireId)
            try {
                const grades = await axios.get(
                    `users/grades/${res.id}/${selectedQuestionnaireId}/${
                        centerId || id
                    }`
                )
                result.push({
                    Nom: res.contact.lastname,
                    Prénom: res.contact.firstname,
                    'Code Postal': res.contact.zip_code,
                    'Date de naissance': res.birthday,
                    Catégorie: res.registrations[0]?.category?.name,
                    'RNE Établissement':
                        res.registrations[0]?.establishment?.rneCode,
                    'Nom Établissement':
                        res.registrations[0]?.establishment?.name,
                    'Ville Établissement':
                        res.registrations[0]?.establishment?.city,
                    Langue: res.language?.language,
                    Note:
                        grades.data.grade !== undefined
                            ? grades.data.grade
                            : 'Non noté',
                    Coefficient:
                        grades.data.weightedGrade !== undefined
                            ? grades.data.weightedGrade
                            : 'Non noté',
                    Temps:
                        grades.data.time !== undefined
                            ? grades.data.time
                            : 'Non noté',
                })
            } catch (e) {
                result.push({
                    Nom: res.contact.lastname,
                    Prénom: res.contact.firstname,
                    'Code Postal': res.contact.zip_code,
                    'Date de naissance': res.birthday,
                    Catégorie: res.registrations[0].category.name,
                    'RNE Établissement':
                        res.registrations[0]?.establishment?.rneCode,
                    'Nom Établissement':
                        res.registrations[0]?.establishment?.name,
                    'Ville Établissement':
                        res.registrations[0]?.establishment?.city,
                    Langue: res.language?.language,
                    Note: 'Non noté',
                    Coefficient: 'Non noté',
                    Temps: 'Non noté',
                })
            }
        }

        const worksheet = XLSX.utils.json_to_sheet(result)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(
            workbook,
            worksheet,
            "Centre d'examen - Participants"
        )
        XLSX.writeFile(
            workbook,
            "Centre d'examen - Participants - " + label + '.xlsx'
        )
        setModalActive(false)
    }

    useEffect(() => {
        setCurrentUser()
        console.log('HERE')

        if (selectedSession)
            setDataTable(selectedSession.previousQuestionnaireId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pages.actual, triggerEffect])

    useEffect(() => {
        if (selectedSession)
            setDataTable(selectedSession.previousQuestionnaireId)
    }, [search, filterPrimary, filterSecondary, selectedCat, selectedSession])

    const [gradesEditable, setGradesEditables] = useState<boolean | null>(null)
    const [nextQuestionnaire, setNextQuestionnaire] = useState<IQuestionnaire>()
    const [link, setLink] = useState('')
    useEffect(() => {
        if (selectedSession) {
            axios
                .get(
                    `questionnaire/next/${selectedSession.previousQuestionnaireId}`
                )
                .then((res) => {
                    if (res.data.isClosed === false) {
                        setNextQuestionnaire(res.data)
                        setGradesEditables(true)
                    } else {
                        setGradesEditables(false)
                    }
                })
            axios
                .get(`questionnaire/link/${selectedSession.value}/${centerId}`)
                .then((res) => {
                    setLink(res.data.linkToDoc)
                })
        } else {
            axios.get(`examcenter/session/${centerId}`).then((res) => {
                const sess = res.data.map((sess: IQuestionnaire) => ({
                    value: sess.id,
                    label: sess.name,
                    previousQuestionnaireId: sess.previousQuestionnaireId,
                }))
                setSessions(sess)
                console.log('SESSIONS', sess)
                if (res.data.length <= 0) return
                setSelectedSession({
                    value: res.data[0].id,
                    label: res.data[0].name,
                    previousQuestionnaireId:
                        res.data[0].previousQuestionnaireId,
                })
                axios
                    .get(`questionnaire/link/${res.data[0].id}/${centerId}`)
                    .then((res) => {
                        setLink(res.data.linkToDoc)
                    })
            })
        }
    }, [selectedSession])

    function setGrades(participant: ParticipantWithResults) {
        setModalType(
            <>
                <AddGradeToUser
                    close={() => setModalActive(false)}
                    trigger={() => {
                        setTimeout(() => setTriggerEffect(!triggerEffect), 1000)
                    }}
                    participant={participant}
                    centerId={id || centerId}
                    questionnaireId={selectedSession?.previousQuestionnaireId}
                    nextQuestionnaire={nextQuestionnaire}
                />
            </>
        )
        setModalActive(true)
    }
    return (
        <ParticipantLayout color="red">
            <div
                style={{
                    padding: '15px',
                    marginTop: '20px',
                    width: '100%',
                    maxWidth: '1400px',
                }}
            >
                <div className="flex">
                    <div
                        className="flex-none text-start"
                        style={{ padding: '5px 15px' }}
                    >
                        <button
                            className="buttons-blue school-button-border-off"
                            onClick={() =>
                                navigate(
                                    searchParams.get('return') ||
                                        '/etablissement'
                                )
                            }
                        >
                            Retour
                        </button>
                    </div>

                    <div
                        className="flex-1"
                        style={{ textAlign: 'right', padding: '5px 15px' }}
                    >
                        {' '}
                        {link !== '' && (
                            <button
                                className="buttons-blue school-button"
                                onClick={() => {
                                    window.open(link)
                                }}
                            >
                                Accès documentation
                            </button>
                        )}
                        <button
                            className="buttons-blue school-button"
                            onClick={Export}
                        >
                            Exporter en Excel
                        </button>
                    </div>
                </div>
                <div className="flex">
                    <div
                        className="flex-1 text-start"
                        style={{ padding: '0 20px' }}
                    >
                        <h4>
                            Nom :{' '}
                            <span style={{ fontWeight: 'normal' }}>
                                {examCenter?.name ?? 'Chargement...'}
                            </span>
                        </h4>
                        <h4>
                            Adresse :{' '}
                            <span style={{ fontWeight: 'normal' }}>
                                {examCenter?.street || ''}{' '}
                                {examCenter?.zipCode || ''}{' '}
                                {examCenter?.city || ''}
                            </span>
                        </h4>
                    </div>
                    <div className="flex-none" style={{ padding: '0 20px' }}>
                        <h4>
                            Nombre de participants :{' '}
                            <span style={{ fontWeight: 'normal' }}>
                                {pages.totalItems ?? 'Chargement...'}
                            </span>
                        </h4>
                        <h4>
                            Nombre de places :{' '}
                            <span style={{ fontWeight: 'normal' }}>
                                {examCenter?.seats ?? 'Chargement...'}
                            </span>
                        </h4>
                    </div>
                </div>
                <div
                    className="w-100"
                    style={{
                        paddingInline: 17,
                        display: 'flex',
                        gap: 20,
                        alignItems: 'flex-end',
                    }}
                >
                    {sessions && (
                        <div className="insc-inputs">
                            <span className="inputs-title-insc ">Session</span>
                            <Select
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                noOptionsMessage={() =>
                                    'Aucune session disponible'
                                }
                                options={sessions}
                                placeholder=""
                                onChange={(e) => {
                                    setSelectedSession(
                                        e as unknown as {
                                            value: string
                                            label: string
                                            previousQuestionnaireId: string
                                        }
                                    )
                                }}
                                value={selectedSession}
                            />
                        </div>
                    )}
                    <div className="insc-inputs" id="admin-search">
                        <input
                            className="insc-input"
                            type="text"
                            placeholder="Rechercher un participant..."
                            onChange={(e) => setSearch(() => e.target.value)}
                        />
                    </div>
                    <div className="insc-inputs">
                        <span className="inputs-title-insc ">
                            Ordonner par (primaire)
                        </span>
                        <Select
                            options={filterOptions}
                            placeholder=""
                            onChange={(e) => {
                                setFilterPrimary(
                                    e as unknown as {
                                        value: string
                                        label: string
                                    }
                                )
                            }}
                            value={filterPrimary}
                        />
                    </div>
                    <div className="insc-inputs">
                        <span className="inputs-title-insc">
                            Ordonner par (secondaire)
                        </span>
                        <Select
                            options={filterOptions}
                            placeholder=""
                            onChange={(e) => {
                                setFilterSecondary(
                                    e as unknown as {
                                        value: string
                                        label: string
                                    }
                                )
                            }}
                            value={filterSecondary}
                        />
                    </div>
                    <div className="insc-inputs">
                        <span className="inputs-title-insc">
                            Filtrer par catégorie
                        </span>
                        <Select
                            options={[
                                { value: 'Toute', label: 'Toute' },
                                ...catsOptions,
                            ]}
                            placeholder=""
                            onChange={(e) => {
                                setSelectedCat(
                                    e as unknown as {
                                        value: string
                                        label: string
                                    }
                                )
                            }}
                            value={selectedCat}
                        />
                    </div>
                </div>
                {gradesEditable !== null && (
                    <Table
                        showActions={gradesEditable}
                        titles={Titles}
                        data={data}
                        color="--rose-vif100"
                        pagination={Pagination}
                        filter={() => {
                            return null
                        }}
                        page={pages}
                        actions={{
                            modify: {
                                action: setGrades,
                                showCondition: () => gradesEditable,
                            },
                        }}
                        margin="30"
                    />
                )}
            </div>
            <Modal
                isActive={ModalActive}
                setActive={setModalActive}
                children={modalType}
            ></Modal>
        </ParticipantLayout>
    )
}
