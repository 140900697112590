import { TableStyle } from './typeTables'

const constAdminUsers: TableStyle[] = [
    {
        name: 'Nom',
        isSortable: true,
        filters: null,
        index: 'contact.lastname',
    },
    {
        name: 'Prénom',
        isSortable: true,
        filters: null,
        index: 'contact.firstname',
    },
    {
        name: 'Email',
        isSortable: false,
        filters: null,
        index: 'contact.email',
    },
    {
        name: 'Code postal',
        isSortable: false,
        filters: null,
        index: 'contact.zip_code',
    },
    {
        name: 'Identifiant',
        isSortable: true,
        filters: null,
        index: 'username',
    },
    {
        name: 'Téléphone',
        isSortable: true,
        filters: null,
        index: 'contact.phone',
    },
]

export default constAdminUsers
