import { useEffect, useState } from 'react'
import { iSchoolEditUser } from '../../types/user'
import Input from '../forms/common/Input'
import Modal from '../Modal'
import UpdateUser from './UpdateUser'
import { useNavigate } from 'react-router-dom'
import { ExamCenter } from '../../pages/admin/AdminExam'

export default function UpdateExamCenter({
    close,
    confirm,
    data,
    initialValue,
    selectedQuestionnaire,
}: {
    close: () => void
    confirm: (dataToSend: ExamCenter) => void
    data: ExamCenter
    initialValue: ExamCenter
    selectedQuestionnaire?: string
}) {
    const [ModalActive, setModalActive] = useState(false)
    const [modalType, setModalType] = useState<JSX.Element | null>(null)

    const [dataToSend, setDataToSend] = useState<ExamCenter>()
    useEffect(() => {
        const datemp = {
            id: initialValue.id,
            name: initialValue.name,
            seats: initialValue.seats,
            street: initialValue.street,
            country: initialValue.country,
            city: initialValue.city,
            zipCode: initialValue.zipCode,
            typeCenter: initialValue.typeCenter,
            rneCode: initialValue.rneCode,
        } as ExamCenter

        setDataToSend(datemp)
    }, [initialValue])
    function editAssociatedUser(participant?: iSchoolEditUser) {
        if (participant != undefined) {
            setModalType(
                <>
                    <UpdateUser
                        close={() => setModalActive(false)}
                        confirm={async () => {
                            setModalActive(false)
                        }}
                        initialValue={participant}
                        from="adminEtablissement"
                    />
                </>
            )
            setModalActive(true)
        }
    }
    const navigate = useNavigate()

    if (dataToSend == undefined) return <>Chargement</>
    return (
        <div style={{ width: '80vw' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h1 style={{ marginBottom: 10 }}>
                    Modifier ce centre d'épreuve
                </h1>
                <span style={{ marginBottom: 10 }}>
                    Identifiant unique : {initialValue.id}
                </span>
                <div style={{ display: 'flex' }}>
                    {initialValue.user != undefined && (
                        <div>
                            <button
                                className="buttons-blue buttons-blue blue-admin"
                                onClick={() =>
                                    editAssociatedUser(initialValue.user)
                                }
                            >
                                Modifier l'utilisateur associé
                            </button>
                        </div>
                    )}
                    {selectedQuestionnaire ? (
                        <div>
                            <button
                                className="buttons-blue buttons-blue blue-admin"
                                onClick={() =>
                                    // editAssociatedUser(initialValue.user)
                                    navigate(
                                        '' +
                                            initialValue.id +
                                            '/' +
                                            selectedQuestionnaire +
                                            '?return=/admin/centres'
                                    )
                                }
                            >
                                Voir les participants convoqués
                            </button>
                        </div>
                    ) : (
                        'Veuillez séléctionner une étape pour voir les participants convoqués'
                    )}
                </div>
            </div>
            <form>
                <div className="add-modal modal-content-scrollable">
                    <Input
                        title="Nom"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                name: e.target.value,
                            })
                        }
                        value={dataToSend.name}
                        required
                    />

                    <Input
                        title="Code RNE"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                rneCode: e.target.value,
                            })
                        }
                        value={dataToSend.rneCode}
                    />
                    <Input
                        title="Rue"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                street: e.target.value,
                            })
                        }
                        value={dataToSend.street}
                    />
                    <Input
                        title="Ville"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                city: e.target.value,
                            })
                        }
                        value={dataToSend.city}
                    />
                    <Input
                        title="Code postal"
                        error={
                            dataToSend.zipCode &&
                            dataToSend.zipCode.length !== 5
                                ? 'Le code postal doit contenir 5 chiffres'
                                : ''
                        }
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                zipCode: e.target.value,
                            })
                        }
                        value={dataToSend.zipCode}
                        required
                    />
                    <Input
                        title="Pays"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                country: e.target.value,
                            })
                        }
                        value={dataToSend.country}
                    />
                    <Input
                        title="Nombre de places"
                        type="number"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                seats: Number(e.target.value),
                            })
                        }
                        value={dataToSend.seats}
                    />
                </div>
                <div className="buttons-modal-multiple">
                    <button
                        className="buttons-border-off admin-border-off"
                        onClick={close}
                    >
                        Annuler
                    </button>
                    <button
                        className="buttons-blue buttons-blue blue-admin"
                        onClick={() => {
                            if (dataToSend?.name != undefined) {
                                confirm(dataToSend)
                            } else {
                                console.log('Données non conformes')
                            }
                        }}
                    >
                        Confirmer
                    </button>
                </div>
            </form>
            <Modal
                isActive={ModalActive}
                setActive={setModalActive}
                children={modalType}
            ></Modal>
        </div>
    )
}
