import Select from 'react-select'

import { iSchoolUser } from '../../types/user'
import Input from '../forms/common/Input'
import { useEffect, useState } from 'react'
import { getCategories } from '../../helpers/getCategories'
import { getLanguages } from '../../helpers/getLanguages'

export default function AddUser({
    close,
    setData,
    confirm,
    data,
    from,
}: {
    close: () => void
    setData: (data: iSchoolUser) => void
    confirm: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    data: iSchoolUser
    from: 'admin' | 'establishment'
}) {
    const [catsOptions, setCatsOptions] = useState<
        { value: string; label: string }[]
    >([])
    const [langsOptions, setLangsOptions] = useState<
        { value: string; label: string }[]
    >([])
    useEffect(() => {
        getLanguages().then((langs) => setLangsOptions(langs))

        getCategories().then((cats) => setCatsOptions(cats))
    }, [])
    const customStylesShort = {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        menuList: (base: any) => ({
            ...base,
            maxHeight: 140,
        }),
    }
    return (
        <div style={{ width: '80vw' }}>
            <h1>Ajouter un qualifié</h1>
            <form className="" id="addSingleUser">
                <div className="add-modal modal-content-scrollable">
                    <Input
                        title="Nom"
                        onChange={(e) =>
                            setData({
                                ...data,
                                contact: {
                                    ...data.contact,
                                    lastname: e.target.value,
                                },
                            })
                        }
                        required
                    />
                    <Input
                        title="Prénom"
                        onChange={(e) =>
                            setData({
                                ...data,
                                contact: {
                                    ...data.contact,
                                    firstname: e.target.value,
                                },
                            })
                        }
                        required
                    />
                    <Input
                        title="E-mail"
                        type="email"
                        onChange={(e) =>
                            setData({
                                ...data,
                                contact: {
                                    ...data.contact,
                                    email: e.target.value,
                                },
                            })
                        }
                        required
                    />
                    <Input
                        title="Date de naissance"
                        type="date"
                        onChange={(e) =>
                            setData({
                                ...data,
                                birthday: new Date(e.target.value),
                            })
                        }
                        required
                    />
                    <div className="insc-inputs">
                        <span className="inputs-title-insc required">
                            Catégorie
                        </span>
                        <Select
                            options={catsOptions}
                            isSearchable={true}
                            placeholder=""
                            styles={customStylesShort}
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    categoryName: e?.value,
                                })
                            }
                            value={
                                catsOptions.find(
                                    (option) =>
                                        option.label === data.categoryName
                                ) || null
                            }
                            name="category"
                            required
                        />
                    </div>
                    <div className="insc-inputs">
                        <span className="inputs-title-insc required">
                            Langue
                        </span>
                        <Select
                            styles={customStylesShort}
                            options={langsOptions}
                            isSearchable={true}
                            placeholder=""
                            onChange={(e) =>
                                setData({
                                    ...data,
                                    languageName: e?.value,
                                })
                            }
                            value={
                                langsOptions.find(
                                    (option) =>
                                        option.label === data.languageName
                                ) || null
                            }
                            name="lang"
                            required
                        />
                    </div>
                    <Input
                        title="Adresse"
                        type="text"
                        onChange={(e) =>
                            setData({
                                ...data,
                                contact: {
                                    ...data.contact,
                                    address: e.target.value,
                                },
                            })
                        }
                    />
                    <Input
                        title="Ville"
                        type="text"
                        onChange={(e) =>
                            setData({
                                ...data,
                                contact: {
                                    ...data.contact,
                                    city: e.target.value,
                                },
                            })
                        }
                    />
                    <Input
                        title="Code postal"
                        type="text"
                        error={
                            data.contact &&
                            data.contact.zip_code &&
                            data.contact.zip_code.length !== 5
                                ? 'Le code postal doit contenir 5 chiffres'
                                : ''
                        }
                        onChange={(e) =>
                            setData({
                                ...data,
                                contact: {
                                    ...data.contact,
                                    zip_code: e.target.value,
                                },
                            })
                        }
                        required
                    />
                    <Input
                        required={from === 'establishment'}
                        title="Pays"
                        type="text"
                        onChange={(e) =>
                            setData({
                                ...data,
                                contact: {
                                    ...data.contact,
                                    country: e.target.value,
                                },
                            })
                        }
                    />
                </div>
                <div className="buttons-modal-multiple">
                    <button
                        className="buttons-border-off school-button-border-off"
                        onClick={close}
                    >
                        Annuler
                    </button>
                    <button
                        className="buttons-blue school-button"
                        type="submit"
                        onClick={(e) => {
                            e.preventDefault()
                            const formElement = document.getElementById(
                                'addSingleUser'
                            ) as HTMLFormElement

                            if (formElement.checkValidity()) {
                                confirm(e)
                            } else {
                                formElement.requestSubmit()
                            }
                        }}
                    >
                        Confirmer
                    </button>
                </div>
            </form>
        </div>
    )
}
