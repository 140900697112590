import { useEffect, useState } from 'react'
import Input from '../forms/common/Input'
import Editor, { ContentEditableEvent } from 'react-simple-wysiwyg'
import './AdminQuestion.css'
import { nanoid } from 'nanoid'
import IQuestion from '../../types/IQuestion'
import ControlledInput from '../forms/common/ControlledInput'

export enum qType {
    SINGLE,
    MULTIPLE,
}

export default function AdminQuestion({
    subkey,
    id,
    title,
    text,
    image_start,
    image_end,
    isMultiple,
    regex,
    askTotal,
    openAnswer,
    position,
    coefficient,
    possibleAnswers,
    setQuestion,
}: {
    subkey: number
    id?: string
    title?: string
    text?: string
    image_start?: string
    image_end?: string
    regex?: string
    isMultiple: boolean
    askTotal: boolean
    openAnswer: number
    position?: number
    coefficient?: number
    possibleAnswers: {
        value: string
        isCorrect: boolean
    }[]
    setQuestion: (question: IQuestion) => void
}) {
    const [error, setError] = useState(false)

    function addAnswer() {
        setQuestion({
            id: id,
            title: title,
            text: text,
            image_start: image_start,
            regex: regex,
            image_end: image_end,
            isMultiple: isMultiple,
            askTotal: askTotal,
            openAnswer: openAnswer,
            position: position,
            coefficient: coefficient,
            possibleAnswers: [
                ...possibleAnswers,
                {
                    value: '',
                    isCorrect: false,
                },
            ],
        })
    }
    function setAnswerType(
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) {
        const checked = e.target.value
        setQuestion({
            id: id,
            title: title,
            text: text,
            image_start: image_start,
            image_end: image_end,
            position: position,
            coefficient: coefficient,
            isMultiple: checked == 'multiple',
            askTotal: checked == 'twoFreeAndTotal',
            openAnswer:
                checked == 'twoFreeAndTotal' || checked == 'twoFree'
                    ? 2
                    : checked == 'oneFree'
                    ? 1
                    : 0,
            possibleAnswers: possibleAnswers,
            regex: regex,
        })
    }
    function setAnswer(e: ContentEditableEvent) {
        setQuestion({
            id: id,
            title: title,
            text: e.target.value,
            image_start: image_start,
            image_end: image_end,
            isMultiple: isMultiple,
            askTotal: askTotal,
            openAnswer: openAnswer,
            possibleAnswers: possibleAnswers,
            position: position,
            coefficient: coefficient,
            regex: regex,
        })
    }
    function setPosition(e: ContentEditableEvent) {
        setQuestion({
            id: id,
            title: title,
            text: text,
            image_start: image_start,
            image_end: image_end,
            isMultiple: isMultiple,
            askTotal: askTotal,
            openAnswer: openAnswer,
            possibleAnswers: possibleAnswers,
            position: +e.target.value,
            coefficient: coefficient,
            regex: regex,
        })
    }
    function setCoefficient(e: ContentEditableEvent) {
        setQuestion({
            id: id,
            title: title,
            text: text,
            image_start: image_start,
            image_end: image_end,
            isMultiple: isMultiple,
            askTotal: askTotal,
            openAnswer: openAnswer,
            possibleAnswers: possibleAnswers,
            position: position,
            coefficient: +e.target.value,
            regex: regex,
        })
    }
    function setRegex(e: ContentEditableEvent) {
        setQuestion({
            id: id,
            title: title,
            text: text,
            regex: e.target.value,
            image_start: image_start,
            image_end: image_end,
            isMultiple: isMultiple,
            askTotal: askTotal,
            position: position,
            coefficient: coefficient,
            openAnswer: openAnswer,
            possibleAnswers: possibleAnswers,
        })
    }
    function setTitle(
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) {
        setQuestion({
            id: id,
            title: e.target.value,
            text: text,
            image_start: image_start,
            image_end: image_end,
            isMultiple: isMultiple,
            position: position,
            coefficient: coefficient,
            askTotal: askTotal,
            openAnswer: openAnswer,
            possibleAnswers: possibleAnswers,
            regex: regex,
        })
    }
    function setResponse(id: number, value?: string, isCorrect?: boolean) {
        possibleAnswers[id] = {
            ...possibleAnswers[id],
            value: value !== undefined ? value : possibleAnswers[id].value,
            isCorrect:
                isCorrect !== undefined
                    ? isCorrect
                    : possibleAnswers[id].isCorrect,
        }
    }
    function setImage(start?: string, end?: string) {
        setQuestion({
            id: id,
            title: title,
            text: text,
            image_start: start !== undefined ? start : image_start,
            image_end: end !== undefined ? end : image_end,
            isMultiple: isMultiple,
            position: position,
            coefficient: coefficient,
            askTotal: askTotal,
            openAnswer: openAnswer,
            possibleAnswers: possibleAnswers,
            regex: regex,
        })
    }
    return (
        <div className="admin-question-container">
            <div className="row row-md">
                <div className="col">
                    <Input
                        title="Titre (Inclure le numéro)"
                        defaultValue={title}
                        onChange={setTitle}
                    />
                    <div className="question-input-container">
                        <Editor
                            value={text || ''}
                            onChange={setAnswer}
                            key={subkey}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="question-input-container">
                        <span className={`inputs-title-insc`}>Type</span>
                        <Input
                            title="Choix multiple"
                            type="radio"
                            value={'multiple'}
                            name={`type${subkey}`}
                            onChange={setAnswerType}
                            isChecked={isMultiple}
                        />
                        <Input
                            title="Choix unique"
                            type="radio"
                            value={'single'}
                            name={`type${subkey}`}
                            onChange={setAnswerType}
                            isChecked={
                                !isMultiple && !askTotal && openAnswer == 0
                            }
                        />
                        <Input
                            title="Une réponse libre"
                            type="radio"
                            value={'oneFree'}
                            name={`type${subkey}`}
                            onChange={setAnswerType}
                            isChecked={openAnswer == 1}
                        />
                        <Input
                            title="Deux réponses libres"
                            type="radio"
                            value={'twoFree'}
                            name={`type${subkey}`}
                            onChange={setAnswerType}
                            isChecked={openAnswer == 2}
                        />
                        <Input
                            title="Deux réponse libre avec total"
                            type="radio"
                            value={'twoFreeAndTotal'}
                            name={`type${subkey}`}
                            onChange={setAnswerType}
                            isChecked={openAnswer == 2 && askTotal}
                        />
                    </div>
                </div>
            </div>
            <div className="row row-md">
                <div className="question-input-container inputs-title-insc">
                    Réponses
                </div>
                <div className="row">
                    {possibleAnswers.map((possibleAnswer, index) => (
                        <div key={nanoid()}>
                            <Input
                                type="text"
                                key={nanoid()}
                                defaultValue={possibleAnswer.value}
                                onChange={(e) =>
                                    setResponse(index, e.target.value)
                                }
                            />
                            <input
                                type="checkbox"
                                key={nanoid()}
                                defaultChecked={possibleAnswer.isCorrect}
                                onChange={(e) => {
                                    setResponse(
                                        index,
                                        undefined,
                                        e.target.checked
                                    )
                                }}
                            />
                        </div>
                    ))}
                    <div className="admin-question-button">
                        <button
                            className={'buttons-blue blue-admin'}
                            onClick={() => addAnswer()}
                        >
                            +
                        </button>
                    </div>
                </div>
            </div>
            <div className="col">
                <div className="row row-md">
                    <span className="question-input-container inputs-title-insc">
                        Images (BASE 64 - Evitez les images de plus de 10ko{' '}
                        <a
                            href="https://codebeautify.org/image-to-base64-converter"
                            target="_blank"
                            color="blue"
                        >
                            Lien site convertisseur
                        </a>
                        )
                    </span>
                </div>
                <div className="row row-md">
                    <Input
                        title="Avant le texte"
                        type="text"
                        defaultValue={image_start}
                        onChange={(e) => setImage(e.target.value, undefined)}
                    />
                    <img src={'data:image/png;base64,' + image_start} />
                    <Input
                        title="Après le texte"
                        type="text"
                        defaultValue={image_end}
                        onChange={(e) => setImage(undefined, e.target.value)}
                    />
                    <img src={'data:image/png;base64,' + image_end} />
                </div>
            </div>
            <div className="col">
                <div className="row row-md">
                    <span className="question-input-container inputs-title-insc">
                        Validation entrée utilisateur
                    </span>
                </div>
                <div className="row" style={{ width: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <Input
                            title="Regex, exemple : ^[0-9]*\.[0-9]+$"
                            type="text"
                            defaultValue={regex}
                            onChange={(e) => setRegex(e)}
                        />
                    </div>
                    <ControlledInput
                        title="Test (modifiez le texte pour tester)"
                        type="text"
                        defaultValue={"Test de l'entrée"}
                        regex={regex}
                    />
                </div>
            </div>
            <div className="col">
                <div className="row row-md">
                    <span className="question-input-container inputs-title-insc">
                        Réglage de la question
                    </span>
                </div>
                <div className="row" style={{ width: '100%' }}>
                    <div style={{ width: '100%' }}>
                        <Input
                            title="Position"
                            type="number"
                            defaultValue={position}
                            onChange={(e) => setPosition(e)}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <Input
                            title="Coefficient"
                            type="number"
                            defaultValue={coefficient}
                            onChange={(e) => setCoefficient(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
