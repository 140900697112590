import axios from '../../axios/axios'
import { useEffect, useState } from 'react'
import NavbarAdmin from '../../components/NavbarAdmin'

import './adminEtablissement.css'
import Input from '../../components/forms/common/Input'
import { useNavigate } from 'react-router-dom'
import Switch from '../../components/forms/common/Switch'
import AddQuestionnaire from '../../components/modals/AddQuestionnaire'
import Modal from '../../components/Modal'
import { toast } from 'react-toastify'
import EditQuestionnaireTexts from '../../components/modals/EditQuestionnaireTexts'
import IQuestionnaire from '../../types/IQuestionnaire'

export interface QuizzI {
    id: string
    name: string
    active: boolean
    isClosed: boolean
    year: number
    physical: boolean
    showOnUserDashboard: boolean
    previousQuestionnaire?: QuizzI
}
export interface QuestionI {
    id?: string
    title?: string
    text?: string
    imagePath?: string
    options?: string[]
    isMultiple?: boolean
    askTotal?: boolean
    openAnswer: number
    possibleAnswers: {
        value: string
        isCorrect: boolean
    }[]
}
function AdminQuizzList() {
    const [passcode, setPasscode] = useState('')
    const [admin, setAdmin] = useState(false)
    const [modalActive, setModalActive] = useState(false)
    const [quizz, setQuizz] = useState<IQuestionnaire[]>([])
    const [modal, setModal] = useState(<></>)
    const [triggerEffet, setTriggerEffet] = useState(false)
    const [years, setYears] = useState<{ year: string }[]>([])
    const [selectedYear, setSelectedYear] = useState<string>('')
    useEffect(() => {
        axios.get('/questionnaire/years').then((res) => {
            setYears(res.data)
            if (selectedYear === '')
                setSelectedYear(res.data[res.data.length - 1].year)
        })

        axios.get('/questionnaire').then((res) => setQuizz(res.data))
    }, [triggerEffet])

    function createQuizz() {
        console.log(quizz)
        setModal(
            <AddQuestionnaire
                close={() => {
                    setModalActive(false)
                    setModal(<></>)
                    setTriggerEffet(!triggerEffet)
                }}
                questionnairelist={quizz}
            />
        )
        setModalActive(true)
    }
    function editQuizzTexts(quiz: IQuestionnaire) {
        setModal(
            <EditQuestionnaireTexts
                close={() => {
                    setModalActive(false)
                    setModal(<></>)
                    setTriggerEffet(!triggerEffet)
                }}
                quizz={quiz}
            />
        )
        setModalActive(true)
    }

    useEffect(() => {
        const isAdmin =
            localStorage.getItem('isAdmin' + window.location.hostname) ||
            undefined
        if (isAdmin) {
            setAdmin(true)
        }
    }, [])

    async function changeQuizName(quizId: string, quizName: string = '') {
        const result = await prompt(
            'Indiquez le nouveau nom du questionnaire',
            quizName
        )
        console.log(result)
        if (result) {
            axios.patch(`/questionnaire/${quizId}`, {
                name: result,
            })
            setTriggerEffet(!triggerEffet)
            toast.success('Nom du questionnaire modifié')
        }
    }

    async function changeQuizDoc(quizId: string) {
        const quizLink = await axios.get(`/questionnaire/link/${quizId}`)
        console.log(quizLink.data.linkToDoc)
        const newLink = prompt(
            'Indiquez le nouveau lien de la documentation',
            quizLink.data.linkToDoc
        )
        if (newLink) {
            axios.patch(`/questionnaire/${quizId}`, {
                linkToDoc: newLink,
            })
            setTriggerEffet(!triggerEffet)
            toast.success('Lien de la documentation modifiée')
        }
    }

    async function changeQuizOrder(quizId: string, quizOrder: number = 0) {
        if (parseInt('' + quizOrder) >= 0) {
            axios.patch(`/questionnaire/${quizId}`, {
                order: parseInt('' + quizOrder) || 0,
            })
            setTriggerEffet(!triggerEffet)
            toast.success('Ordre du questionnaire modifié, rechargez la page.')
        }
    }
    useEffect(() => {
        if (
            (window.location.hostname == 'championnat.ffjm.org' &&
                passcode == 'CodeChamp2023') ||
            (window.location.hostname == 'ffjm.juniorisep.com' &&
                passcode == 'CodeDev') ||
            (window.location.hostname == 'localhost' && passcode == 'CodeDev')
        ) {
            console.log(open)
            setAdmin(true)
            localStorage.setItem('isAdmin' + window.location.hostname, 'true')
        }
    }, [passcode])
    const navigate = useNavigate()
    if (!admin)
        return (
            <>
                <div className="admin-container">
                    <div className="navbar">
                        <div className="nav-title">Accès Administrateur</div>
                        <NavbarAdmin active="questionnaires" />
                    </div>
                    <div className="page-body">
                        <Input
                            type="password"
                            title="Mot de passe"
                            onChange={(e) => setPasscode(e.target.value)}
                        />
                    </div>
                </div>
            </>
        )
    return (
        <>
            <div className="admin-container">
                <div className="navbar">
                    <div className="nav-title">Étapes</div>
                    <NavbarAdmin active="questionnaires" />
                </div>

                <div className="page-body">
                    <div
                        style={{
                            fontStyle: 'italic',
                            fontSize: 10,
                            textAlign: 'center',
                            marginBottom: 10,
                        }}
                    >
                        Attention a n'avoir qu'une seule étape en ligne active !
                        Les utilisateurs ne pourront pas voir le questionnaire
                        lié sinon.
                        <br />
                        <button
                            className={'buttons-blue blue-admin'}
                            onClick={() => createQuizz()}
                        >
                            Nouvelle étape
                        </button>
                    </div>
                    <div className="font-italic text-xs mb-10">
                        <p>
                            <span className="font-bold">Étape en ligne :</span>
                            <br />
                            Active/Inactive : Les utilisateurs peuvent voir ou
                            non le questionnaire lié à l'étape dans leur onglet
                            "Mes questionnaires"
                            <br />
                            Fermée/Ouverte : Les utilisateurs peuvent répondre
                            ou non au questionnaire lié dans l'onglet "Mes
                            questionnaires"
                            <br />
                            Visible/Invisible : Les utilisateurs peuvent voir
                            les messages lié à l'étape dans "Informations
                            championnat" et les établissements peuvent voir les
                            notes de leurs élèves.
                        </p>
                        <p>
                            <span className="font-bold">
                                {' '}
                                Étapes physiques :
                            </span>
                            <br />
                            Fermée/Ouverte : Les centres d'épreuves peuvent ou
                            non renseigner les notes des participants pour
                            l'étape
                            <br />
                            Visible/Invisible : Les utilisateurs peuvent voir
                            les messages lié à l'étape dans "Informations
                            championnat" et les établissements peuvent voir les
                            notes de leurs élèves.
                        </p>
                        <br />
                        Il n'est pas possible de supprimer une étape.
                        <br />
                        Vous pouvez modifier le nom d'une étape en cliquant
                        dessus.
                    </div>

                    {years.map((year) => {
                        return (
                            <button
                                key={year.year}
                                className={
                                    year.year == selectedYear
                                        ? 'buttons-blue blue-admin'
                                        : 'buttons-white white-admin'
                                }
                                onClick={() => setSelectedYear(year.year)}
                            >
                                {year.year}
                            </button>
                        )
                    })}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 20,
                        }}
                    >
                        {quizz.map((quiz, index) => {
                            if (quiz.year != parseInt(selectedYear))
                                return <></>
                            return (
                                <div
                                    key={quiz.id}
                                    style={{
                                        display: 'flex',
                                        width: '80%',
                                        alignSelf: 'center',
                                        justifyContent: 'space-between',
                                        padding: 10,
                                        borderRadius: 10,
                                        borderWidth: 1,
                                        borderColor: 'black',
                                        borderStyle: 'solid',
                                    }}
                                >
                                    <div>
                                        <div>
                                            <div
                                                onClick={() =>
                                                    changeQuizName(
                                                        quiz.id,
                                                        quiz.name
                                                    )
                                                }
                                            >
                                                {quiz.name} ({quiz.year}) -{' '}
                                                {quiz.physical
                                                    ? 'Physique'
                                                    : 'En ligne'}
                                            </div>
                                            <div
                                                style={{
                                                    fontStyle: 'initial',
                                                    fontSize: 8,
                                                }}
                                            >
                                                {quiz.id}
                                            </div>
                                        </div>
                                        {quiz.previousQuestionnaire && (
                                            <div>
                                                Étape précédente liée :{' '}
                                                {
                                                    quiz.previousQuestionnaire
                                                        .name
                                                }{' '}
                                                (
                                                {
                                                    quiz.previousQuestionnaire
                                                        .year
                                                }
                                                )
                                            </div>
                                        )}
                                        <div>
                                            <Input
                                                title="Ordre de priorité"
                                                type="number"
                                                defaultValue={quiz.order}
                                                value={quiz.order}
                                                onChange={(e) => {
                                                    const quizz2 = quizz
                                                    quizz2[index].order =
                                                        parseInt(e.target.value)
                                                    changeQuizOrder(
                                                        quiz.id,
                                                        parseInt(
                                                            '' + e.target.value
                                                        )
                                                    )
                                                    setQuizz([...quizz2])
                                                }}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            style={{
                                                display: 'flex',
                                            }}
                                        >
                                            {!quiz.physical && (
                                                <Switch
                                                    isChecked={quiz.active}
                                                    name={
                                                        quiz.active
                                                            ? 'Active'
                                                            : 'Inactive'
                                                    }
                                                    onChange={(isActive) => {
                                                        const quizz2 = quizz
                                                        quizz2[index].active =
                                                            isActive
                                                        axios.patch(
                                                            `/questionnaire/${quiz.id}`,
                                                            {
                                                                active: isActive,
                                                            }
                                                        )
                                                        setQuizz([...quizz2])
                                                    }}
                                                />
                                            )}
                                            <Switch
                                                isChecked={!quiz.isClosed}
                                                name={
                                                    quiz.isClosed
                                                        ? 'Fermée'
                                                        : 'Ouverte'
                                                }
                                                onChange={(isActive) => {
                                                    const quizz2 = quizz
                                                    quizz2[index].isClosed =
                                                        !isActive
                                                    axios.patch(
                                                        `/questionnaire/${quiz.id}`,
                                                        {
                                                            isClosed: !isActive,
                                                        }
                                                    )
                                                    setQuizz([...quizz2])
                                                }}
                                            />
                                            <Switch
                                                isChecked={
                                                    quiz.showOnUserDashboard
                                                }
                                                name={
                                                    !quiz.showOnUserDashboard
                                                        ? 'Invisible'
                                                        : 'Visible'
                                                }
                                                onChange={(isActive) => {
                                                    const quizz2 = quizz
                                                    quizz2[
                                                        index
                                                    ].showOnUserDashboard =
                                                        isActive
                                                    axios.patch(
                                                        `/questionnaire/${quiz.id}`,
                                                        {
                                                            showOnUserDashboard:
                                                                isActive,
                                                        }
                                                    )
                                                    setQuizz([...quizz2])
                                                }}
                                            />
                                        </div>
                                        <button
                                            onClick={() =>
                                                changeQuizDoc(quiz.id)
                                            }
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            Lien documentation
                                        </button>
                                        <button
                                            onClick={() => editQuizzTexts(quiz)}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            Messages
                                        </button>
                                        {!quiz.physical && (
                                            <button
                                                onClick={() =>
                                                    navigate(quiz.id)
                                                }
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                Modifier le questionnaire en
                                                ligne
                                            </button>
                                        )}
                                        <button
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                                navigate(
                                                    `/admin/reponses/questionnaire/${quiz.id}`
                                                )
                                            }
                                        >
                                            Résultats
                                        </button>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <Modal
                        isActive={modalActive}
                        setActive={setModalActive}
                        children={modal}
                    ></Modal>
                </div>
            </div>
        </>
    )
}
export default AdminQuizzList
