import { useRef, useState } from 'react'
import axios from '../../axios/axiosFile'
import { toast } from 'react-toastify'
export default function AssignUsersToExamCenters({
    close,
}: {
    close: () => void
    importSuccess: (msg: string) => void
}) {
    const [error, setError] = useState('')
    const inputFile = useRef<HTMLInputElement>(null)
    const loader = useRef<HTMLDivElement>(null)
    const onButtonClick = () => {
        if (inputFile?.current) inputFile.current.click()
    }
    const [linesInError, setLinesInError] = useState([])

    async function upload() {
        if (
            inputFile.current &&
            inputFile.current?.files &&
            inputFile.current?.files.length != 0
        ) {
            setLinesInError([])
            //console.log(inputFile.current?.files)
            const formData = new FormData()
            formData.append('excel', inputFile.current?.files[0])
            const response = await axios
                .post('/examcenter/register/excel', formData)
                .then((response) => {
                    // handle the response
                    console.log(response)

                    toast.success('Le fichier a bien été importé!')
                    if (response.data.invalidRows.length > 0) {
                        toast.info(
                            `${response.data.invalidRows.length} erreurs ont été rencontrées lors de l'import`
                        )
                        setLinesInError(response.data.invalidRows)
                    }
                    if (response.data.assignedUsers.length > 0)
                        toast.info(
                            `${response.data.assignedUsers.length} lignes ont été importées avec succès`
                        )
                    console.log(response)
                })
                .catch((e) => {
                    console.log(e)
                    setError("Le fichier n'a pas été importé :(")
                })
        } else {
            //console.log('fichier invalide')
            setError('Merci de séléctionner un fichier valide...')
        }
    }
    return (
        <>
            <h1>Convoquer des participants aux centres</h1>
            <span>
                Depuis cette page vous pouvez convoquer des utilisateurs à des
                centres d'examen.
                <br />
                Pour cela, vous devez importer un fichier Excel contenant les
                informations suivantes : <br />
                - Identifiant du centre d'examen (id dans l'excel d'export ou
                sur la fenetre de modification) <br />
                - Identifiant de l'utilisateur (identifiant de connexion)
                <br />
                - Identifiant de l'étape (présent dans la liste des étapes)
                <br />
                Attention ! Vous devez assignez à l'étape précédente, exemple :
                Les demi finale sont lié à l'étape de quart de finale (car ils
                découlent des résultats de cette étape)
                <br />
                Si la colonne "Identifiant du centre d'examen" est vide,
                l'utilisateur sera assigné à aucun centre (ou désassigné de son
                centre). Si la colonne "Identifiant de la étape" est vide la
                ligne sera ignorée
                <br />
            </span>
            <div className="succesful-upload d-none" id="successfulUpload">
                Le fichier a bien été choisi! Cliquez sur "confirmer" pour
                effectuer l'import!
            </div>
            {error != '' ? <div className="error-upload">{error}</div> : <></>}
            <button
                className="buttons-blue school-button"
                onClick={onButtonClick}
            >
                Selectionner le fichier
            </button>

            <input
                type="file"
                id="file"
                name="file"
                onChange={() => {
                    if (
                        inputFile?.current != null &&
                        document.getElementById('successfulUpload') != undefined
                    ) {
                        console.log(inputFile.current.value)
                        document
                            .getElementById('successfulUpload')
                            ?.classList.remove('d-none')
                        document
                            .getElementById('successfulUpload')
                            ?.classList.add('d-block')
                    }
                }}
                ref={inputFile}
                style={{ display: 'none' }}
            />

            <a
                href={require('../../assets/Assignations.xlsx')}
                target={'_blank'}
            >
                <button
                    className="buttons-blue school-button"
                    style={{
                        marginTop: 20,
                        marginBottom: 20,
                    }}
                >
                    Télécharger le modèle
                </button>
            </a>
            <div className="buttons-modal-multiple">
                <button
                    className="buttons-border-off school-button-border-off"
                    onClick={close}
                >
                    Annuler
                </button>
                <button
                    className="buttons-blue school-button"
                    onClick={async () => {
                        console.log('Sending the request...')
                        loader.current?.classList.remove('d-none')
                        loader.current?.classList.add('d-inline-block')
                        const response = await upload()
                        loader.current?.classList.add('d-none')
                        loader.current?.classList.remove('d-inline-block')
                    }}
                >
                    <span className="d-inline-block">Confirmer</span>{' '}
                    <div ref={loader} className="loader d-none"></div>
                </button>
            </div>
            {linesInError.length > 0 ? (
                <div
                    style={{
                        height: 200,
                        border: 1,
                        borderWidth: 1,
                        borderColor: 'black',
                        borderBlock: 'solid',
                        overflowY: 'scroll',
                    }}
                >
                    <div>Erreurs : </div>
                    <div>
                        {linesInError.map((error, index) => (
                            <div key={index}>{error}</div>
                        ))}
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    )
}
