import { TableStyle } from './typeTables'

export enum typeStatus {
    A_PAYE = 'À payé',
    NON = 'Non',
}

const tilesAdminClubTable: TableStyle[] = [
    { name: 'Nom', isSortable: true, filters: null, index: 'name' },
    {
        name: 'Ville',
        isSortable: false,
        filters: null,
        index: 'city',
    },
    {
        name: 'Prénom correspondant',
        isSortable: false,
        filters: null,
        index: 'user.contact.firstname',
    },
    {
        name: 'Nom correspondant',
        isSortable: false,
        filters: null,
        index: 'user.contact.lastname',
    },
    {
        name: 'Email correspondant',
        isSortable: false,
        filters: null,
        index: 'user.contact.email',
    },
    {
        name: 'Téléphone correspondant',
        isSortable: false,
        filters: null,
        index: 'user.contact.phone',
    },
    {
        name: 'Adhérent ? ',
        isSortable: true,
        filters: [typeStatus.NON, typeStatus.A_PAYE],
        index: 'accountStatus',
    },
]

export default tilesAdminClubTable
