import { iEstablishementUser } from '../../../types/user'
import EtablishementInfosForm from './EtablishementInfosForm'
import EtablishementContactForm from './EtablishementContactForm'
import EtablishementRecapForm from './EtablishementRecapForm'

function EtablishementForm({
    active,
    user,
    setUser,
}: {
    active: string
    user: iEstablishementUser
    setUser: (args: iEstablishementUser) => void
}) {
    return (
        <form className="forms-insc" id="formulaires-inscription">
            <h2 className="forms-title-insc">{active}</h2>
            <div className="forms-inputs">
                {active == 'Établissement' && (
                    <EtablishementInfosForm setUser={setUser} user={user} />
                )}

                {active == 'Correspondant' && (
                    <EtablishementContactForm setUser={setUser} user={user} />
                )}

                {active == 'Confirmation' && (
                    <EtablishementRecapForm user={user} />
                )}
            </div>
            <button
                id="Inscription-sub-button"
                type="submit"
                onClick={(e) => e.preventDefault()}
                hidden
            ></button>
        </form>
    )
}
export default EtablishementForm
