import { useEffect, useState, useRef } from 'react'
import './inscription.css'
import { useNavigate } from 'react-router-dom'
import Timeline from '../../components/Timeline'

import ScolaireForm from '../../components/forms/students/ScolaireForm'
import EtablishementForm from '../../components/forms/etablishement/EtablishementForm'
import axios from '../../axios/axios'
import AuthService from '../../services/AuthService'
import { eroles, eestablishements, elangs } from '../../types/enums'
import {
    iIndividualUser,
    iEstablishementUser,
    iSchoolUser,
    iClubUser,
} from '../../types/user'
import { useSearchParams } from 'react-router-dom'
import ChoiceAccountForm from '../../components/forms/ChoiceAccountForm'
import ClubForm from '../../components/forms/club/ClubForm'
import IndividualForm from '../../components/forms/IndividualForm/IndividualForm'
import { getCategories } from '../../helpers/getCategories'
import ParticipantLayout from '../layouts/ParticipantLayout'
import { toast } from 'react-toastify'

export const TypeEstablishementOptions = [
    { value: eestablishements.COLLEGE, label: 'Collège' },
    { value: eestablishements.ECOLE, label: 'École' },
    { value: eestablishements.LYCEE, label: 'Lycée' },
    { value: eestablishements.SUP, label: 'Supérieur' },
]

function Inscription() {
    const [step, setStep] = useState<Array<string>>([])
    const [active, setActive] = useState<string>('')
    const [form, setForm] = useState<string>('Compte')

    const loader = useRef<HTMLDivElement>(null)

    const [individual, setIndividual] = useState<iIndividualUser>({
        id: '',
        role: eroles.USER,
        birthday: undefined,
        club: {
            id: undefined,
        },
        contact: {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            zip_code: '',
            country: '',
        },
        categoryName: undefined,
        languageName: undefined,
        imageCapture: false,
        communication: false,
        numFFJM: '',
        genre: '',
    })
    const [school, setSchool] = useState<iSchoolUser>({
        id: '',
        zip_code: '',
        role: eroles.USER,
        birthday: undefined,
        establishmentId: '',
        contact: {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            zip_code: '',
            country: '',
        },
        categoryName: undefined,
        languageName: undefined,
        imageCapture: false,
        communication: false,
    })
    const [establishement, setEstablishement] = useState<iEstablishementUser>({
        id: '',
        role: eroles.ESTABLISHEMENT,
        contact: {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
        },
        establishment: {
            rneCode: '',
            name: '',
            type: undefined,
            street: '',
            city: '',
            zipCode: '',
            country: '',
        },
    })
    const [clubdata, setClubData] = useState<iClubUser>({
        id: '',
        role: eroles.CLUB,
        contact: {
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
        },
        club: {
            id: '',
            name: '',
            street: '',
            city: '',
            zipCode: '',
            country: '',
        },
    })

    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate()

    function individuel() {
        setStep([
            'Informations',
            'Coordonnées',
            'Autorisations',
            'Confirmation',
        ])
        setActive('Informations')
        setForm('Individual')
    }

    function scolaire() {
        setStep([
            'Informations',
            'Coordonnées',
            'Autorisations',
            'Confirmation',
        ])
        setActive('Informations')
        setForm('School')
    }

    function etablissment() {
        setStep(['Établissement', 'Correspondant', 'Confirmation'])
        setActive('Établissement')
        setForm('Establishement')
    }
    function club() {
        setStep(['Club', 'Correspondant', 'Confirmation'])
        setActive('Club')
        setForm('Club')
    }

    const [error, setError] = useState<string>('')

    const Next = () => {
        const formElement = document.getElementById(
            'formulaires-inscription'
        ) as HTMLFormElement
        if (formElement.checkValidity()) {
            const newStepIndex = step.indexOf(active) + 1
            if (step.length >= newStepIndex) {
                setActive(step[newStepIndex])
            }
        } else {
            formElement.requestSubmit()
            // document.getElementById('Inscription-sub-button')?.click()
        }
    }

    const Prev = () => {
        const newStep = step[step.indexOf(active) - 1]
        setActive(newStep)
        setError('')
        //console.log('STEP', newStep)
        if (!newStep) {
            setActive('')
            setForm('Compte')
            setStep([])
        }
    }

    let firstTry = true
    const finish = async () => {
        loader.current?.classList.remove('d-none')
        loader.current?.classList.add('d-inline-block')

        console.log('firstTry', firstTry)
        // check email
        if (firstTry) {
            const checkMail = (
                await axios.post('/authentification/check-mail', {
                    mail:
                        form === 'Individual'
                            ? individual.contact.email
                            : school.contact.email,
                })
            ).data

            if (checkMail.response > 0) {
                toast.warning(
                    `${
                        checkMail.response > 1
                            ? `${checkMail.response} comptes existent`
                            : 'Un compte existe'
                    } déjà avec la même adresse électronique. Etes-vous bien un nouveau participant? Si oui, re-cliquez sur le bouton "terminer"`,
                    {
                        autoClose: false,
                    }
                )
                firstTry = false
                setTimeout(() => {
                    loader.current?.classList.add('d-none')
                    loader.current?.classList.remove('d-inline-block')
                }, 2000)
                return
            }
        }
        if (establishement.establishment.rneCode == '')
            setEstablishement({
                ...establishement,
                establishment: {
                    ...establishement.establishment,
                    rneCode: undefined,
                },
            })

        if (form === 'Individual') {
            if (!individual.club?.id) {
                delete individual.club
            }
            // if (individual.communicationFFJM)
            //     delete individual.communicationFFJM
        }
        await axios
            .post(
                '/authentification/register',
                form === 'Individual'
                    ? individual
                    : form === 'School'
                    ? school
                    : form === 'Club'
                    ? clubdata
                    : establishement
            )
            .then((value) => {
                AuthService.setAccessTokens(value.data.accessToken)
                AuthService.setRefreshTokens(value.data.refreshToken)
                const role = AuthService.decodedToken()[1]
                if (role === eroles.USER) {
                    navigate('/participant')
                } else if (role === eroles.ADMIN) {
                    navigate('/admin')
                } else if (role === eroles.ESTABLISHEMENT) {
                    navigate('/etablissement')
                } else if (role === eroles.CLUB) {
                    navigate('/club')
                }
            })
            .catch((err) => {
                console.log(err)
                setError("Impossible de finaliser l'inscription")
            })
        loader.current?.classList.add('d-none')
        loader.current?.classList.remove('d-inline-block')
    }

    useEffect(() => {
        getCategories()
        document.title = 'Inscription'
        if (searchParams.get('etablissement')) {
            //console.log('HEY')
            setSchool({
                ...school,
                establishmentId: searchParams.get('etablissement') || '',
            })
            scolaire()
            searchParams.delete('etablissement')
        }
    }, [])

    return (
        <ParticipantLayout
            noDisconnectButton
            headerButtons={[
                {
                    title: 'Se connecter',
                    link: '/Connexion',
                    active: true,
                },
            ]}
            color="red"
        >
            {/* <div className="container"> */}
            {/* <div className="header">
                <img className="logo" />
                <button
                    className="buttons-blue school-button"
                    onClick={() => navigate('/Connexion')}
                >
                    Se connecter
                </button>
            </div> */}

            <div
                id="Page-inscription"
                style={{
                    marginTop: '10%',
                    display: 'flex',
                    height: '80%',
                }}
            >
                {/* <div
                    style={{
                        backgroundColor: 'rgba(280, 100, 5, 0.5)',
                        padding: 15,
                        borderRadius: 10,
                    }}
                >
                    Participant scolaire ou individuel : Pour renouveler votre
                    inscription aux championnats successifs , connectez-vous à
                    l'aide de vos identifiants existants et cliquez ensuite sur
                    le bouton "Renouveler inscription"
                </div> */}
                {form && form !== 'Compte' && (
                    <>
                        <h1
                            className="insc-trans"
                            id={
                                step.length == 1 ? 'insc-title-1' : 'insc-title'
                            }
                        >
                            Inscription
                        </h1>

                        <Timeline steps={step} active={active} />
                    </>
                )}
                <div id="forms">
                    {form == 'Compte' && (
                        <ChoiceAccountForm
                            individuel={() => individuel()}
                            scolaire={() => scolaire()}
                            etablissment={() => etablissment()}
                            club={() => club()}
                        />
                    )}
                    {form == 'Individual' && (
                        <IndividualForm
                            active={active}
                            user={individual}
                            setUser={setIndividual}
                        />
                    )}
                    {form == 'School' && (
                        <ScolaireForm
                            active={active}
                            user={school}
                            setUser={setSchool}
                        />
                    )}
                    {form == 'Establishement' && (
                        <EtablishementForm
                            active={active}
                            user={establishement}
                            setUser={setEstablishement}
                        />
                    )}
                    {form == 'Club' && (
                        <ClubForm
                            active={active}
                            user={clubdata}
                            setUser={setClubData}
                        />
                    )}
                </div>
                {error && <p id="error">{error}</p>}
                {active != '' && (
                    <div id="insc-step-button">
                        <button
                            className="buttons-border-off"
                            id="buttons-border-off-insc"
                            onClick={Prev}
                        >
                            Précédent
                        </button>
                        {active == 'Confirmation' ? (
                            <>
                                <button
                                    className="buttons-blue school-button"
                                    onClick={() => finish()}
                                    type="button"
                                    disabled={loader.current?.classList.contains(
                                        'd-inline-block'
                                    )}
                                >
                                    Terminer
                                    <span className="d-inline-block">
                                        {' '}
                                    </span>{' '}
                                    <div
                                        ref={loader}
                                        className="loader d-none"
                                    ></div>
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    className="buttons-blue school-button"
                                    onClick={Next}
                                    type="button"
                                >
                                    Suivant
                                </button>
                            </>
                        )}
                    </div>
                )}
            </div>
        </ParticipantLayout>
    )
}
export default Inscription
