import { useState } from 'react'
import './QualifyingThresholds.css'

import { useEffect } from 'react'

import IQuestionnaire from '../types/IQuestionnaire'
import IGradesToQualify from '../types/IGradesToQualify'

import QuestionnaireThresholds from '../components/forms/questionnaire/QuestionnaireThresholds'
import axios from '../axios/axios'
import { getCategories } from '../helpers/getCategories'

export default function QualifyingThresholds({
    questionnaireId,
}: {
    questionnaireId: string
}) {
    const [thresholds, setThresholds] = useState<IGradesToQualify[]>()
    const [loaded, setLoaded] = useState(false)
    const [catsOptions, setCatsOptions] = useState<
        { value: string; label: string }[]
    >([])
    useEffect(() => {
        getCategories().then((cats) => setCatsOptions(cats))
    }, [])
    useEffect(() => {
        setThresholds(thresholds)
    }, [thresholds])

    useEffect(() => {
        if (!loaded && catsOptions.length > 0) {
            setLoaded(true)
            axios.get(`/questionnaire/${questionnaireId}`).then((data) => {
                const questionnaire = data.data as IQuestionnaire
                for (let index = 0; index < catsOptions.length; index++) {
                    const option = catsOptions[index]
                    const currentThreshold = questionnaire.gradesToQualify.find(
                        (threshold) => threshold.category.name == option.label
                    )
                    console.warn('NO THRESHOLD FOR ' + option.value)

                    if (currentThreshold == undefined) {
                        questionnaire.gradesToQualify.push({
                            category: { name: option.value },
                            grade: 0,
                            weightedGrade: 0,
                            thresholds: 0,
                        })
                    }
                }

                setThresholds(questionnaire.gradesToQualify)
            })
        }
    }, [thresholds, setThresholds, catsOptions])
    if (thresholds === undefined || thresholds.length < 1) {
        return <div className="py-1">Chargement...</div>
    }

    return (
        <div style={{ height: '90vh' }}>
            <div className="py-1">
                Seuil de qualification pour ce questionnaire, les seuils sont
                supèrieur ou égal.
                <br />
                Modifiez la valeur des seuils pour visualiser le nombre de
                participants séléctionnés puis cliquez sur "Enregistrer les
                seuils" pour enregistrer la valeur. Seulement ensuite cliquez
                sur "Enregistrer les qualifiés" pour qualifier les participants
                au dessus des seuils.
                <br /> Le bouton "Réunitialiser les qualifiés" permet de
                déqualifier tous les participants d'une catégorie.
                <br />
                <br />
                <p style={{ fontWeight: 'bold' }}>
                    Pour les questionnaires en ligne : Calculez les résultats
                    avant d'enregistrer des qualifiés !
                </p>
            </div>
            <div className="thresholds-container text-center mb-5">
                <div className="row thresholds-headers">
                    <div style={{ maxWidth: '90px' }} className="col"></div>
                    <div className="col threshold-title">Nombre de points</div>
                    <div className="col threshold-title">Coefficient</div>
                    <div className="col threshold-title">
                        Nombre de qualifiés
                    </div>
                    <div className="col threshold-title">Actions</div>
                </div>
                {thresholds &&
                    thresholds.map((threshold, index) => (
                        <QuestionnaireThresholds
                            key={index}
                            category={threshold.category.name}
                            defaultNumberPoint={threshold.grade}
                            defaultCoef={threshold.weightedGrade}
                            questionnaireId={questionnaireId}
                        />
                    ))}
            </div>
        </div>
    )
}
