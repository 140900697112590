import { useEffect, useState } from 'react'
import axios from '../../axios/axios'
import Table, { PageObject } from '../../components/Table'
import * as XLSX from 'xlsx'
import NavbarAdmin from '../../components/NavbarAdmin'
import { useParams } from 'react-router-dom'
import ConfirmAction from '../../components/modals/ConfirmAction'
import { toast } from 'react-toastify'
import constAdminParticipantResults from '../../types/tables/constAdminParticipantResults'
import Modal from '../../components/Modal'
import QualifyingThresholds from './../QualifyingThresholds'
import IQuestionnaireWithQuestion from '../../types/IQuestionnaire'
import IQuestionnaire from '../../types/IQuestionnaire'
import SeeResultDetail from '../../components/modals/SeeResultDetail'
import IUserWithQuestionnaireAnwsers from '../../types/IParticipant'
import { iResultWithUser } from '../../types/user'

export default function AdminParticipantResults() {
    const [isLoading, setIsloading] = useState(false)

    const [pages, setPages] = useState<PageObject>({
        pages: 1,
        actual: 1,
    })
    const [search, setSearch] = useState<string>('')
    const [data, setData] = useState<IUserWithQuestionnaireAnwsers[] | null>(
        null
    )

    const [questionnaire, setQuestionnaire] =
        useState<IQuestionnaireWithQuestion | null>()
    const [triggerEffect, setTriggerEffect] = useState(false)
    const [modalActive, setModalActive] = useState(false)
    const [modalType, setModalType] = useState<JSX.Element | null>(null)

    const { id } = useParams()

    async function getAllQuestionnaires() {
        const response: IUserWithQuestionnaireAnwsers[] = (
            await axios.get(`/users/answers/${id}?limit=0`)
        ).data.data

        return response
    }

    async function getQuestions() {
        return await axios.get('/questionnaire/' + id)
    }

    async function retrieveExamCenters(questionnaireId: string) {
        return await axios.get(`/users/grades/short/${questionnaireId}?limit=0`)
    }
    async function calculateResults() {
        const result: string = await axios
            .get(`/users/results/generate/${id}`)
            .then((res) => {
                toast.success('Résultats générés')
                setModalActive(false)
                return res.data
            })
        setTimeout(() => setTriggerEffect(!triggerEffect), 500)
        return result
    }

    const Pagination = (page: number) => {
        setPages({
            pages: pages.pages,
            actual: page,
        })
    }

    const Export = () => {
        setModalType(
            <ConfirmAction
                text="Voulez-vous exporter les réponses de questionnaire des participants ?"
                onClose={() => setModalActive(false)}
                onValidate={async () => await exportExcelFile()}
                active={modalActive}
            />
        )
        setModalActive(true)
    }

    const exportExcelFile = async () => {
        console.log('EXPORT')
        setModalActive(false)

        const modal = toast.loading(
            'Export en cours... Ne pas recliquer. Cette opération peut prendre plusieurs minutes'
        )
        setIsloading(true)
        const questionnaire = (await getQuestions()).data

        const allQuestions: {
            id: string
            text: string
            title: string
            questionnaire?: IQuestionnaire
        }[] = []

        await questionnaire.questions.forEach(
            (question: { id: string; text: string; title: string }) => {
                allQuestions.push(question)
            }
        )

        const response = await getAllQuestionnaires()

        console.log('questionnaire')
        const assignations = questionnaire.previousQuestionnaireId
            ? (await retrieveExamCenters(questionnaire.previousQuestionnaireId))
                  .data.data
            : []

        console.log('ass', assignations)
        const assignationsParsed: {
            [key: string]: iResultWithUser
        } = {}
        for (let index = 0; index < assignations.length; index++) {
            const element = assignations[index]
            assignationsParsed[assignations[index].userId] = assignations[index]
        }

        console.log('assignationsParsed', assignationsParsed)
        const result: {
            [key: string]: string | number | boolean | undefined
        }[] = []

        const users = []
        response.forEach((res: IUserWithQuestionnaireAnwsers) => {
            const row: {
                [key: string]: string | number | boolean | undefined
            } = {
                Utilisateur: res.username ?? '',
                Statut: res.registrations[0]?.status ?? '',
                genre: res.genre ?? '',
                Prénom: res.contact.firstname ?? '',
                'Date de naissance': res.birthday,
                'Nom de famille': res.contact.lastname ?? '',
                'E-mail': res.contact.email ?? '',
                'Numéro FFJM': res.numFFJM ?? '',
                'RNE établissement scolaire':
                    res.registrations[0]?.establishment?.rneCode ?? '',
                'Nom établissement scolaire':
                    res.registrations[0]?.establishment?.name ?? '',
                Langue: res.language?.language,
                ImageCapture: res.imageCapture ?? '',
                Communication: res.communication ?? '',
                Categorie: res.registrations[0]?.category?.name ?? '',
                'RNE Centre epreuve':
                    '' +
                    (assignationsParsed[res.id]?.examCenter?.rneCode || ''),
                'Nom Centre epreuve':
                    '' + (assignationsParsed[res.id]?.examCenter?.name || ''),
                'Note Totale': res.results[0]?.grade ?? '',
                'Coefficient Total': res.results[0]?.weightedGrade ?? '',
                'Temps Total': res.results[0]?.time ?? '',
                'Qualifié pour la phase suivante':
                    res.results[0]?.qualified ?? '',
            }
            delete assignationsParsed[res.id]

            for (let i = 0; i < allQuestions.length; i++) {
                const question = allQuestions[i]
                // if (
                //     !question?.questionnaire ||
                //     question?.questionnaire.id !== id
                // )
                //     continue
                const answer = res.answers.find(
                    (answer) => answer.questionId === question.id
                )
                row[question.title + ' : ' + question.text] = answer?.answers
                    ? answer.answers.join(', ') +
                      (answer.totalAnswer ? ` (${answer.totalAnswer})` : '')
                    : '- '
            }

            result.push(row)
        })

        console.log(assignationsParsed)
        // For each assignationsParsed, add to the results if undefined

        Object.keys(assignationsParsed).forEach((key) => {
            if (assignationsParsed[key]) {
                const obj = assignationsParsed[key]
                const row: {
                    [key: string]: string | number | boolean | undefined
                } = {
                    Utilisateur: obj.user.username ?? '',
                    Statut: obj.user.status ?? '',
                    genre: obj.user.genre ?? '',
                    Prénom: obj.user.contact.firstname ?? '',
                    'Date de naissance': obj.user.birthday as string,
                    'Nom de famille': obj.user.contact.lastname ?? '',
                    'E-mail': obj.user.contact.email ?? '',
                    'Numéro FFJM': obj.user.numFFJM ?? '',
                    'RNE établissement scolaire':
                        obj.user.establishment?.rneCode ?? '',
                    'Nom établissement scolaire':
                        obj.user.establishment?.name ?? '',
                    Langue: obj.user.language?.language ?? '',
                    ImageCapture: obj.user.imageCapture ?? '',
                    Communication: obj.user.communication ?? '',
                    Categorie: obj.user.categoryName ?? '',
                    'RNE Centre epreuve': '' + obj?.examCenter?.rneCode,
                    'Nom Centre epreuve': '' + obj?.examCenter?.name,
                    'Note Totale': '',
                    'Coefficient Total': '',
                    'Temps Total': '',
                    'Qualifié pour la phase suivante': false,
                }
                console.log('questionnaire', questionnaire)
                if (
                    !(
                        obj?.examCenter?.rneCode === undefined &&
                        questionnaire.physical
                    )
                )
                    result.push(row)
            }
        })
        const worksheet = XLSX.utils.json_to_sheet(result)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(
            workbook,
            worksheet,
            'Réponses Questionnaire'
        )
        XLSX.writeFile(workbook, 'Réponses Questionnaire.xlsx')
        setIsloading(false)
        toast.dismiss(modal)
    }

    const details = async (participant: IUserWithQuestionnaireAnwsers) => {
        if (!questionnaire?.physical) {
            const detailsParticipantResult = (
                await axios.get(`/users/results/detail/${id}/${participant.id}`)
            ).data

            setModalType(
                <SeeResultDetail
                    participant={participant}
                    detailsParticipantResult={detailsParticipantResult}
                    setModalActive={() => setModalActive(false)}
                    modalActive={modalActive}
                />
            )
            setModalActive(true)
        } else {
            // setModalType(
            //     <>
            //         <AddGradeToUser
            //             close={() => setModalActive(false)}
            //             trigger={() => {
            //                 setTimeout(
            //                     () => setTriggerEffect(!triggerEffect),
            //                     1000
            //                 )
            //             }}
            //             participant={participant}
            //             questionnaireId={id}
            //             nextQuestionnaire={questionnaire}
            //         />
            //     </>
            // )
            // setModalActive(true)
            // console.log(participant)
            toast.error(
                "Cette fonction n'est pas encore disponible pour les questionnaires physiques."
            )
        }
    }

    const calculate = () => {
        setModalType(
            <ConfirmAction
                text="Êtes vous sûr de vouloir (re)calculer l'ensemble des résultats des personnes ayant répondus ?"
                onClose={() => setModalActive(false)}
                onValidate={async () => await calculateResults()}
                active={modalActive}
            />
        )
        setModalActive(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        async function fetchData() {
            const questionnaire = (await getQuestions()).data
            setQuestionnaire(questionnaire)
            console.log('questionnaire', questionnaire)
        }
        fetchData()
    }, [(document.title = 'Réponses Questionnaire')])

    async function setDataTable() {
        const fetch = await axios.get(
            `/users/answers/${id}?limit=10&page=${pages.actual ?? '1'}` +
                (search !== '' ? '&search=' + search : '')
        )

        const users = fetch.data.data

        const newusers = users.map((user: IUserWithQuestionnaireAnwsers) => {
            return {
                ...user,
                status: user.registrations[0]?.status,
                category: user.registrations[0]?.category?.name,
                qualified:
                    user.results[0]?.qualified === true
                        ? 'Oui'
                        : user.results[0]?.qualified === false
                        ? 'Non'
                        : '-',
                grade:
                    user.results[0]?.grade !== undefined
                        ? user.results[0]?.grade
                        : '-',
                coefficient:
                    user.results[0]?.weightedGrade !== undefined
                        ? user.results[0]?.weightedGrade
                        : '-',
            }
        })

        setPages({
            pages: fetch.data.meta.totalPages,
            actual:
                fetch.data.meta.currentPage > fetch.data.meta.totalPages
                    ? fetch.data.meta.totalPages
                    : fetch.data.meta.currentPage,
        })
        setData(newusers)

        console.log(newusers)
    }

    useEffect(() => {
        setDataTable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pages.actual, triggerEffect, search])

    const [complexModalActive, setComplexModalActive] = useState(false)
    const [complexModal, setComplexModal] = useState<JSX.Element>(<></>)

    function showQualifyingThresholds() {
        setComplexModal(
            <>
                <div
                    style={{
                        height: '90vh',
                        width: '80vw',
                        overflowY: 'scroll',
                        marginTop: '25px',
                    }}
                >
                    {id && <QualifyingThresholds questionnaireId={id ?? ''} />}
                </div>
            </>
        )
        setComplexModalActive(true)
    }
    return (
        <>
            <div className="admin-container">
                <div className="navbar">
                    <div className="nav-title">Résultats {isLoading}</div>
                    <NavbarAdmin active="reponses" />
                </div>
                <div className="page-body">
                    <div className="w-100 flex">
                        <div>
                            <div className="insc-inputs" id="admin-search">
                                <input
                                    className="insc-input"
                                    type="text"
                                    placeholder="Rechercher un participant..."
                                    onChange={(e) =>
                                        setSearch(() => e.target.value)
                                    }
                                />
                            </div>
                            <div></div>
                        </div>
                        <div className="insc-inputs" id="admin-search"></div>
                        <button
                            className={'buttons-blue blue-admin'}
                            onClick={() => showQualifyingThresholds()}
                        >
                            Modifier les seuils
                        </button>
                        {!questionnaire?.physical && (
                            <div className="export-admin">
                                <button
                                    className="buttons-blue blue-admin"
                                    onClick={calculate}
                                >
                                    Calculer les résultats
                                </button>
                            </div>
                        )}
                        <div className="export-admin">
                            <button
                                className="buttons-blue blue-admin"
                                onClick={Export}
                            >
                                Exporter les résultats
                            </button>
                        </div>
                    </div>
                    <br />
                    <div className="font-italic text-xs mb-10">
                        Le tableau contient uniquement les participants ayant un
                        résultat (réponse à une question, assignation à un
                        centre pour l'étape suivante ou score), le fichier
                        d'export Excel contient en plus les personnes qui ont
                        été convoquées à un centre mais qui n'ont pas de
                        résultats.
                    </div>
                    <Table
                        titles={constAdminParticipantResults}
                        data={data}
                        color="--orange100"
                        pagination={Pagination}
                        filter={() => {
                            return null
                        }}
                        page={pages}
                        actions={{
                            modify: {
                                action: details,
                            },
                        }}
                        // showActions={!questionnaire?.physical}
                        margin="30"
                    />
                </div>
                {modalActive && modalType ? modalType : ''}
                <Modal
                    isActive={complexModalActive}
                    setActive={setComplexModalActive}
                    children={complexModal}
                />
            </div>
        </>
    )
}
