import logo from '../assets/logoJisep.svg'
function MadeByJisep() {
    return (
        <div
            style={{
                display: 'flex',
                gap: '5px',
                cursor: 'pointer',
            }}
            onClick={() => {
                window.open('https://www.juniorisep.com/', '_blank')
            }}
        >
            Réalisé par Junior ISEP
            <img
                src={logo}
                alt={'Logo Junior ISEP'}
                style={{
                    width: '30px',
                }}
            />
        </div>
    )
}

export default MadeByJisep
