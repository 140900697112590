import { useEffect, useState } from 'react'
import './input.css'

function ControlledInput({
    onChange,
    defaultValue,
    title,
    required = false,
    disabled = false,
    type = 'text',
    multiple = false,
    value,
    name,
    isChecked,
    authorizeCharacters,
    regex,
}: {
    onChange?: (newValue: string) => void
    defaultValue?: string | number
    disabled?: boolean
    required?: boolean
    title?: string
    value?: string | number
    multiple?: boolean
    name?: string
    isChecked?: boolean
    type?: 'text' | 'number'
    authorizeCharacters?: string
    regex?: string
}) {
    useEffect(() => setLocalValue(value), [value])
    const extract = (str: string, pattern: string) =>
        (str.match(pattern) || []).pop() || ''
    const extractHexadecimalWithColon = (str: string) =>
        extract(str, '[0-9:;./]+')

    const [error, setError] = useState('')
    const [localValue, setLocalValue] = useState(defaultValue)
    function onLocalChange(
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) {
        const value = extractHexadecimalWithColon(e.target.value)
        if (regex) {
            if (value !== '' && !new RegExp(regex).test(value)) {
                setError("Le format de l'entrée est détécté comme incorrect")
            } else {
                setError('')
            }
        }
        if (onChange) onChange(value)
        setLocalValue(value)
    }
    return (
        <div className="inputs">
            {title && (
                <span
                    className={`inputs-title-insc${
                        required ? ' required' : ''
                    }`}
                >
                    {title}
                </span>
            )}
            {multiple ? (
                <textarea
                    onChange={(e) => onLocalChange(e)}
                    className="insc-input"
                    defaultValue={defaultValue}
                    required={required}
                    disabled={disabled}
                    value={localValue}
                ></textarea>
            ) : (
                <>
                    <input
                        onChange={(e) => onLocalChange(e)}
                        className="insc-input"
                        defaultValue={defaultValue}
                        required={required}
                        type={type}
                        disabled={disabled}
                        value={localValue}
                    />
                </>
            )}
            {error && (
                <span
                    style={{ fontStyle: 'italic', fontSize: 10, color: 'red' }}
                >
                    {error}
                </span>
            )}
        </div>
    )
}

export default ControlledInput
