export default function EditIcon(props: { action: () => void; size?: string }) {
    return (
        <svg
            width={props.size || '30'}
            height={props.size || '30'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => props.action()}
        >
            <g id="&#240;&#159;&#166;&#134; icon &#34;edit&#34;">
                <g id="Group">
                    <path
                        id="Vector"
                        d="M14.3325 1.37213H11.3697C3.96278 1.37213 1 4.33491 1 11.7419V20.6302C1 28.0372 3.96278 31 11.3697 31H20.2581C27.665 31 30.6278 28.0372 30.6278 20.6302V17.6674"
                        stroke="#292D32"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <g id="Group_2">
                        <path
                            id="Vector_2"
                            d="M21.799 2.88319L10.1256 14.5565C9.68122 15.001 9.2368 15.875 9.14792 16.512L8.51092 20.971C8.2739 22.5857 9.41457 23.7115 11.0293 23.4893L15.4883 22.8523C16.1105 22.7634 16.9845 22.319 17.4437 21.8746L29.1171 10.2013C31.1318 8.18657 32.0798 5.84597 29.1171 2.88319C26.1543 -0.0795946 23.8137 0.868496 21.799 2.88319Z"
                            stroke="#292D32"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            id="Vector_3"
                            d="M20.125 4.55652C21.1175 8.09704 23.8877 10.8672 27.4431 11.8746"
                            stroke="#292D32"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
