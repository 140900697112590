import { useEffect, useState } from 'react'
import './switch.css'
function Switch({
    onChange,
    defaultValue,
    isChecked,
    title,
    disabled = false,
    name,
    width,
    styles,
}: {
    onChange?: (newValue: boolean) => void
    defaultValue?: boolean
    disabled?: boolean
    required?: boolean
    title?: string
    value?: boolean
    multiple?: boolean
    name?: string
    isChecked?: boolean
    type?: 'text' | 'number'
    authorizeCharacters?: string
    width?: number
    styles?: React.CSSProperties
}) {
    return (
        <div
            className="inputs inputs-title-insc-notBold"
            style={{ width: width || 75, ...styles }}
        >
            {name}
            <label className="switch">
                <input
                    defaultChecked={defaultValue}
                    checked={isChecked}
                    type="checkbox"
                    onChange={(e) =>
                        onChange
                            ? onChange(e.target.checked)
                            : console.log(e.target.checked)
                    }
                />
                <span className="slider round"></span>
            </label>
        </div>
    )
}

export default Switch
