import Select from 'react-select'
import { iResult } from '../../types/user'
import { useState } from 'react'
import axios from '../../axios/axios'
import IQuestionnaire from '../../types/IQuestionnaire'

export default function UserModificationConvocation(props: {
    result?: iResult
    questionnaire?: IQuestionnaire
    examCentersOptions: { value: string; label: string }[] | null
    userId: string
}) {
    const [result, setResult] = useState<iResult>(
        props?.result || {
            questionnaire: props.questionnaire,
            qualified: false,
            questionnaireId: props.questionnaire?.id || '',
        }
    )

    async function updateQualification(newStatus: boolean | null) {
        axios
            .patch('/users/' + props.userId, {
                results: [
                    newStatus
                        ? {
                              questionnaireId: result.questionnaireId,
                              qualified: newStatus,
                          }
                        : {
                              questionnaireId: result.questionnaireId,
                              qualified: newStatus,
                              examCenterId: null,
                          },
                ],
            })
            .then((res) => {
                console.log(res.data)
            }).catch
        setResult({
            ...result,
            qualified: newStatus,
            examCenterId: newStatus === false ? undefined : result.examCenterId,
        })
    }

    async function updateCenter(examCenterId?: string) {
        axios
            .patch('/users/' + props.userId, {
                results: [
                    {
                        questionnaireId: result.questionnaireId,
                        examCenterId: examCenterId || null,
                        qualified:
                            result.qualified === false && examCenterId
                                ? true
                                : result.qualified,
                    },
                ],
            })
            .then((res) => {
                console.log(res.data)
            }).catch

        setResult({
            ...result,
            examCenterId: examCenterId,
            qualified:
                result.qualified === false && examCenterId
                    ? true
                    : result.qualified,
        })
    }
    return (
        <div
            style={{
                backgroundColor: 'whitesmoke',
                margin: 10,
                padding: 10,
                borderRadius: 7,
            }}
        >
            <h5 style={{ padding: 0, margin: 0 }}>
                {props?.questionnaire && '[Ne participe pas]'}{' '}
                {result.questionnaire?.name} ({result.questionnaire?.year}){' '}
            </h5>
            <div>
                {result.grade && `Note : ${result.grade}`}
                {result.weightedGrade &&
                    ` Coefficient : ${result.weightedGrade}`}
                {result.time && ` Temps : ${result.time} minutes`}
            </div>
            <div>
                Qualifié ?{' '}
                {result.qualified === true
                    ? 'Oui'
                    : result.qualified === false
                    ? 'Non'
                    : '-'}
                <button
                    type="button"
                    onClick={() => updateQualification(!result.qualified)}
                >
                    Modifier
                </button>
                <button type="button" onClick={() => updateQualification(null)}>
                    Réinitialiser
                </button>
            </div>
            <div>
                Convoqué ? {result.examCenterId ? 'Oui' : 'Non'}{' '}
                {result.examCenterId && (
                    <button type="button" onClick={() => updateCenter()}>
                        Annuler
                    </button>
                )}
            </div>
            {props.examCentersOptions && (
                <div>
                    Centre d'épreuve pour la prochaine étape :{' '}
                    <Select
                        value={
                            props.examCentersOptions.find(
                                (option) => option.value === result.examCenterId
                            ) || null
                        }
                        options={props.examCentersOptions}
                        isSearchable={true}
                        placeholder=""
                        onChange={(e) => {
                            updateCenter(
                                (
                                    e as unknown as {
                                        value: string
                                        label: string
                                    }
                                ).value
                            )
                        }}
                    />
                </div>
            )}
        </div>
    )
}
