import { useEffect, useState } from 'react'
import NavbarAdmin from '../../components/NavbarAdmin'
import './adminParticipant.css'
import Table, { PageObject } from '../../components/Table'
import axios from '../../axios/axios'
import Modal from '../../components/Modal'
import { eStatus } from '../../types/enums'
import { iRegistrationWithUser, iUser } from '../../types/user'
import Select from 'react-select'
import constAdminParticipant from '../../types/tables/constAdminParticipant'

import { exportExcelFile } from './actionModals/actions'
import { modifyModal } from './actionModals/modifyModal'
import { getCategories } from '../../helpers/getCategories'
import { confirmRegistrationModal } from './actionModals/confirmRegistrationModal'

const filterOptions = [
    { value: 'Aucun filtre', label: 'Aucun filtre' },
    { value: 'user.contact.lastname', label: 'Nom' },
    {
        value: 'user.contact.firstname',
        label: 'Prénom',
    },
    {
        value: 'category.name',
        label: 'Catégorie',
    },
    {
        value: 'status',
        label: "Status de l'adhésion",
    },
    {
        value: 'establishement.name',
        label: 'Etablissement',
    },
]

function AdminParticipant() {
    const [pages, setPages] = useState<PageObject>({
        pages: 1,
        actual: 1,
    })

    const [triggerEffect, setTriggerEffect] = useState(false)
    const [filteredData, setFilteredData] = useState<iUser[] | null>(null)
    const [years, setYears] = useState<{ year: string }[]>([])
    const [selectedYear, setSelectedYear] = useState<string>('')
    const [search, setSearch] = useState('')
    const [ModalActive, setModalActive] = useState(false)
    const [modalType, setModalType] = useState<JSX.Element | null>(null)
    const [catsOptions, setCatsOptions] = useState<
        { value: string; label: string }[]
    >([])

    const [filterPrimary, setFilterPrimary] = useState<{
        value: string
        label: string
    } | null>(null)
    const [filterSecondary, setFilterSecondary] = useState<{
        value: string
        label: string
    } | null>(null)
    const [filterCategory, setFilterCategory] = useState<{
        value: string
        label: string
    } | null>(null)

    const Pagination = (page: number) => {
        setPages({
            pages: pages.pages,
            actual: page,
        })
    }

    const exportToExcel = () => {
        setModalType(
            <>
                <span>Voulez-vous exporter les données participants ?</span>
                <div className="buttons-modal-multiple">
                    <button
                        className="buttons-border-off admin-border-off"
                        onClick={() => setModalActive(false)}
                    >
                        Annuler
                    </button>
                    <button
                        className="buttons-blue blue-admin"
                        onClick={async () =>
                            await exportExcelFile(setModalActive, selectedYear)
                        }
                    >
                        Confirmer
                    </button>
                </div>
            </>
        )
        setModalActive(true)
    }

    useEffect(() => {
        document.title = 'Participants'
        getCategories().then((cats) =>
            setCatsOptions([
                { value: 'Aucun filtre', label: 'Aucun filtre' },
                ...cats,
            ])
        )
        axios.get('/questionnaire/years').then((res) => {
            setYears(res.data)
            // if (selectedYear === '')
            //     setSelectedYear(res.data[res.data.length - 1].year)
            axios.get('configurations/ACTIVE_YEAR').then((res) => {
                setSelectedYear(res.data.value)
            })
        }).catch
    }, [])

    useEffect(() => {
        if (selectedYear === '') return
        axios
            .get(
                '/registrations?limit=10&page=' +
                    (pages.actual ?? '1') +
                    (search !== '' ? '&search=' + search : '') +
                    (filterPrimary
                        ? '&sortBy=' + filterPrimary.value + ':ASC'
                        : '') +
                    (filterSecondary
                        ? '&sortBy=' + filterSecondary.value + ':ASC'
                        : '') +
                    (filterCategory && filterCategory.value !== 'Aucun filtre'
                        ? '&filter.category.name=' + filterCategory.value
                        : '') +
                    (selectedYear ? '&filter.year=' + selectedYear : '')
            )
            .then((res) => {
                setPages({
                    pages: res.data.meta.totalPages,
                    actual:
                        res.data.meta.totalPages >= res.data.meta.currentPage
                            ? res.data.meta.currentPage
                            : res.data.meta.totalPages,
                })
                const data = res.data.data.map(
                    (registration: iRegistrationWithUser) => ({
                        ...registration.user,
                        ...registration,

                        canBeConfirmed: registration.status === eStatus.PENDING,
                    })
                )

                setFilteredData(data)
            }).catch
    }, [
        pages.actual,
        triggerEffect,
        search,
        filterPrimary,
        filterSecondary,
        filterCategory,
        selectedYear,
    ])
    return (
        <>
            <div className="admin-container">
                <div className="navbar">
                    <div className="nav-title">Participants</div>
                    <NavbarAdmin active="participants" />
                </div>
                <div className="page-body">
                    <div className="font-italic text-xs mb-10">
                        Ici vous retrouverez l'ensemble participants année par
                        année, l'icone "valider" permet soit de valider
                        l'adhésion d'un participant soit de confirmer que le
                        participant fait bien parti de son établissement (action
                        réalisable aussi par le référent de l'établissement) .
                    </div>
                    <div
                        style={{
                            flexDirection: 'column',
                            marginTop: 10,
                            marginBottom: 10,
                        }}
                    >
                        {years.map((year) => {
                            return (
                                <button
                                    key={year.year}
                                    className={
                                        year.year == selectedYear
                                            ? 'buttons-blue blue-admin'
                                            : 'buttons-white white-admin'
                                    }
                                    onClick={() => {
                                        setSelectedYear(year.year)
                                    }}
                                >
                                    {year.year}
                                </button>
                            )
                        })}
                    </div>
                    <div className="w-100">
                        <div className="insc-inputs" id="admin-search">
                            <input
                                className="insc-input"
                                type="text"
                                placeholder="Rechercher un participant..."
                                onChange={(e) =>
                                    setSearch(() => e.target.value)
                                }
                            />
                        </div>
                        <div className="export-admin">
                            <button
                                className="buttons-blue blue-admin"
                                onClick={exportToExcel}
                            >
                                Exporter
                            </button>
                        </div>
                    </div>

                    <div
                        className="w-100"
                        style={{ paddingInline: 17, display: 'flex', gap: 20 }}
                    >
                        <div className="insc-inputs">
                            <span className="inputs-title-insc ">
                                Ordonner par (primaire)
                            </span>
                            <Select
                                options={filterOptions}
                                placeholder=""
                                onChange={(e) => {
                                    setFilterPrimary(
                                        e as unknown as {
                                            value: string
                                            label: string
                                        }
                                    )
                                }}
                                value={filterPrimary}
                            />
                        </div>
                        <div className="insc-inputs">
                            <span className="inputs-title-insc">
                                Ordonner par (secondaire)
                            </span>
                            <Select
                                options={filterOptions}
                                placeholder=""
                                onChange={(e) => {
                                    setFilterSecondary(
                                        e as unknown as {
                                            value: string
                                            label: string
                                        }
                                    )
                                }}
                                value={filterSecondary}
                            />
                        </div>
                        <div className="insc-inputs">
                            <span className="inputs-title-insc">Catégorie</span>
                            <Select
                                options={catsOptions}
                                placeholder=""
                                onChange={(e) => {
                                    setFilterCategory(
                                        e as unknown as {
                                            value: string
                                            label: string
                                        }
                                    )
                                }}
                                value={filterCategory}
                            />
                        </div>
                    </div>

                    <Table
                        titles={constAdminParticipant}
                        data={filteredData}
                        color="--orange100"
                        pagination={Pagination}
                        filter={() => {}}
                        page={pages}
                        actions={{
                            modify: {
                                action: (user) =>
                                    modifyModal(
                                        user.user,
                                        setModalType,
                                        setModalActive,
                                        setTriggerEffect,
                                        triggerEffect,
                                        'admin'
                                    ),
                            },
                            confirm: {
                                action: (user) =>
                                    confirmRegistrationModal(
                                        selectedYear,
                                        user.user,
                                        setModalType,
                                        setModalActive,
                                        triggerEffect,
                                        setTriggerEffect,
                                        'admin'
                                    ),
                                showCondition: (user: iUser) =>
                                    user.status === 'PENDING',
                            },
                        }}
                        margin="30"
                    />
                </div>

                <Modal
                    isActive={ModalActive}
                    setActive={async () => {
                        setModalActive(false)
                        setTimeout(() => setTriggerEffect(!triggerEffect), 500)
                    }}
                    children={modalType}
                ></Modal>
            </div>
        </>
    )
}
export default AdminParticipant
