import { useLocation, useNavigate } from 'react-router-dom'
import authService from '../services/AuthService'
import './navbarAdmin.css'

function Header(props: {
    buttons?: {
        title: string
        link?: string
        action?: () => void
        loadingId?: string
        active?: boolean
    }[]
    color?: string
    noDisconnectButton?: boolean
}) {
    const navigate = useNavigate()
    const location = useLocation()

    return (
        <div className="header">
            <img className="logo" />
            <div className="buttons">
                {props.buttons &&
                    props.buttons.map((button, index) => {
                        return (
                            <button
                                key={index}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                className={
                                    button.active ||
                                    (button.link &&
                                        location.pathname.startsWith(
                                            button.link
                                        ))
                                        ? 'buttons-basis button-color-' +
                                          props.color +
                                          '-full'
                                        : 'buttons-basis button-color-' +
                                          props.color +
                                          '-border'
                                }
                                onClick={() =>
                                    button.link
                                        ? button.link != ''
                                            ? navigate(button.link)
                                            : alert('Bientôt disponible !')
                                        : button?.action
                                        ? button.action()
                                        : alert('Bientôt disponible !')
                                }
                            >
                                {button.title}
                                <span className="d-inline-block"> </span>{' '}
                                <div
                                    id={button.loadingId}
                                    className="loader d-none "
                                ></div>
                            </button>
                        )
                    })}
                {!props.noDisconnectButton && (
                    <button
                        className={
                            'buttons-basis button-color-' +
                            props.color +
                            '-full'
                        }
                        onClick={() => {
                            authService.logout()
                            navigate('/Connexion')
                        }}
                    >
                        Se déconnecter
                    </button>
                )}
            </div>
        </div>
    )
}
export default Header
