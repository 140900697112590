import axios from '../axios/axios'
import { PageObject } from '../components/Table'
import { typeFilterFormat } from '../types/IfilterFormat'

export function getUserRegistrationClub(
    pages: PageObject,
    clubId: string,
    year: number,
    filterPrimary: typeFilterFormat,
    filterSecondary: typeFilterFormat,
    selectedSession: typeFilterFormat
) {
    return axios.get(
        `/registrations/club/${clubId}/members/${year}?limit=15&page=` +
            (pages.actual ?? '1')
        //   +  (filterPrimary ? '&sortBy=' + filterPrimary.value + ':ASC' : '') +
        //     (filterSecondary
        //         ? '&sortBy=' + filterSecondary.value + ':ASC'
        //         : '') +
        //     (selectedSession && selectedSession?.value !== ''
        //         ? '&filter.results.questionnaireId=' + selectedSession.value
        //         : '') +
        //     (year ? '&filter.registrations.year=' + year : '')
    )
}
