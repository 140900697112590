import { toast } from 'react-toastify'
import axios from '../../axios/axios'
import { ExamCenter } from '../../pages/admin/AdminExam'
import { eroles } from '../../types/enums'
import { iUser } from '../../types/user'
import Input from '../forms/common/Input'
import { useEffect, useState } from 'react'

export default function AddExamCenter({
    close,
}: {
    close: () => void
    setData: (data: ExamCenter) => void
    confirm: (e: ExamCenter) => void
}) {
    const [dataToSend, setDataToSend] = useState<ExamCenter>()
    useEffect(() => {
        const dataToSendTemp = {
            typeCenter: {
                year: 2023,
                center: 0,
            },
        } as ExamCenter
        dataToSendTemp.contact = {} as {
            firstname: string
            lastname: string
            email: string
            phone: string
        }
        setDataToSend(dataToSendTemp)
    }, [])

    const [disabled, setDisabled] = useState(false)
    async function createCenter() {
        if (!dataToSend)
            return toast.error('Erreur lors de la création du centre d’épreuve')
        // Create the user

        toast.info('Création de l’utilisateur (référent) en cours...')
        await axios
            .post(`/authentification/register-admin`, {
                role: eroles.USER,
                contact: dataToSend.contact,
                noRegistration: true,
            })
            .then((res) => {
                const user: iUser = res.data.user
                toast.success('Utilisateur créé avec succès')
                toast.info("Création du centre d'épreuve en cours...")
                console.log(user)
                axios
                    .post(`/examcenter/${user.id}`, dataToSend)
                    .then(() => {
                        setDisabled(false)
                        close()
                        toast.success("Centre d'épreuve créé avec succès")
                    })
                    .catch(() => {
                        setDisabled(false)
                        toast.error(
                            "Erreur lors de la création du centre d'épreuve"
                        )
                    })
            })
            .catch(() => {
                toast.error(
                    "Erreur lors de la création de l'utilisateur (référent) - Si l'utilisateur apparait dans la liste des participants, veuillez le supprimer et réessayer"
                )
                setDisabled(false)
            })
    }

    if (!dataToSend) return <>Chargement</>
    return (
        <div style={{ width: '80vw' }}>
            <h1>Ajouter un centre d'épreuve</h1>
            <form className="" id="addSingleUser">
                <div className="add-modal modal-content-scrollable">
                    <Input
                        title="Nom"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                name: e.target.value,
                            })
                        }
                        value={dataToSend.name}
                        required
                    />
                    <Input
                        title="Code RNE"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                rneCode: e.target.value,
                            })
                        }
                        value={dataToSend.rneCode}
                    />
                    <Input
                        title="Rue"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                street: e.target.value,
                            })
                        }
                        value={dataToSend.street}
                    />
                    <Input
                        title="Ville"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                city: e.target.value,
                            })
                        }
                        value={dataToSend.city}
                    />
                    <Input
                        title="Code postal"
                        error={
                            dataToSend.zipCode &&
                            dataToSend.zipCode.length !== 5
                                ? 'Le code postal doit contenir 5 chiffres'
                                : ''
                        }
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                zipCode: e.target.value,
                            })
                        }
                        value={dataToSend.zipCode}
                        required
                    />
                    <Input
                        title="Pays"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                country: e.target.value,
                            })
                        }
                        value={dataToSend.country}
                    />
                    <Input
                        title="Nombre de places"
                        type="number"
                        value={dataToSend.seats}
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                seats: Number(e.target.value),
                            })
                        }
                    />
                    <Input
                        title="Prénom du référent"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                contact: {
                                    ...dataToSend.contact,
                                    firstname: e.target.value,
                                },
                            })
                        }
                        value={dataToSend.contact.firstname}
                        required
                    />
                    <Input
                        title="Nom du référent"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                contact: {
                                    ...dataToSend.contact,
                                    lastname: e.target.value,
                                },
                            })
                        }
                        value={dataToSend.contact.lastname}
                        required
                    />
                    <Input
                        type="email"
                        title="e-mail du référent"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                contact: {
                                    ...dataToSend.contact,
                                    email: e.target.value,
                                },
                            })
                        }
                        value={dataToSend.contact.email}
                        required
                    />
                    <Input
                        title="Téléphone du référent"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                contact: {
                                    ...dataToSend.contact,
                                    phone: e.target.value,
                                },
                            })
                        }
                        value={dataToSend.contact.phone}
                    />
                </div>
                <div className="buttons-modal-multiple">
                    <button
                        className="buttons-border-off school-button-border-off"
                        onClick={close}
                    >
                        Annuler
                    </button>
                    <button
                        className="buttons-blue school-button"
                        type="submit"
                        disabled={disabled}
                        onClick={(e) => {
                            setDisabled(true)
                            e.preventDefault()
                            const formElement = document.getElementById(
                                'addSingleUser'
                            ) as HTMLFormElement

                            if (formElement.checkValidity()) {
                                // confirm(dataToSend)
                                console.log(dataToSend)
                                createCenter()
                            } else {
                                formElement.requestSubmit()
                                setDisabled(false)
                            }
                        }}
                    >
                        Confirmer
                    </button>
                </div>
            </form>
        </div>
    )
}
