import Select from 'react-select'
import Input from '../forms/common/Input'
import { IcreateQuestionnaire } from '../../types/IQuestionnaire'
import Switch from '../forms/common/Switch'
import { useState } from 'react'
import axios from '../../axios/axios'
import { QuizzI } from '../../pages/admin/AdminQuizzList'

export default function AddQuestionnaire({
    close,
    questionnairelist,
}: {
    close: () => void
    questionnairelist: QuizzI[]
}) {
    const [data, setData] = useState<IcreateQuestionnaire>({
        name: '',
        year: new Date().getFullYear(),
        active: true,
        physical: false,
        questions: [],
        previousQuestionnaireId: '',
    })

    function confirm(data: IcreateQuestionnaire) {
        console.log(data)
        if (data.previousQuestionnaireId === '') {
            delete data.previousQuestionnaireId
        }
        axios.post('/questionnaire', data).then((res) => {
            console.log(res)
            close()
        })
    }

    return (
        <div style={{ width: '100%' }}>
            <h1>Créer un questionnaire</h1>
            <form className="" id="addSingleUser">
                <div
                    className="add-modal modal-content-scrollable flex"
                    style={{ flexDirection: 'column' }}
                >
                    <div className="insc-inputs">
                        <span className="inputs-title-insc">
                            Questionnaire précédent (début d'une nouvelle année
                            si vide)
                        </span>
                        <Select
                            options={[
                                { value: null, label: 'Aucun', id: undefined },
                                ...questionnairelist.map((q) => ({
                                    value: q.id,
                                    label: q.name,
                                    id: q.id,
                                })),
                            ]}
                            isSearchable={true}
                            placeholder=""
                            onChange={(value) => {
                                setData({
                                    ...data,
                                    previousQuestionnaireId: value?.id,
                                })
                            }}
                            // value={}
                        />
                    </div>
                    <Input
                        title="Nom"
                        value={data.name}
                        onChange={(e) =>
                            setData({
                                ...data,
                                name: e.target.value,
                            })
                        }
                        required
                    />
                    <Input
                        title="Année"
                        type="number"
                        value={data.year.toString()}
                        onChange={(e) =>
                            setData({
                                ...data,
                                year: parseInt(e.target.value) || 2020,
                            })
                        }
                        required
                    />
                    <Switch
                        name={'Actif'}
                        value={data.active}
                        onChange={(isActive) => {
                            setData({
                                ...data,
                                active: isActive,
                            })
                        }}
                        styles={{ width: 100 }}
                    />
                    <Switch
                        name={'Physique (Demi-finale, finale)'}
                        value={data.physical}
                        onChange={(isActive) => {
                            setData({
                                ...data,
                                physical: isActive,
                            })
                        }}
                        styles={{ width: 400, marginTop: 10 }}
                    />
                </div>
                <div className="buttons-modal-multiple">
                    <button
                        className="buttons-border-off school-button-border-off"
                        onClick={close}
                    >
                        Annuler
                    </button>
                    <button
                        className="buttons-blue school-button"
                        type="submit"
                        onClick={(e) => {
                            e.preventDefault()
                            const formElement = document.getElementById(
                                'addSingleUser'
                            ) as HTMLFormElement

                            if (formElement.checkValidity()) {
                                confirm(data)
                            } else {
                                formElement.requestSubmit()
                            }
                        }}
                    >
                        Créer
                    </button>
                </div>
            </form>
        </div>
    )
}
