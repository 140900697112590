import axios from '../axios/axios'
import { useEffect, useState } from 'react'
import Header from '../components/Header'
import Question, { qType } from '../components/questions/Question'
import { toast } from 'react-toastify'
import ParticipantLayout from './layouts/ParticipantLayout'

interface userQuestionI {
    id: string
    title: string
    text: string
    image_start?: string
    image_end?: string
    possibleAnswers?: { id: string; value: string }[] | null
    isMultiple?: boolean | null
    askTotal?: boolean | null
    openAnswer?: number | null
    regex?: string
}
export default function QuestionnaireUser() {
    const [listOfQuestions, setListOfQuestions] = useState<userQuestionI[]>([])
    const [isLoading, setIsloading] = useState(true)
    const [listOfAnswers, setListOfAnswers] = useState<{
        [key: string]: {
            numberOfAnswer?: number
            choiceFromList?: string[]
            freeAnswer?: string[]
        }
    }>({})
    const [isClose, setIsClose] = useState(false)

    const [noQuestionnaire, setNoQuestionnaire] = useState(false)
    function setAnswers(
        questionId: string,
        answers: {
            numberOfAnswer?: number
            choiceFromList?: string[]
            freeAnswer?: string[]
        }
    ) {
        const newA = listOfAnswers
        newA[questionId] = answers
        setListOfAnswers((val) => newA)
    }
    useEffect(() => {
        axios
            .get('/questionnaire/active')
            .then((res) => {
                const questions = res.data.questions
                setListOfQuestions(questions)
                setIsClose(res.data.isClosed)
                axios.get('/users-answer').then((res2) => {
                    const answers = res2.data
                    const reviewAns: {
                        [id: string]: {
                            answers: string[]
                            totalAnswer?: number
                        }
                    } = {}
                    answers.map(
                        (answer: {
                            questionId: string
                            answers: string[]
                            totalAnswer?: number
                        }) => {
                            reviewAns[answer.questionId] = {
                                answers: answer.answers,
                                totalAnswer: answer.totalAnswer,
                            }
                        }
                    )

                    questions.map((question: userQuestionI) => {
                        if (reviewAns[question.id]) {
                            setAnswers(question.id, {
                                choiceFromList:
                                    question.openAnswer == 0
                                        ? reviewAns[question.id].answers
                                        : undefined,
                                freeAnswer:
                                    question.openAnswer == 0
                                        ? undefined
                                        : reviewAns[question.id].answers,
                                numberOfAnswer:
                                    reviewAns[question.id].totalAnswer,
                            })
                        }
                    })
                    setIsloading(false)
                })
            })
            .catch((res) => {
                console.log('HEY')
                setNoQuestionnaire(true)
                setIsloading(false)
            })
    }, [])

    const [buttonLoading, setButtonLoading] = useState(false)
    function save() {
        // get button with Id saveButton
        const button = document.getElementById('saveButton')
        button?.classList.remove('d-none')
        setButtonLoading(true)
        // for each key
        const dataToSend = []
        for (const key in listOfAnswers) {
            const ans: {
                questionId: string
                answers?: string[]
                totalAnswer?: number
            } = {
                questionId: key,
            }
            const keyObj = listOfAnswers[key]
            if (keyObj.choiceFromList && keyObj.choiceFromList.length > 0) {
                ans['answers'] = keyObj.choiceFromList
            } else if (keyObj.freeAnswer && keyObj.freeAnswer.length > 0) {
                ans['answers'] = keyObj.freeAnswer
            }
            if (keyObj.numberOfAnswer !== undefined) {
                ans['totalAnswer'] = keyObj.numberOfAnswer
            }

            dataToSend.push(ans)
        }
        axios.post('/users-answer', dataToSend).then(() => {
            setTimeout(() => {
                button?.classList.add('d-none')
            }, 1000)
        })
        toast.success('Vos réponses ont été enregistrées.')
    }

    if (isLoading) {
        return <div>Chargement...</div>
    }
    if (noQuestionnaire) {
        return (
            <ParticipantLayout
                color="yellow"
                headerButtons={
                    isClose
                        ? [{ title: 'Quitter', link: '/participant' }]
                        : [{ title: 'Quitter', link: '/participant' }]
                }
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        height: '80%',
                        width: '80%',
                        marginTop: 20,
                        padding: 10,
                    }}
                >
                    Il n'y a actuellement aucun questionnaire accessible,
                    revenez plus tard !
                </div>
            </ParticipantLayout>
        )
    }
    return (
        <ParticipantLayout
            color="yellow"
            headerButtons={
                isClose
                    ? [{ title: 'Quitter', link: '/participant' }]
                    : [
                          {
                              title: 'Enregistrer',
                              action: () => save(),
                              loadingId: 'saveButton',
                          },
                          { title: 'Quitter', link: '/participant' },
                      ]
            }
        >
            <div>
                {isClose && (
                    <div className="alert alert-danger" role="alert">
                        <h4 className="alert-heading">Questionnaire fermé !</h4>
                        <p>
                            Vous ne pouvez désormais plus répondre au
                            questionnaire. Vous pouvez toutefois consulter vos
                            réponses.
                        </p>
                        <hr />
                    </div>
                )}

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '80%',
                        marginTop: 20,
                    }}
                >
                    {listOfQuestions &&
                        listOfQuestions.map((question) => {
                            return (
                                <Question
                                    key={question.id}
                                    answers={listOfAnswers[question.id] || {}}
                                    number={question.id}
                                    answerType={
                                        question?.isMultiple
                                            ? qType.MULTIPLE
                                            : qType.SINGLE
                                    }
                                    setAnswers={(answers: {
                                        numberOfAnswer?: number
                                        choiceFromList?: string[]
                                        freeAnswer?: string[]
                                    }) => setAnswers(question.id, answers)}
                                    title={question.title}
                                    possibleAnswers={question.possibleAnswers}
                                    text={question.text}
                                    numberOfFields={question.openAnswer}
                                    image_end={question.image_end}
                                    image_start={question.image_start}
                                    askForTotalNumber={question.askTotal}
                                    readOnly={isClose}
                                    regex={question?.regex}
                                />
                            )
                        })}
                </div>
            </div>
        </ParticipantLayout>
    )
}
