import { useState } from 'react'
import NavbarAdmin from '../../../components/NavbarAdmin'
import '../adminEtablissement.css'
import CategoryConfig from './CategoryConfig'
import LanguagesConfig from './LanguagesConfig'
import ActiveYear from './ActiveYear'
import InscriptionsDates from './InscriptionsDates'

function AdminConfiguration() {
    const settings = [
        {
            label: 'Catégories',
            component: <CategoryConfig />,
        },
        {
            label: 'Langues',
            component: <LanguagesConfig />,
        },
        {
            label: 'Année active',
            component: <ActiveYear />,
        },
        {
            label: 'Dates inscriptions',
            component: <InscriptionsDates />,
        },
    ]

    const [selectedSetting, setSelectedSetting] = useState(settings[0])

    return (
        <>
            <div className="admin-container">
                <div className="navbar">
                    <div className="nav-title">Configuration</div>
                    <NavbarAdmin active="configuration" />
                </div>
                {settings.map((setting) => {
                    return (
                        <button
                            key={setting.label}
                            className={
                                setting.label == selectedSetting.label
                                    ? 'buttons-blue blue-admin'
                                    : 'buttons-white white-admin'
                            }
                            onClick={() => setSelectedSetting(setting)}
                        >
                            {setting.label}
                        </button>
                    )
                })}
                <div className="page-body" style={{ width: '100%' }}>
                    {selectedSetting.component}
                </div>
            </div>
        </>
    )
}
export default AdminConfiguration
