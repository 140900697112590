import React, { SetStateAction, useEffect, useState } from 'react'
import { iRegistration, iResult } from '../types/user'
import axios from '../axios/axios'
import { toast } from 'react-toastify'
import UserModificationConvocation from './forms/UserModificationConvocation'
import DetailRegistration from './DetailRegistration'
import { getCategories } from '../helpers/getCategories'

interface EditRegistrationsOfUserBySchoolProps {
    registrations: iRegistration[]
}

const EditRegistrationsOfUserBySchool: React.FC<
    EditRegistrationsOfUserBySchoolProps
> = ({ registrations }) => {
    // Add your component logic here

    const [selectedRegistration, setSelectedRegistration] =
        useState<iRegistration>()

    const [catsOptions, setCatsOptions] = useState<
        { value: string; label: string }[]
    >([])
    useEffect(() => {
        getCategories().then((cats) => setCatsOptions(cats))
    }, [])
    return (
        <div
            style={{
                background: 'lightgrey',
                paddingInline: 10,
                borderRadius: 10,
                paddingBottom: 10,
            }}
        >
            <h2 style={{ paddingTop: 10 }}>Gestion du championnat</h2>
            <div>
                {registrations &&
                    registrations.map((registration) => {
                        return (
                            <button
                                type="button"
                                className={
                                    registration.year ==
                                    selectedRegistration?.year
                                        ? 'buttons-blue blue-admin'
                                        : 'buttons-white white-admin'
                                }
                                onClick={() => {
                                    setSelectedRegistration(registration)
                                }}
                            >
                                {registration.year}
                            </button>
                        )
                    })}
            </div>
            <hr />
            {selectedRegistration && (
                <div style={{ marginTop: 10 }}>
                    <DetailRegistration
                        registration={selectedRegistration}
                        categories={catsOptions}
                    />
                </div>
            )}
            {/* {results && (
                <div style={{ marginTop: 10 }}>
                    {results.map((result) => (
                        <div key={result.questionnaireId}>
                            <UserModificationConvocation
                                result={result}
                                examCentersOptions={examCentersOptions}
                            />
                        </div>
                    ))}
                </div>
            )} */}
        </div>
    )
}

export default EditRegistrationsOfUserBySchool
