import './participantInformation.css'
import axios from '../../axios/axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import { ecategories } from '../../types/enums'
import { iIndividualUser, iRegistration, iSchoolUser } from '../../types/user'
import authService from '../../services/AuthService'
import { ExamCenter } from '../admin/AdminExam'
import ParticipantLayout from '../layouts/ParticipantLayout'

function ParticipantPersonnalInformation() {
    useEffect(() => {
        document.title = 'Informations'
    }, [])

    const navigate = useNavigate()

    const [mail, setMail] = useState('')
    const [phone, setPhone] = useState('')
    const [genre, setGenre] = useState('')

    const [data, setData] = useState<iSchoolUser | iIndividualUser | null>(null)

    const [activeYear, setActiveYear] = useState(2020)
    function fetchData() {
        axios
            .get('/users/me')
            .then((res) => {
                setData(res.data)
                setMail(res.data.contact.email)
                setGenre(res.data.genre)
                setPhone(res.data.contact.phone)

                if (res.data.controlledCenter.length > 0) {
                    navigate(
                        `/examcenter/participants/${res.data.controlledCenter[0].examCenterId}/${res.data.controlledCenter[0].associatedQuestionnaireId}`
                    )
                }
            })
            .catch((e) => {
                if (e.response.status === 401) {
                    authService.logout()
                    navigate('/Connexion')
                }
            })
    }
    const [activeRegistration, setActiveRegistration] = useState<
        iRegistration | undefined
    >(undefined)
    function getActive(data: iSchoolUser | iIndividualUser | null) {
        axios.get('/configurations/public/ACTIVE_YEAR').then((res) => {
            setActiveYear(res.data.value)
            if (data) {
                setActiveRegistration(
                    data.registrations?.filter(
                        (registration) =>
                            registration?.year === parseInt(res.data.value)
                    )?.[0]
                )
            }
        })
    }
    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {
        getActive(data)
    }, [data])
    function Update() {
        axios
            .patch('/users/me', { contact: { phone: phone }, genre: genre })
            .then((res) => {
                fetchData()
            })
            .catch()
    }

    if (!data) return <>Chargement...</>
    return (
        <ParticipantLayout
            headerButtons={
                !activeRegistration ||
                activeRegistration?.establishment ||
                !activeRegistration?.category.canSeeQuestionnaire
                    ? [
                          {
                              title: 'Informations Championnat',
                              link: '/participant/informations-championnat',
                          },
                          {
                              title: 'Informations Personnelles',
                              link: '/participant/informations-personnelles',
                          },
                      ]
                    : [
                          {
                              title: 'Informations Championnat',
                              link: '/participant/informations-championnat',
                          },
                          {
                              title: 'Informations Personnelles',
                              link: '/participant/informations-personnelles',
                          },
                          {
                              title: 'Mes questionnaires',
                              link: '/participant/questionnaire',
                          },
                      ]
            }
            color="yellow"
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div className="body">
                    <div
                        style={{
                            display: 'flex',
                            gap: 20,
                            flexDirection: 'column',
                        }}
                    >
                        <h1>Vos informations personnelles</h1>
                        <span className="inputs-infos-data">
                            {data.contact.firstname} {data.contact.lastname}
                        </span>
                        <span className="inputs-infos-data">
                            Identifiant : {data.username}
                        </span>

                        <input
                            type="text"
                            className="co-input"
                            id="email-update"
                            placeholder="Email"
                            disabled
                            defaultValue={data.contact.email}
                            /*value={mail}*/
                            onChange={(e) => setMail(e.target.value)}
                        />

                        <input
                            type="text"
                            className="co-input"
                            id="phone-update"
                            placeholder="Téléphone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <div className="insc-inputs">
                            <span className="inputs-title-insc">Genre</span>
                            <div
                                className="flex"
                                style={{ alignItems: 'center', gap: 15 }}
                            >
                                <div
                                    className="flex"
                                    style={{ alignItems: 'center', gap: 10 }}
                                >
                                    <input
                                        type="radio"
                                        name="genre"
                                        id="homme"
                                        value="homme"
                                        checked={genre === 'homme'}
                                        onChange={(e) => setGenre('homme')}
                                    />
                                    <label htmlFor="homme">Homme</label>
                                </div>
                                <div
                                    className="flex"
                                    style={{ alignItems: 'center', gap: 10 }}
                                >
                                    <input
                                        type="radio"
                                        name="genre"
                                        id="femme"
                                        value="femme"
                                        checked={genre === 'femme'}
                                        onChange={(e) => setGenre('femme')}
                                    />
                                    <label htmlFor="femme">Femme</label>
                                </div>
                            </div>
                        </div>
                        {(data.contact.phone !== phone ||
                            data.genre !== genre) && (
                            <button
                                className="buttons-blue part-button save"
                                id="butt-en-school"
                                onClick={() => Update()}
                            >
                                Enregistrer
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </ParticipantLayout>
    )
}
export default ParticipantPersonnalInformation
