import React, { useEffect, useState } from 'react'
import ParticipantLayout from '../layouts/ParticipantLayout'
import { iClub, iClubUser } from '../../types/user'
import axios from '../../axios/axios'
import { eStatus } from '../../types/enums'

const ClubPageInformation: React.FC = () => {
    const [data, setData] = useState<iClubUser>()

    const [selectedClub, setSelectedClub] = useState<iClub>()
    useEffect(() => {
        document.title = 'Club'
        axios.get('/users/me').then((res: { data: iClubUser }) => {
            setData(res.data)
            if (
                res.data?.controlledClubs &&
                res.data.controlledClubs.length > 0
            )
                setSelectedClub(res.data?.controlledClubs?.[0])
        })
    }, [])
    return (
        <ParticipantLayout
            color="red"
            headerButtons={[
                {
                    title: 'Informations Club',
                    link: '/club',
                },
                {
                    title: 'Adhérents',
                    link: '/club/participants',
                },
            ]}
        >
            <div className="flex-col flex">
                <h1>Espace Club</h1>
                {data?.controlledClubs &&
                    data?.controlledClubs.map((club) => {
                        return (
                            <button
                                key={club.id}
                                className={
                                    club.id == selectedClub?.id
                                        ? 'buttons-basis button-color-red-full'
                                        : 'buttons-basis button-color-red-border'
                                }
                                onClick={() => {
                                    setSelectedClub(club)
                                }}
                            >
                                {club.name}
                            </button>
                        )
                    })}
                {selectedClub ? (
                    <div className="w-full flex flex-col gap-4 ml-1">
                        <h2>Informations</h2>
                        <div className="flex">
                            <span className="inputs-title">Nom :</span>
                            <span className="inputs-infos-data">
                                {selectedClub.name}
                            </span>
                        </div>
                        <div className="flex">
                            <span className="inputs-title">Adresse :</span>
                            <span className="inputs-infos-data">
                                {selectedClub.street}, {selectedClub.city}{' '}
                                {selectedClub.zipCode}, {selectedClub.country}
                            </span>
                        </div>

                        <div className="flex">
                            <span className="inputs-title">
                                Paiement de l'adhésion :
                            </span>
                            <span className="inputs-infos-data flex flex-col">
                                {selectedClub.registrations &&
                                    selectedClub.registrations.map((reg) => (
                                        <span key={reg.year}>
                                            {reg.year} :{' '}
                                            {reg.status === eStatus.PENDING
                                                ? 'En attente de paiement'
                                                : 'Payé'}
                                        </span>
                                    ))}
                            </span>
                        </div>
                    </div>
                ) : (
                    <div>Aucun club sélectionné</div>
                )}
            </div>
        </ParticipantLayout>
    )
}

export default ClubPageInformation
