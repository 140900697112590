import './App.css'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Connexion from './pages/connexion/Connexion'
import Inscription from './pages/inscription/Inscription'
import SchoolInformation from './pages/SchoolInformation'
import ProtectedRoute from './services/ProtectedRoute'
import SchoolParticipant from './pages/SchoolParticipant'
import AdminParticipant from './pages/admin/AdminParticipant'
import AdminEtablissement from './pages/admin/AdminEtablissement'
import AdminExam from './pages/admin/AdminExam'
import ParticipantInformationChampionnat from './pages/participant/ParticipantInformationChampionnat'
import { eroles } from './types/enums'
import { createContext, useState } from 'react'
import QuestionnaireUser from './pages/QuestionnaireUser'
import AdminCreateAndEditQuizz from './pages/admin/AdminCreateAndEditQuizz'
import AdminQuizzList from './pages/admin/AdminQuizzList'
import AdminParticipantResults from './pages/admin/AdminParticipantResults'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import SchoolExamCenter from './pages/SchoolExamCenter'
import ParticipantLayout from './pages/layouts/ParticipantLayout'
import ParticipantPersonnalInformation from './pages/participant/ParticipantPersonnalInformation'
import AdminExports from './pages/admin/AdminExports'
import AdminConfiguration from './pages/admin/config/AdminConfiguration'
import AdminUsers from './pages/admin/AdminUsers'
import AdminClubs from './pages/admin/AdminClubs'
import ClubPageInformation from './pages/club/ClubPageInformation'
import ClubPageMembers from './pages/club/ClubPageMembers'

export const GlobalVariablesContext = createContext({
    convocations: false,
    results: false,
    formAccessible: false,
    formFinished: false,
    createQuestions: false,
    examCenter: false,
})

function App() {
    const [activeFunctions, setActiveFunctions] = useState({
        convocations: false,
        results: false,
        formAccessible: false,
        formFinished: false,
        createQuestions: false,
        examCenter: true,
    })

    return (
        <GlobalVariablesContext.Provider value={activeFunctions}>
            <BrowserRouter>
                <Routes>
                    <Route path="/*" element={<Navigate to="/connexion" />} />
                    <Route path="/connexion" element={<Connexion />} />
                    <Route path="/inscription" element={<Inscription />} />

                    <Route
                        path="/admin/questionnaire"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.ADMIN]}>
                                <AdminQuizzList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/questionnaire/:id"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.ADMIN]}>
                                <AdminCreateAndEditQuizz />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/users"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.ADMIN]}>
                                <AdminUsers />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/reponses/questionnaire/:id"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.ADMIN]}>
                                <AdminParticipantResults />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/configuration"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.ADMIN]}>
                                <AdminConfiguration />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/participants"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.ADMIN]}>
                                <AdminParticipant />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/clubs"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.ADMIN]}>
                                <AdminClubs />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/etablissements"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.ADMIN]}>
                                <AdminEtablissement />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/centres"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.ADMIN]}>
                                <AdminExam />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/exports"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.ADMIN]}>
                                <AdminExports />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/centres/:centerId"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.ADMIN]}>
                                <SchoolExamCenter />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/centres/:centerId/:questionnaireId"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.ADMIN]}>
                                <SchoolExamCenter />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/*"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.ADMIN]}>
                                <Navigate to="/admin/participants" />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/etablissement/infos"
                        element={
                            <ProtectedRoute
                                allowedRoles={[eroles.ESTABLISHEMENT]}
                            >
                                <SchoolInformation />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/etablissement/participants"
                        element={
                            <ProtectedRoute
                                allowedRoles={[eroles.ESTABLISHEMENT]}
                            >
                                <SchoolParticipant />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/examcenter/participants/:centerId"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.USER]}>
                                <SchoolExamCenter />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/examcenter/participants/:centerId/:questionnaireId"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.USER]}>
                                <SchoolExamCenter />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/etablissement/examcenter/:centerId"
                        element={
                            <ProtectedRoute
                                allowedRoles={[eroles.ESTABLISHEMENT]}
                            >
                                <SchoolExamCenter />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/etablissement/examcenter/:centerId/:questionnaireId"
                        element={
                            <ProtectedRoute
                                allowedRoles={[eroles.ESTABLISHEMENT]}
                            >
                                <SchoolExamCenter />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/etablissement/*"
                        element={
                            <ProtectedRoute
                                allowedRoles={[eroles.ESTABLISHEMENT]}
                            >
                                <Navigate to="/etablissement/infos" />
                            </ProtectedRoute>
                        }
                    />

                    <Route
                        path="/club/participants"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.CLUB]}>
                                <ClubPageMembers />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/club/info"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.CLUB]}>
                                <ClubPageInformation />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/club/*"
                        element={
                            <ProtectedRoute allowedRoles={[eroles.CLUB]}>
                                <Navigate to="/club/info" />
                            </ProtectedRoute>
                        }
                    />

                    <Route path="/participant">
                        <Route
                            path="informations-championnat"
                            element={
                                <ProtectedRoute allowedRoles={[eroles.USER]}>
                                    <ParticipantInformationChampionnat />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="informations-personnelles"
                            element={
                                <ProtectedRoute allowedRoles={[eroles.USER]}>
                                    <ParticipantPersonnalInformation />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/participant/questionnaire"
                            element={
                                <ProtectedRoute allowedRoles={[eroles.USER]}>
                                    <QuestionnaireUser />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/participant"
                            element={
                                <ProtectedRoute allowedRoles={[eroles.USER]}>
                                    <Navigate to="/participant/informations-championnat" />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/participant/*"
                            element={
                                <ProtectedRoute allowedRoles={[eroles.USER]}>
                                    <Navigate to="/participant/informations-championnat" />
                                </ProtectedRoute>
                            }
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
            <ToastContainer />
        </GlobalVariablesContext.Provider>
    )
}

export default App
