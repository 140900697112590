import { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import ControlledInput from '../forms/common/ControlledInput'

export enum qType {
    SINGLE,
    MULTIPLE,
}

export default function Question({
    number,
    answerType,
    title,
    text,
    image_end,
    image_start,
    possibleAnswers,
    numberOfFields = 0,
    askForTotalNumber = false,
    answers,
    setAnswers,
    readOnly,
    regex,
}: {
    number: string
    answerType?: qType
    title: string
    text: string
    image_start?: string
    image_end?: string
    possibleAnswers?: { id: string; value: string }[] | null
    numberOfFields?: number | null
    askForTotalNumber?: boolean | null
    regex?: string
    answers: {
        numberOfAnswer?: number
        choiceFromList?: string[]
        freeAnswer?: string[]
    }
    setAnswers: (answers: {
        numberOfAnswer?: number
        choiceFromList?: string[]
        freeAnswer?: string[]
    }) => void
    readOnly: boolean
}) {
    //console.log(possibleAnswers)
    const [fields, setFields] = useState<number[]>([])
    const [directAnw, setDirectAnw] = useState<{
        numberOfAnswer?: number
        choiceFromList?: string[]
        freeAnswer?: string[]
    }>()
    useEffect(() => {
        console.log('UPDATE', answers)
        setDirectAnw(answers)
    }, [answers])

    useEffect(() => {
        const i = []
        if (numberOfFields)
            while (i.length < numberOfFields) {
                i.push(i.length + 1)
            }
        setFields(i)
    }, [numberOfFields])

    function checkbox(value: string) {
        const index = directAnw?.choiceFromList?.findIndex(
            (item) => item == value
        )

        if (directAnw?.choiceFromList && index !== undefined && index > -1) {
            console.log('REMOVE')
            const newChoiceFromList = [
                ...directAnw.choiceFromList.slice(0, index),
                ...directAnw.choiceFromList.slice(index + 1),
            ]
            setDirectAnw((old) => ({
                ...old,
                choiceFromList: newChoiceFromList,
            }))
            setAnswers({
                ...answers,
                choiceFromList: newChoiceFromList,
            })
        } else {
            const list =
                answerType == qType.MULTIPLE
                    ? directAnw?.choiceFromList || []
                    : []
            list.push(value)
            setDirectAnw({
                ...answers,
                choiceFromList: list,
            })
            setAnswers({
                ...answers,
                choiceFromList: list,
            })
            console.log('ADD')
        }
        console.log(number, value, answers?.choiceFromList)
    }
    function setFreeAnswers(i: number, value: string) {
        const an = directAnw?.freeAnswer || new Array(numberOfFields || 0)

        an[i - 1] = value
        console.log('OLD', directAnw)
        setDirectAnw({
            ...directAnw,
            freeAnswer: an,
        })
        setAnswers({
            ...directAnw,
            freeAnswer: an,
        })
    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
                marginBottom: 20,
            }}
        >
            <div style={{ fontWeight: 'bold' }}>{title}</div>
            {image_start && (
                <img
                    src={'data:image/png;base64,' + image_start}
                    style={{ width: 150 }}
                />
            )}
            <div>{parse(text)}</div>
            {image_end && (
                <img
                    src={'data:image/png;base64,' + image_end}
                    style={{ width: 100 }}
                />
            )}

            {askForTotalNumber && (
                <ControlledInput
                    disabled={readOnly}
                    title="Nombre total de réponses"
                    type="number"
                    value={directAnw?.numberOfAnswer}
                    defaultValue={
                        directAnw?.numberOfAnswer
                            ? directAnw.numberOfAnswer
                            : ''
                    }
                    onChange={(e) => {
                        console.log(directAnw)
                        setDirectAnw({
                            ...directAnw,
                            numberOfAnswer: parseInt(e),
                        })
                        setAnswers({
                            ...directAnw,
                            numberOfAnswer: parseInt(e),
                        })
                    }}
                />
            )}

            {possibleAnswers &&
                possibleAnswers.map((choice) => (
                    <div key={choice.id}>
                        <input
                            disabled={readOnly}
                            onChange={(e) => checkbox(e.target.value)}
                            type={
                                answerType == qType.MULTIPLE
                                    ? 'checkbox'
                                    : 'radio'
                            }
                            value={choice.value}
                            name={number}
                            checked={
                                directAnw?.choiceFromList
                                    ? directAnw.choiceFromList?.findIndex(
                                          (item) => item === choice.value
                                      ) > -1
                                    : false
                            }
                        />
                        <span>{choice.value}</span>
                    </div>
                ))}
            {fields.map((field, index) => (
                <ControlledInput
                    key={index}
                    regex={regex}
                    disabled={readOnly}
                    title={'Réponse ' + field}
                    defaultValue={
                        directAnw?.freeAnswer
                            ? directAnw.freeAnswer[field - 1]
                            : ''
                    }
                    onChange={(e) => {
                        setFreeAnswers(field, e)
                    }}
                />
            ))}
        </div>
    )
}
