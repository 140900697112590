import { useEffect, useState } from 'react'
import axios from '../../../axios/axios'
import '../adminEtablissement.css'

function ActiveYear() {
    const [years, setYears] = useState<{ year: string }[]>([])
    const [selectedYear, setSelectedYear] = useState<string>('')
    useEffect(() => {
        axios.get('/questionnaire/years').then((res) => {
            setYears(res.data)
            // if (selectedYear === '')
            //     setSelectedYear(res.data[res.data.length - 1].year)
            getActiveYear().then((res) => {
                setSelectedYear(res.data.value)
            })
        })
    }, [])

    async function setActiveYear(newYear: string) {
        axios.patch('configurations/ACTIVE_YEAR', { value: newYear })
    }
    async function getActiveYear() {
        return await axios.get('configurations/ACTIVE_YEAR')
    }
    return (
        <>
            <h3>Année active</h3>
            <div className="font-italic text-xs mb-10">
                Indiquez l'année active pour les nouvelles inscriptions, cette
                année sera automatiquement associée aux nouvelles inscriptions.
                <br />
                Afin que l'année apparaisse dans la liste il est nécessaire
                d'avoir créé au moins une étape pour l'année en question.
            </div>
            <div
                style={{
                    flexDirection: 'column',
                    marginTop: 20,
                }}
            >
                {years.map((year) => {
                    return (
                        <button
                            key={year.year}
                            className={
                                year.year == selectedYear
                                    ? 'buttons-blue blue-admin'
                                    : 'buttons-white white-admin'
                            }
                            onClick={() => {
                                setSelectedYear(year.year)
                                setActiveYear('' + year.year)
                            }}
                        >
                            {year.year}
                        </button>
                    )
                })}
            </div>
        </>
    )
}
export default ActiveYear
