import { useEffect, useState } from 'react'

import { toast } from 'react-toastify'
import Input from '../forms/common/Input'
import { ParticipantWithResults } from '../../types/IParticipant'
import axios from '../../axios/axios'
import IQuestionnaire from '../../types/IQuestionnaire'
import { ecategories } from '../../types/enums'
import Select from 'react-select'
import { getCategories } from '../../helpers/getCategories'

export default function AddGradeToUser({
    close,
    trigger,
    participant,
    centerId,
    questionnaireId,
}: {
    close: () => void
    trigger: () => void
    participant: ParticipantWithResults
    centerId?: string
    questionnaireId?: string
    nextQuestionnaire?: IQuestionnaire
}) {
    const [dataToSend, setData] = useState({
        grade: '',
        weightgrade: '',
        time: '',
        category: '',
        genre: (undefined || '') as string | undefined,
    })

    const [catsOptions, setCatsOptions] = useState<
        { value: string; label: string }[]
    >([])
    useEffect(() => {
        getCategories().then((cats) => setCatsOptions(cats))
    }, [])
    useEffect(() => {
        setData({
            grade: participant?.result?.grade + '' || '',
            weightgrade: participant.result?.weightedGrade + '' || '',
            time: participant.result?.time + '' || '',
            category: participant.registrations[0].category.name || '',
            genre: participant.genre || undefined,
        })
    }, [participant])

    return (
        <div className="flex" style={{ flexDirection: 'column' }}>
            <div className="flex-1">
                <span>
                    Modifier la note de {participant.contact.lastname}{' '}
                    {participant.contact.firstname}
                </span>
            </div>
            <form
                className="flex-none"
                id="addGrade"
                onSubmit={(e) => {
                    e.preventDefault()
                    const formElement = document.getElementById(
                        'addGrade'
                    ) as HTMLFormElement
                    console.log('VALID', formElement.checkValidity())
                    if (formElement.checkValidity()) {
                        console.log(dataToSend)

                        // check that weightgrade is >= to n(n+1)/2 with n the grade
                        if (
                            parseInt(dataToSend.grade) !==
                            parseFloat(dataToSend.grade)
                        ) {
                            toast.error('La note doit être un entier')
                            return
                        }
                        if (
                            parseFloat(dataToSend.weightgrade) <
                            (parseFloat(dataToSend.grade) *
                                (parseFloat(dataToSend.grade) + 1)) /
                                2
                        ) {
                            toast.error(
                                'Le coefficient doit être supérieur ou égal à n(n+1)/2 avec n la note'
                            )
                            return
                        }
                        axios.post(
                            `users/${
                                participant.id
                            }/${questionnaireId}/${centerId}/${
                                dataToSend.grade
                            }/${dataToSend.weightgrade}/${parseInt(
                                dataToSend.time
                            )}`,
                            {
                                category: dataToSend.category,
                                genre: dataToSend.genre,
                            }
                        )
                        toast.success('Note ajoutée')
                        trigger()
                        close()
                    } else {
                        toast.error('Veuillez remplir tous les champs')
                        return
                    }
                }}
            >
                <Input
                    type="number"
                    step="1"
                    title="Note"
                    onChange={(e) => {
                        console.log(e.target.value)
                        setData((value) => ({
                            ...value,
                            grade: e.target.value,
                        }))
                    }}
                    value={dataToSend.grade}
                    required
                />
                <Input
                    type="number"
                    step="0.01"
                    title="Coefficient"
                    onChange={(e) => {
                        console.log(e.target.value)
                        setData((value) => ({
                            ...value,
                            weightgrade: e.target.value,
                        }))
                    }}
                    value={dataToSend.weightgrade}
                    required
                />
                <Input
                    type="number"
                    step="1"
                    title="Temps passé (en minutes)"
                    onChange={(e) => {
                        console.log(e.target.value)
                        setData((value) => ({
                            ...value,
                            time: e.target.value,
                        }))
                    }}
                    value={dataToSend.time}
                    required
                />
                <div className="insc-inputs">
                    <span className="inputs-title-insc">Catégorie</span>
                    <Select
                        options={catsOptions}
                        isSearchable={true}
                        placeholder=""
                        onChange={(e) =>
                            setData((value) => ({
                                ...value,
                                category: e?.value as string,
                            }))
                        }
                        value={
                            catsOptions.find(
                                (option) => option.label === dataToSend.category
                            ) || null
                        }
                    />
                </div>
                {/* 2 radio button to set Men/Women */}
                <div className="insc-inputs">
                    <span className="inputs-title-insc">Genre</span>
                    <div className="flex">
                        <div className="flex-none">
                            <input
                                type="radio"
                                name="genre"
                                id="homme"
                                value="homme"
                                checked={dataToSend.genre === 'homme'}
                                onChange={(e) =>
                                    setData((value) => ({
                                        ...value,
                                        genre: e.target.value,
                                    }))
                                }
                            />
                            <label htmlFor="homme">Homme</label>
                        </div>
                        <div className="flex-none">
                            <input
                                type="radio"
                                name="genre"
                                id="femme"
                                value="femme"
                                checked={dataToSend.genre === 'femme'}
                                onChange={(e) =>
                                    setData((value) => ({
                                        ...value,
                                        genre: e.target.value,
                                    }))
                                }
                            />
                            <label htmlFor="femme">Femme</label>
                        </div>
                    </div>
                </div>
                <button
                    className="buttons-blue school-button-border-off"
                    onClick={() => close()}
                    type="button"
                >
                    Annuler
                </button>
                <button
                    className="buttons-blue school-button-border"
                    type="submit"
                >
                    Enregistrer
                </button>
            </form>
        </div>
    )
}
