import { iClubUser, iEstablishementUser } from '../../../types/user'

import ClubInfosForm from './ClubInfosForm'
import ClubContactForm from './ClubContactForm'
import ClubRecapForm from './ClubRecapForm'

function ClubForm({
    active,
    user,
    setUser,
}: {
    active: string
    user: iClubUser
    setUser: (args: iClubUser) => void
}) {
    return (
        <form className="forms-insc" id="formulaires-inscription">
            <h2 className="forms-title-insc">{active}</h2>
            <div className="forms-inputs">
                {active == 'Club' && (
                    <ClubInfosForm setUser={setUser} user={user} />
                )}

                {active == 'Correspondant' && (
                    <ClubContactForm setUser={setUser} user={user} />
                )}

                {active == 'Confirmation' && <ClubRecapForm user={user} />}
            </div>
            <button
                id="Inscription-sub-button"
                type="submit"
                style={{ display: 'none', opacity: 0 }}
                onClick={(e) => e.preventDefault()}
                hidden
            ></button>
        </form>
    )
}
export default ClubForm
