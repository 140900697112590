import Select from 'react-select'

import { iIndividualUser } from '../../../types/user'
import Input from '../common/Input'
import { getCategories } from '../../../helpers/getCategories'
import { useEffect, useState } from 'react'
import { getLanguages } from '../../../helpers/getLanguages'
import axios from '../../../axios/axios'

function getDate(date: Date | undefined) {
    const inputDate = new Date(date ?? '').toLocaleDateString()

    const dateComponents = inputDate.split('/')
    if (dateComponents.length === 3) {
        if (dateComponents[1].length != 2) {
            dateComponents[1] = '0' + dateComponents[1]
        }
        if (dateComponents[0].length != 2) {
            dateComponents[0] = '0' + dateComponents[0]
        }
        const formattedDate = `${dateComponents[2]}-${dateComponents[0]}-${dateComponents[1]}`
        return formattedDate
    }
}

function IndividualInfosForm({
    user,
    setUser,
}: {
    user: iIndividualUser
    setUser: (args: iIndividualUser) => void
}) {
    const [clubOptions, setClubOptions] = useState([
        { value: '0', label: 'Aucun club trouvé', id: 'x' },
    ])
    const [catsOptions, setCatsOptions] = useState<
        { value: string; label: string }[]
    >([])
    const [langsOptions, setLangsOptions] = useState<
        { value: string; label: string }[]
    >([])
    useEffect(() => {
        getCategories().then((cats) => setCatsOptions(cats))
        getLanguages().then((langs) => setLangsOptions(langs))
        axios
            .get(`/clubs/inscription`)
            .then((res) => {
                setClubOptions(() =>
                    res.data.map(
                        (e: { name: string; id: string; city: string }) => {
                            return {
                                value: e.id,
                                label: `${e.name} (${e.city})`,
                                id: e.id,
                            }
                        }
                    )
                )
            })
            .catch()
    }, [])

    return (
        <>
            <Input
                value={user.contact.lastname}
                title="Nom"
                onChange={(e) => {
                    setUser({
                        ...user,
                        contact: {
                            ...user.contact,
                            lastname: e.target.value,
                        },
                    })
                }}
                defaultValue={user.contact.lastname}
                required
            />
            <Input
                value={user.contact.firstname}
                title="Prénom"
                onChange={(e) => {
                    setUser({
                        ...user,
                        contact: {
                            ...user.contact,
                            firstname: e.target.value,
                        },
                    })
                }}
                defaultValue={user.contact.firstname}
                required
            />
            <Input
                value={undefined}
                title="Date de naissance"
                type="date"
                onChange={(e) => {
                    setUser({
                        ...user,
                        birthday: new Date(e.target.value),
                    })
                }}
                defaultValue={getDate(user.birthday as Date) ?? ''}
                required
            />
            {/* <Input
                value={user.numFFJM}
                title="Numéro FFJM"
                onChange={(e) => {
                    setUser({
                        ...user,
                        numFFJM: e.target.value,
                    })
                }}
                defaultValue={user.numFFJM}
            /> */}

            <div className="insc-inputs">
                <span className="inputs-title-insc required">Catégorie</span>
                <Select
                    options={catsOptions}
                    isSearchable={true}
                    placeholder=""
                    onChange={(e) => {
                        //console.log(e?.value as ecategories)
                        setUser({
                            ...user,
                            categoryName: e?.value,
                        })
                    }}
                    value={
                        catsOptions.find(
                            (option) => option.value === user.categoryName
                        ) || null
                    }
                    required
                />
            </div>
            <div className="insc-inputs">
                <span className="inputs-title-insc required">
                    Langue de participation
                </span>
                <Select
                    options={langsOptions}
                    isSearchable={true}
                    placeholder=""
                    onChange={(e) =>
                        setUser({
                            ...user,
                            languageName: e?.value,
                        })
                    }
                    value={
                        langsOptions.find(
                            (option) => option.label === user.languageName
                        ) || null
                    }
                    required
                />
            </div>
            <div className="insc-inputs">
                <span className="inputs-title-insc required">Genre</span>
                <div className="flex" style={{ gap: 10 }}>
                    <div
                        className="flex "
                        style={{ gap: 10, alignItems: 'center' }}
                    >
                        <input
                            type="radio"
                            name="genre"
                            id="homme"
                            value="homme"
                            required
                            onChange={(e) =>
                                setUser({
                                    ...user,
                                    genre: 'homme',
                                })
                            }
                            checked={user.genre === 'homme'}
                        />
                        <label htmlFor="homme">Homme</label>
                    </div>
                    <div
                        className="flex"
                        style={{ gap: 10, alignItems: 'center' }}
                    >
                        <input
                            type="radio"
                            name="genre"
                            id="femme"
                            value="femme"
                            required
                            onChange={(e) =>
                                setUser({
                                    ...user,
                                    genre: 'femme',
                                })
                            }
                            checked={user.genre === 'femme'}
                        />
                        <label htmlFor="femme">Femme</label>
                    </div>
                </div>
            </div>
            <div className="insc-inputs">
                <span className="inputs-title-insc">
                    Club (si adhérent à un club)
                </span>
                <Select
                    options={clubOptions}
                    isSearchable={true}
                    isClearable={true}
                    placeholder=""
                    onChange={(e) => {
                        setUser({
                            ...user,
                            club: { id: e?.value },
                        })
                    }}
                    value={
                        clubOptions.find(
                            (option) => option.value === user.club?.id
                        ) || null
                    }
                />
            </div>
        </>
    )
}
export default IndividualInfosForm
