import { useState } from 'react'
import './modal.css'
import faqjson from '../fixture/faq.json'
//eslint-disable-next-line
function Faq() {
    const questions: {
        [key: string]: {
            question: string
            answer: string
        }[]
    } = faqjson
    const [shown, setShown] = useState<string>(Object.keys(questions)[0])
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 20,
                maxWidth: '90vw',
                height: '90vh',
                overflowY: 'scroll',
            }}
        >
            <div>
                {Object.keys(questions).map((value) => (
                    <button
                        className={
                            value === shown
                                ? 'buttons-blue school-button-border-off school-button-border-active'
                                : 'buttons-blue school-button-border-off '
                        }
                        onClick={() => setShown(value)}
                    >
                        {value}
                    </button>
                ))}
            </div>
            {questions[shown]
                ? questions[shown].map((question) => {
                      return (
                          <div
                              key={question.question}
                              style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 10,
                              }}
                          >
                              <div style={{ textAlign: 'justify' }}>
                                  <strong>{question.question}</strong>
                              </div>
                              <div style={{ textAlign: 'justify' }}>
                                  {question.answer}
                              </div>
                          </div>
                      )
                  })
                : ''}
        </div>
    )
}

export default Faq
