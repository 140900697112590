import { useNavigate } from 'react-router-dom'
import './navbarAdmin.css'
import authService from '../services/AuthService'
import { useContext } from 'react'

export type NavbarType =
    | 'questions'
    | 'questionnaires'
    | 'reponses'
    | 'etablissements'
    | 'centresExamen'
    | 'participants'
    | 'exports'
    | 'configuration'
    | 'users'
    | 'clubs'

function NavbarAdmin(props: { active: NavbarType }) {
    const navigate = useNavigate()

    return (
        <div id="navbar-admin">
            <button
                className={
                    props.active == 'configuration'
                        ? 'buttons-blue blue-admin'
                        : 'buttons-white white-admin'
                }
                onClick={() => navigate('/admin/configuration')}
            >
                Configuration
            </button>
            <button
                className={
                    props.active == 'questionnaires'
                        ? 'buttons-blue blue-admin'
                        : 'buttons-white white-admin'
                }
                onClick={() => navigate('/admin/questionnaire')}
            >
                Étapes
            </button>
            <button
                className={
                    props.active == 'clubs'
                        ? 'buttons-blue blue-admin'
                        : 'buttons-white white-admin'
                }
                onClick={() => navigate('/admin/clubs')}
            >
                Clubs
            </button>
            <button
                className={
                    props.active == 'etablissements'
                        ? 'buttons-blue blue-admin'
                        : 'buttons-white white-admin'
                }
                onClick={() => navigate('/admin/etablissements')}
            >
                Établissements
            </button>

            <button
                className={
                    props.active == 'centresExamen'
                        ? 'buttons-blue blue-admin'
                        : 'buttons-white white-admin'
                }
                onClick={() => navigate('/admin/centres')}
            >
                Centres d'épreuve
            </button>

            <button
                className={
                    props.active == 'participants'
                        ? 'buttons-blue blue-admin'
                        : 'buttons-white white-admin'
                }
                onClick={() => navigate('/admin/participants')}
            >
                Participants
            </button>
            <button
                className={
                    props.active == 'users'
                        ? 'buttons-blue blue-admin'
                        : 'buttons-white white-admin'
                }
                onClick={() => navigate('/admin/users')}
            >
                Utilisateurs
            </button>
            <button
                className="buttons-white white-admin"
                onClick={() => {
                    authService.logout()
                    navigate('/Connexion')
                }}
            >
                Se déconnecter
            </button>
        </div>
    )
}
export default NavbarAdmin
