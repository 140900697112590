import { iIndividualUser } from '../../../types/user'

function IndividualAuthorisationForm({
    user,
    setUser,
}: {
    user: iIndividualUser
    setUser: (args: iIndividualUser) => void
}) {
    return (
        <>
            <div style={{ gridArea: 'none' }}>
                <div className="insc-inputs-checkbox required">
                    <input
                        className="chexkbox-insc"
                        onChange={(e) => {
                            setUser({
                                ...user,
                                imageCapture: !user.imageCapture,
                            })
                        }}
                        checked={user.imageCapture}
                        type="checkbox"
                        required
                    />
                    <span className="inputs-title-insc">
                        J'accepte (ou mon responsable légal accepte.) que mon
                        image soit utilisée par la FFJM à des fins de
                        communication.
                    </span>
                </div>
                <div className="insc-inputs-checkbox">
                    <input
                        className="chexkbox-insc"
                        type="checkbox"
                        required
                        onChange={(e) => {
                            setUser({
                                ...user,
                                communicationFFJM: !user.communicationFFJM,
                            })
                        }}
                        checked={user.communicationFFJM}
                    />

                    <span className="inputs-title-insc required">
                        J'accepte de recevoir des communications de la FFJM par
                        mail (indispensable pour le championnat)
                    </span>
                </div>
                <div className="insc-inputs-checkbox ">
                    <input
                        className="chexkbox-insc"
                        onChange={(e) => {
                            setUser({
                                ...user,
                                communication: !user.communication,
                            })
                        }}
                        type="checkbox"
                        checked={user.communication}
                    />
                    <span className="inputs-title-insc">
                        J'accepte de recevoir des communications des partenaires
                        de la FFJM
                    </span>
                </div>
            </div>
        </>
    )
}
export default IndividualAuthorisationForm
