import React, { SetStateAction, useEffect, useState } from 'react'
import { iRegistration, iResult } from '../types/user'
import axios from '../axios/axios'
import { toast } from 'react-toastify'
import UserModificationConvocation from './forms/UserModificationConvocation'
import DetailRegistration from './DetailRegistration'
import { getCategories } from '../helpers/getCategories'

interface EditRegistrationsOfUserProps {
    userId: string
}

const EditRegistrationsOfUser: React.FC<EditRegistrationsOfUserProps> = ({
    userId,
}) => {
    // Add your component logic here

    const [registrations, setRegistrations] = useState<iRegistration[] | null>(
        null
    )
    const [selectedRegistration, setSelectedRegistration] =
        useState<iRegistration>()
    const [adminFunctionShown, setAdminFunctionShown] = useState(false)
    const [triggerEffect, setTriggerEffect] = useState(false)
    const [results, setResults] = useState<iResult[] | null>(null)
    const [examCentersOptions, setExamCentersOptions] = useState<
        { value: string; label: string }[] | null
    >(null)
    const [catsOptions, setCatsOptions] = useState<
        { value: string; label: string }[]
    >([])
    useEffect(() => {
        getCategories().then((cats) => setCatsOptions(cats))
    }, [])
    useEffect(() => {
        axios
            .get(`/registrations/user/${userId}`)
            .then((res) => {
                console.log(res.data)
                setRegistrations(res.data)
            })
            .catch((e) =>
                toast.error('Erreur lors de la récupération des inscriptions')
            )
        axios
            .get('/examcenter?limit=0')
            .then((res) => {
                const options: SetStateAction<
                    { value: string; label: string }[] | null
                > = []
                res.data.data.forEach(
                    (element: { id: string; name: string; city: string }) => {
                        options.push({
                            value: element.id,
                            label: element.name + ', ' + element.city,
                        })
                    }
                )
                setExamCentersOptions(options)
            })
            .catch((error) => {
                console.error(error)
            })
    }, [userId])

    // when the selected year changes, we fetch the results for the selected year
    useEffect(() => {
        if (selectedRegistration) {
            axios
                .get(
                    `/results/user/${userId}/year/${selectedRegistration.year}`
                )
                .then((res) => {
                    console.log('RES', res.data)
                    setResults(res.data)
                })
                .catch((e) =>
                    toast.error('Erreur lors de la récupération des résultats')
                )
        }
    }, [selectedRegistration])
    return (
        <div
            style={{
                background: 'lightgrey',
                paddingInline: 10,
                borderRadius: 10,
                paddingBottom: 10,
            }}
        >
            <h2 style={{ paddingTop: 10 }}>Gestion du championnat</h2>
            <div>
                {(!registrations || registrations.length === 0) && (
                    <>
                        Cet utilisateur n'est inscrit à aucune session. Ceci est
                        normal s'il est responsable de centre d'épreuve.
                    </>
                )}
                {registrations &&
                    registrations.map((registration) => {
                        return (
                            <button
                                type="button"
                                className={
                                    registration.year ==
                                    selectedRegistration?.year
                                        ? 'buttons-blue blue-admin'
                                        : 'buttons-white white-admin'
                                }
                                onClick={() => {
                                    setSelectedRegistration(registration)
                                }}
                            >
                                {registration.year}
                            </button>
                        )
                    })}
            </div>
            <hr />
            {selectedRegistration && (
                <div style={{ marginTop: 10 }}>
                    <DetailRegistration
                        registration={selectedRegistration}
                        categories={catsOptions}
                    />
                </div>
            )}
            {results && (
                <div style={{ marginTop: 10 }}>
                    {results.map((result) => (
                        <div key={result.questionnaireId}>
                            <UserModificationConvocation
                                result={result}
                                userId={userId}
                                examCentersOptions={examCentersOptions}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default EditRegistrationsOfUser
