import { iEstablishementUser } from '../../../types/user'
import Input from '../common/Input'

function EtablishementContactForm({
    user,
    setUser,
}: {
    user: iEstablishementUser
    setUser: (args: iEstablishementUser) => void
}) {
    return (
        <>
            <Input
                title="Nom"
                defaultValue={user.contact.lastname}
                value={user.contact.lastname}
                onChange={(e) => {
                    setUser({
                        ...user,
                        contact: {
                            ...user.contact,
                            lastname: e.target.value,
                        },
                    })
                }}
                required
            />
            <Input
                title="Prénom"
                defaultValue={user.contact.firstname}
                value={user.contact.firstname}
                onChange={(e) => {
                    setUser({
                        ...user,
                        contact: {
                            ...user.contact,
                            firstname: e.target.value,
                        },
                    })
                }}
                required
            />
            <Input
                title="Courriel"
                defaultValue={user.contact.email}
                value={user.contact.email}
                type="email"
                onChange={(e) => {
                    setUser({
                        ...user,
                        contact: {
                            ...user.contact,
                            email: e.target.value,
                        },
                    })
                }}
                required
            />
            <Input
                title="Téléphone"
                defaultValue={user.contact.phone}
                value={user.contact.phone}
                type="tel"
                onChange={(e) => {
                    setUser({
                        ...user,
                        contact: {
                            ...user.contact,
                            phone: e.target.value,
                        },
                    })
                }}
                required
            />
        </>
    )
}

export default EtablishementContactForm
