import { iEstablishementUser } from '../../../types/user'
import Input from '../common/Input'

function EtablishementRecapForm({ user }: { user: iEstablishementUser }) {
    return (
        <>
            <Input
                title="Nom de l'établissement"
                defaultValue={user.establishment.name}
                disabled
            />
            <Input
                title="Code RNE"
                defaultValue={user.establishment.rneCode}
                disabled
            />

            <Input
                title="Type"
                defaultValue={user.establishment.type}
                disabled
            />
            <Input
                title="Adresse"
                defaultValue={user.establishment.street}
                disabled
            />
            <Input
                title="Ville"
                defaultValue={user.establishment.city}
                disabled
            />
            <Input
                title="Code postal"
                error={
                    user.contact.zip_code && user.contact.zip_code.length !== 5
                        ? 'Le code postal doit contenir 5 chiffres'
                        : ''
                }
                defaultValue={user.establishment.zipCode}
                disabled
            />
            <Input
                title="Pays"
                defaultValue={user.establishment.country}
                disabled
            />
            <Input
                title="Nom du correspondant"
                defaultValue={user.contact.lastname}
                disabled
            />
            <Input
                title="Prénom du correspondant"
                defaultValue={user.contact.firstname}
                disabled
            />
            <Input
                title="Courriel du correspondant"
                defaultValue={user.contact.email}
                disabled
            />
            <Input
                title="Téléphone du correspondant"
                defaultValue={user.contact.phone}
                disabled
            />
        </>
    )
}

export default EtablishementRecapForm
