import { useEffect, useState } from 'react'
import Select from 'react-select'
import { ExamCenter } from '../../pages/admin/AdminExam'
import { TypeEstablishementOptions } from '../../pages/inscription/Inscription'
import { eestablishements } from '../../types/enums'
import {
    iEstablishementWContact,
    iEstablishment,
    iSchoolEditUser,
} from '../../types/user'
import Input from '../forms/common/Input'
import Modal from '../Modal'
import UpdateUser from './UpdateUser'
import axios from '../../axios/axios'
import { toast } from 'react-toastify'

export default function UpdateEstablishment({
    close,
    confirm,
    initialValue,
}: {
    close: () => void
    confirm: (dataToSend: {
        name: string
        type: eestablishements
        rneCode: string
        street: string
        city: string
        zipCode: string
        country: string
        estimatedParticipants: number
    }) => void
    initialValue: iEstablishementWContact
}) {
    const [ModalActive, setModalActive] = useState(false)
    const [modalType, setModalType] = useState<JSX.Element | null>(null)

    const [dataToSend, setDataToSend] = useState<{
        name: string
        type: eestablishements
        rneCode: string
        street: string
        city: string
        zipCode: string
        country: string
        estimatedParticipants: number
    }>()
    useEffect(() => {
        setDataToSend({
            name: initialValue.name,
            type: initialValue.type,
            rneCode: initialValue.rneCode,
            street: initialValue.street,
            city: initialValue.city,
            zipCode: initialValue.zipCode,
            country: initialValue.country,
            estimatedParticipants: initialValue.estimatedParticipants,
        })
    }, [initialValue])
    function editAssociatedUser(participant: iSchoolEditUser) {
        setModalType(
            <>
                <UpdateUser
                    close={() => setModalActive(false)}
                    confirm={async () => {
                        setModalActive(false)
                    }}
                    initialValue={participant}
                    from="adminEtablissement"
                />
            </>
        )
        setModalActive(true)
    }
    const [showButton, setShowButton] = useState(true)

    function changeToExamCenter(newExamCenter: iEstablishementWContact) {
        const requestBody = {
            name: newExamCenter?.name,
            rneCode: newExamCenter?.rneCode,
            street: newExamCenter?.street,
            city: newExamCenter?.city,
            zipCode: newExamCenter?.zipCode,
            country: newExamCenter?.country,
            seats: 0,
            typeCenter: {
                year: 2023,
                center: 0,
            },
        } as ExamCenter

        console.log(requestBody)
        // alert('Fonctionnalité désactivée - En cours de développement')

        axios
            .post(`/examcenter/${newExamCenter.user.id}`, requestBody)
            .then((res: { data: unknown }) => {
                console.log(res.data)
                toast.success(
                    "Centre d'épreuve créé à partir de l'établissement"
                )
                setShowButton(false)
            })
            .catch((e) => {
                console.error(e)
                toast.error(
                    "Erreur lors de la création du centre d'épreuve, vérifiez qu'il n'existe pas déjà"
                )
            })
        setModalActive(false)
    }

    if (!dataToSend) return <>Chargement</>

    return (
        <div style={{ width: '80vw' }}>
            <h1>Modifier cet Établissement</h1>

            <button
                className="buttons-blue buttons-blue blue-admin"
                onClick={() => editAssociatedUser(initialValue.user)}
            >
                Modifier l'utilisateur associé
            </button>
            {!showButton ||
            (initialValue?.user?.controlledCenter &&
                initialValue?.user?.controlledCenter?.length > 0) ? (
                ''
            ) : (
                <button
                    className="buttons-blue buttons-blue blue-admin"
                    onClick={() =>
                        window.confirm(
                            "Êtes vous sûr de vouloir créer un centre d'épreuve a partir des informations de cet établissement ? Cette action est irréversible."
                        )
                            ? changeToExamCenter(initialValue)
                            : null
                    }
                >
                    Convertir en centre d'épreuve
                </button>
            )}
            <form>
                <div className="add-modal modal-content-scrollable">
                    <Input
                        title="Nom"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                name: e.target.value,
                            })
                        }
                        value={dataToSend.name}
                        required
                    />
                    <div className="insc-inputs">
                        <span className="inputs-title-insc">Type</span>
                        <Select
                            options={TypeEstablishementOptions}
                            isSearchable={true}
                            placeholder=""
                            onChange={(e) =>
                                setDataToSend({
                                    ...dataToSend,
                                    type: e
                                        ? (e.value as eestablishements)
                                        : eestablishements.COLLEGE,
                                })
                            }
                            value={
                                TypeEstablishementOptions.find(
                                    (option) => option.label === dataToSend.type
                                ) || null
                            }
                            name="type"
                            required
                        />
                    </div>
                    <Input
                        title="RNE"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                rneCode: e.target.value,
                            })
                        }
                        value={dataToSend.rneCode}
                        required
                    />

                    <Input
                        title="Adresse"
                        type="text"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                street: e.target.value,
                            })
                        }
                        value={dataToSend?.street}
                    />
                    <Input
                        title="Ville"
                        type="text"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                city: e.target.value,
                            })
                        }
                        value={dataToSend.city}
                    />
                    <Input
                        title="Code Postal"
                        type="text"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                zipCode: e.target.value,
                            })
                        }
                        value={dataToSend.zipCode}
                        error={
                            dataToSend.zipCode &&
                            dataToSend.zipCode.length !== 5
                                ? 'Le code postal doit contenir 5 chiffres'
                                : ''
                        }
                    />
                    <Input
                        title="Pays"
                        type="text"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                country: e.target.value,
                            })
                        }
                        value={dataToSend.country}
                    />
                    <Input
                        title="Nombre estimé de Participants"
                        type="number"
                        onChange={(e) =>
                            setDataToSend({
                                ...dataToSend,
                                estimatedParticipants: parseInt(e.target.value),
                            })
                        }
                        value={dataToSend.estimatedParticipants}
                    />
                </div>
                <div className="buttons-modal-multiple">
                    <button
                        className="buttons-border-off admin-border-off"
                        onClick={close}
                    >
                        Annuler
                    </button>
                    <button
                        className="buttons-blue buttons-blue blue-admin"
                        onClick={() => {
                            if (
                                dataToSend?.name != undefined ||
                                dataToSend.rneCode != undefined
                            ) {
                                confirm(dataToSend)
                            } else {
                                console.log('Données non conformes')
                            }
                        }}
                    >
                        Confirmer
                    </button>
                </div>
            </form>
            <Modal
                isActive={ModalActive}
                setActive={setModalActive}
                children={modalType}
            ></Modal>
        </div>
    )
}
