import React, { useEffect, useState } from 'react'
import ParticipantLayout from '../layouts/ParticipantLayout'
import {
    iClub,
    iClubUser,
    iRegistrationWithUser,
    iUser,
} from '../../types/user'
import Table, { PageObject } from '../../components/Table'
import { typeFilterFormat } from '../../types/IfilterFormat'
import constClubParticipant from '../../types/tables/constClubParticipant'
import { getUserRegistrationClub } from '../../helpers/getUserRegistrationClub'
import axios from '../../axios/axios'
import { modifyModal } from '../admin/actionModals/modifyModal'
import { eStatus } from '../../types/enums'
import Modal from '../../components/Modal'

const ClubPageMembers: React.FC = () => {
    const [pages, setPages] = useState<PageObject>({
        pages: 1,
        actual: 1,
    })
    const [triggerEffect, setTriggerEffect] = useState(false)
    const [modalActive, setModalActive] = useState(false)
    const [modalContent, setModalContent] = useState<
        JSX.Element | string | null
    >(null)

    const [clubs, setClubs] = useState<iClub[]>([])
    const [selectedClubId, setSelectedClubId] = useState<string>()
    const [selectedYear, setSelectedYear] = useState<number>()
    const [users, setUsers] = useState<iRegistrationWithUser[]>([])
    const [selectedSession, setSelectedSession] =
        useState<typeFilterFormat>(null)
    const pagination = (page: number) => {
        setPages({
            pages: pages.pages,
            actual: page,
        })
    }

    useEffect(() => {
        axios.get('/users/me').then((res: { data: iUser }) => {
            setClubs(res.data.controlledClubs || [])
            const controlledCLubs = res.data.controlledClubs || []
            if (controlledCLubs?.length > 0) {
                setSelectedClubId(controlledCLubs[0].id)
                setSelectedYear(controlledCLubs[0].registrations?.[0]?.year)
            }
        })
    }, [])

    useEffect(() => {
        if (selectedClubId && selectedYear) {
            getUserRegistrationClub(
                pages,
                selectedClubId,
                selectedYear,
                null,
                null,
                selectedSession
            ).then((res) => {
                console.log(res.data.data)
                setUsers(res.data.data)
                if (pages.actual !== res.data.meta.currentPage)
                    setPages({
                        pages: res.data.meta.totalPages,
                        actual: res.data.meta.currentPage,
                    })
            })
        }
    }, [pages, selectedClubId, selectedSession, selectedYear, triggerEffect])

    function detachRegistrationFromClub(reg: iRegistrationWithUser) {
        console.log(reg)
        setModalContent(
            <>
                <span>
                    Êtes vous sûr de vouloir détacher ce participant de votre
                    club?
                </span>
                <div className="buttons-modal-multiple">
                    <button
                        className="buttons-border-off school-button-border-off"
                        onClick={() => setModalActive(false)}
                    >
                        Annuler
                    </button>
                    <button
                        className="buttons-blue school-button"
                        onClick={async () => detachRegistration(reg)}
                    >
                        Confirmer
                    </button>
                </div>
            </>
        )
        setModalActive(true)
    }
    function detachRegistration(reg: iRegistrationWithUser) {
        console.log(reg)
        axios
            .delete('/registrations/' + reg.id)
            .then((res) => {
                console.log(res.data)
            })
            .then(() => {
                setModalActive(false)
                setTriggerEffect(!triggerEffect)
            })
    }
    function validateRegistration(reg: iRegistrationWithUser) {
        axios
            .get('/registrations/validate/' + reg.id)
            .then((res) => {
                console.log(res.data)
            })
            .then(() => {
                setModalActive(false)
                setTriggerEffect(!triggerEffect)
            })
    }
    function confirmRegistration(reg: iRegistrationWithUser) {
        console.log(reg)
        setModalContent(
            <>
                <span>
                    Êtes vous sûr de vouloir confirmer la validation de ce
                    membre ?
                </span>
                <div className="buttons-modal-multiple">
                    <button
                        className="buttons-border-off school-button-border-off"
                        onClick={() => setModalActive(false)}
                    >
                        Annuler
                    </button>
                    <button
                        className="buttons-blue school-button"
                        onClick={async () => validateRegistration(reg)}
                    >
                        Confirmer
                    </button>
                </div>
            </>
        )
        setModalActive(true)
    }
    return (
        <ParticipantLayout
            color="red"
            headerButtons={[
                {
                    title: 'Informations Club',
                    link: '/club/info',
                },
                {
                    title: 'Adhérents',
                    link: '/club/participants',
                },
            ]}
        >
            <div>
                <h1>Espace Club - Adhérents</h1>
                <p>
                    Ici vous pourrez voir la liste des participants s'étant
                    inscrits en indiquant votre club sur chaque championnat.
                    Vous pouvez valider l'adhésion de chaque participant afin
                    que sa participation soit prise en compte pour le
                    championnat de l'année indiquée. Si un participant n'est pas
                    membre de votre club, merci de le supprimer de la liste.
                </p>
                <div className="page-body">
                    <div className="flex gap-10">
                        <div>
                            <label>Club</label>
                            <select
                                onChange={(e) => {
                                    setSelectedClubId(e.target.value)
                                }}
                                value={selectedClubId}
                            >
                                {clubs.map((club) => (
                                    <option value={club.id}>{club.name}</option>
                                ))}
                            </select>
                        </div>
                        {clubs && selectedClubId && (
                            <div>
                                <label>Année</label>
                                <select
                                    onChange={(e) => {
                                        setSelectedYear(
                                            parseInt(e.target.value)
                                        )
                                    }}
                                    value={selectedYear}
                                >
                                    {clubs
                                        .find(
                                            (club) => club.id === selectedClubId
                                        )
                                        ?.registrations?.map((registration) => (
                                            <option value={registration.year}>
                                                {registration.year}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        )}
                    </div>
                    <Table
                        titles={
                            selectedSession && selectedSession.value !== ''
                                ? [
                                      ...constClubParticipant,
                                      {
                                          name: 'Note',
                                          isSortable: false,
                                          filters: null,
                                          index: 'grade',
                                      },
                                      {
                                          name: 'Coefficient',
                                          isSortable: false,
                                          filters: null,
                                          index: 'weightedGrade',
                                      },
                                      {
                                          name: "Centre d'épreuve",
                                          isSortable: false,
                                          filters: null,
                                          index: 'examCenter.display',
                                      },
                                  ]
                                : constClubParticipant
                        }
                        data={users}
                        color="--rose-vif100"
                        pagination={pagination}
                        filter={() => {}}
                        page={pages}
                        actions={{
                            // modify: {
                            //     action: (participant) =>
                            //         modifyModal(
                            //             participant,
                            //             setModalContent,
                            //             setModalActive,
                            //             setTriggerEffect,
                            //             triggerEffect,
                            //             'school'
                            //         ),
                            // },
                            delete: {
                                action: (reg) =>
                                    detachRegistrationFromClub(reg),
                                showCondition: (registration: iUser) =>
                                    registration.status === eStatus.PENDING,
                            },
                            confirm: {
                                action: (reg) => confirmRegistration(reg),
                                showCondition: (
                                    registration: iRegistrationWithUser
                                ) => registration.status === eStatus.PENDING,
                            },
                        }}
                        margin="150"
                    />
                </div>
            </div>
            <Modal
                isActive={modalActive}
                setActive={() => {
                    setModalActive(false)
                    setTimeout(() => setTriggerEffect(!triggerEffect), 500)
                }}
            >
                {modalContent}
            </Modal>
        </ParticipantLayout>
    )
}

export default ClubPageMembers
