import { useEffect, useState } from 'react'
import axios from '../../../axios/axios'
import '../adminEtablissement.css'
import Input from '../../../components/forms/common/Input'
import { toast } from 'react-toastify'

function InscriptionsDates() {
    const [loading, setLoading] = useState(true)
    const [parameters, setParameters] = useState([
        {
            name: 'ACTIVE_REGISTRATION_INDIVIDUAL',
            value: '',
            descriptipn: 'Inscription individuelle',
        },
        {
            name: 'ACTIVE_REGISTRATION_STUDENT',
            value: '',
            descriptipn:
                "Inscription étudiant, après cette date il ne pourra plus s'inscrire et les établissements ne pourront plus inscrire/valider d'étudiants",
        },
        {
            name: 'ACTIVE_REGISTRATION_SCHOOL',
            value: '',
            descriptipn: 'Inscription établissement',
        },
        {
            name: 'ACTIVE_REGISTRATION_CLUB',
            value: '',
            descriptipn: 'Inscription club',
        },
    ])
    useEffect(() => {
        for (const param of parameters) {
            getParameter(param.name).then((res) => {
                setParameters((val) =>
                    val.map((p) => {
                        if (p.name === param.name) {
                            return { ...p, value: res.data.value }
                        }
                        return p
                    })
                )
                console.log(parameters)
            })
            setLoading(false)
        }
    }, [])

    async function setParameter(parameter: string, value: string) {
        axios
            .patch(`configurations/${parameter}`, { value: value })
            .then(() => {
                toast.success('Paramètre enregistré')
            })
    }
    async function getParameter(parameter: string) {
        return await axios.get(`configurations/${parameter}`)
    }

    function formatDateInUTC2(date: Date) {
        // Helper function to add leading zeros
        const pad = (num: number) => (num < 10 ? '0' : '') + num

        date.setHours(date.getHours() + 2)

        // Extract components
        const year = date.getUTCFullYear()
        const month = pad(date.getUTCMonth() + 1) // getUTCMonth() is zero-based
        const day = pad(date.getUTCDate())
        const hours = pad(date.getUTCHours())
        const minutes = pad(date.getUTCMinutes())

        // Format the date string
        return `${year}-${month}-${day}T${hours}:${minutes}`
    }

    return (
        <>
            <h3>Dates Inscriptions</h3>
            <div className="font-italic text-xs mb-10">
                Les dates d'inscriptions permettent de fermer et d'ouvrir les
                inscriptions par type d'utilisateur.
                <br />
                La gestion des inscriptions par catégorie se fait dans la page
                "Catégories" avec le paramètre "Inscription"
                <br />
                Pour qu'un utilisateur puisse s'inscrire, il doit le faire avant
                la date indiqué et pendant que l'inscription est ouverte pour la
                catégorie à laquelle il appartient.
            </div>
            {loading ? (
                <span>Chargement...</span>
            ) : (
                <div
                    style={{
                        flexDirection: 'column',
                        marginTop: 20,
                    }}
                >
                    {parameters.map((param) => {
                        let date = param.value
                            ? formatDateInUTC2(new Date(param.value))
                            : formatDateInUTC2(new Date())
                        const dateObj = new Date(date)
                        return (
                            <div key={param.name}>
                                <span>{param.descriptipn}</span>
                                <Input
                                    type="datetime-local"
                                    value={date}
                                    onChange={(e) => {
                                        date = e.target.value

                                        setParameters((val) =>
                                            val.map((p) => {
                                                if (p.name === param.name) {
                                                    return { ...p, value: date }
                                                }
                                                return p
                                            })
                                        )
                                    }}
                                />
                                <button
                                    className="buttons-blue blue-admin"
                                    onClick={() => {
                                        setParameter(
                                            param.name,
                                            formatDateInUTC2(new Date(date))
                                        )
                                    }}
                                >
                                    Enregistrer
                                </button>
                            </div>
                        )
                    })}
                </div>
            )}
        </>
    )
}
export default InscriptionsDates
