import { useEffect, useState } from 'react'
import NavbarAdmin from '../../components/NavbarAdmin'
import './adminParticipant.css'
import Table, { PageObject } from '../../components/Table'
import axios from '../../axios/axios'
import Modal from '../../components/Modal'
import { iUser } from '../../types/user'
import Select from 'react-select'

import IQuestionnaire from '../../types/IQuestionnaire'
import constAdminUsers from '../../types/tables/constAdminUsers'
import { modifyModal } from './actionModals/modifyModal'

function AdminUsers() {
    const [triggerEffect, setTriggerEffect] = useState(false)
    const [filteredData, setFilteredData] = useState<iUser[] | null>(null)
    const [data, setData] = useState<iUser[] | null>(null)
    const [search, setSearch] = useState('')
    const [ModalActive, setModalActive] = useState(false)
    const [modalType, setModalType] = useState<JSX.Element | null>(null)
    const [pages, setPages] = useState<PageObject>({
        pages: 1,
        actual: 1,
    })

    const filterOptions = [
        { value: 'Aucun filtre', label: 'Aucun filtre' },
        { value: 'contact.lastname', label: 'Nom' },
        {
            value: 'contact.firstname',
            label: 'Prénom',
        },
        {
            value: 'zip_code',
            label: 'Code postal',
        },
    ]

    const [filterPrimary, setFilterPrimary] = useState<{
        value: string
        label: string
    } | null>(null)
    const [filterSecondary, setFilterSecondary] = useState<{
        value: string
        label: string
    } | null>(null)
    const [questionnaires, setQuestionnaires] = useState<IQuestionnaire[]>([])
    const [actionQuestionnaire, setActionQuestionnaire] = useState<{
        value: string
        label: string
    } | null>(null)
    const Pagination = (page: number) => {
        setPages({
            pages: pages.pages,
            actual: page,
        })
    }

    const deleteUser = async (id: string) => {
        await axios.delete('/users/' + id).then((res) => {
            console.log(res.data)
        }).catch
        setModalActive(false)
        setTimeout(() => setTriggerEffect(!triggerEffect), 500)
    }

    useEffect(() => {}, [(document.title = 'Utilisateurs')])

    useEffect(() => {
        axios
            .get(
                '/users/all?limit=10&page=' +
                    (pages.actual ?? '1') +
                    (search !== '' ? '&search=' + search : '') +
                    (filterPrimary
                        ? '&sortBy=' + filterPrimary.value + ':ASC'
                        : '') +
                    (filterSecondary
                        ? '&sortBy=' + filterSecondary.value + ':ASC'
                        : '')
            )
            .then((res) => {
                setPages({
                    pages: res.data.meta.totalPages,
                    actual:
                        res.data.meta.totalPages >= res.data.meta.currentPage
                            ? res.data.meta.currentPage
                            : res.data.meta.totalPages,
                })
                setData(res.data.data)
                console.log(res.data.data)
                setFilteredData(
                    res.data.data.map((user: iUser) => ({
                        ...user,
                    }))
                )
            }).catch
    }, [pages.actual, triggerEffect, search, filterPrimary, filterSecondary])

    useEffect(() => {
        axios.get('/questionnaire/').then((res) => {
            setQuestionnaires(res.data)
            setActionQuestionnaire({
                value: res.data[0].id,
                label: res.data[0].name,
            })
        })
    }, [])

    return (
        <>
            <div className="admin-container">
                <div className="navbar">
                    <div className="nav-title">Utilisateurs</div>
                    <NavbarAdmin active="users" />
                </div>
                <div className="page-body">
                    <div className="font-italic text-xs mb-10">
                        Ici vous retrouverez l'ensemble des comptes utilisateurs
                        de la plateforme. C'est-à-dire l'ensemble des comptes
                        qui peuvent se connecter (dont les référents des
                        établissements et de centres, les administrateurs et les
                        participants des différentes années).
                    </div>
                    <div className="w-100">
                        <div className="insc-inputs" id="admin-search">
                            <input
                                className="insc-input"
                                type="text"
                                placeholder="Rechercher un utilisateur..."
                                onChange={(e) =>
                                    setSearch(() => e.target.value)
                                }
                            />
                        </div>
                    </div>

                    <div
                        className="w-100"
                        style={{ paddingInline: 17, display: 'flex', gap: 20 }}
                    >
                        <div className="insc-inputs">
                            <span className="inputs-title-insc ">
                                Ordonner par (primaire)
                            </span>
                            <Select
                                options={filterOptions}
                                placeholder=""
                                onChange={(e) => {
                                    setFilterPrimary(
                                        e as unknown as {
                                            value: string
                                            label: string
                                        }
                                    )
                                }}
                                value={filterPrimary}
                            />
                        </div>
                        <div className="insc-inputs">
                            <span className="inputs-title-insc">
                                Ordonner par (secondaire)
                            </span>
                            <Select
                                options={filterOptions}
                                placeholder=""
                                onChange={(e) => {
                                    setFilterSecondary(
                                        e as unknown as {
                                            value: string
                                            label: string
                                        }
                                    )
                                }}
                                value={filterSecondary}
                            />
                        </div>
                    </div>

                    <Table
                        titles={constAdminUsers}
                        data={filteredData}
                        color="--orange100"
                        pagination={Pagination}
                        filter={() => {}}
                        page={pages}
                        actions={{
                            modify: {
                                action: (participant) =>
                                    modifyModal(
                                        participant,
                                        setModalType,
                                        setModalActive,
                                        setTriggerEffect,
                                        triggerEffect,
                                        'admin'
                                    ),
                            },
                            // delete: {
                            //     action: Delete,
                            // },
                        }}
                        margin="30"
                    />
                </div>
                <Modal
                    isActive={ModalActive}
                    setActive={async () => {
                        setModalActive(false)
                        setTimeout(() => setTriggerEffect(!triggerEffect), 500)
                    }}
                    children={modalType}
                ></Modal>
            </div>
        </>
    )
}
export default AdminUsers
