import axios from '../axios/axios'
import jwt_decode from 'jwt-decode'

import {
    iEstablishementUser,
    iIndividualUser,
    iSchoolUser,
} from '../types/user'
import { eroles } from '../types/enums'

class AuthService {
    async register(user: iIndividualUser | iSchoolUser | iEstablishementUser) {
        return await axios.post('authentification/register', {
            user,
        })
    }

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    logout(user?: any) {
        if (user) axios.post(`authentification/log-out/${user.userData.id}`)

        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        axios.defaults.headers.common['Authorization'] = ''
    }

    async changePassword(userId: string) {
        return await axios.post('user/change-password', {
            userId,
        })
    }

    decodedToken() {
        const accessToken = localStorage.getItem('accessToken') || undefined

        if (accessToken === undefined) {
            return [undefined, undefined, undefined]
        }

        type JWTdecoded = {
            id: string
            role: eroles
            iat: number
            exp: number
        }
        const decoded: JWTdecoded = jwt_decode(accessToken)
        //console.log(decoded)
        return [decoded.id, decoded.role, decoded.exp]
    }

    decodedRefreshToken() {
        const accessToken = localStorage.getItem('refreshToken') || undefined

        if (accessToken === undefined) {
            return [undefined, undefined, undefined]
        }

        type JWTdecoded = {
            id: string
            role: eroles
            iat: number
            exp: number
        }
        const decoded: JWTdecoded = jwt_decode(accessToken)
        //console.log(decoded)
        return [decoded.id, decoded.role, decoded.exp]
    }

    setAccessTokens(accessToken: string) {
        localStorage.setItem('accessToken', accessToken)
    }
    setRefreshTokens(refreshToken: string) {
        localStorage.setItem('refreshToken', refreshToken)
    }
}
const authService = new AuthService()
export default authService
