import { useEffect, useState } from 'react'
import axios from '../../../axios/axios'
import '../adminEtablissement.css'
import Input from '../../../components/forms/common/Input'
import { toast } from 'react-toastify'

function LanguagesConfig() {
    interface Language {
        language: string
    }
    const [languages, setLanguages] = useState<Language[]>([])
    useEffect(() => {
        getAllLanguages().then((res) => {
            setLanguages(res.data)
            console.log(res.data)
        })
    }, [])
    async function getAllLanguages() {
        // Get all categories
        return await axios.get('/languages')
    }
    async function updateLanguage(indexState: number, newLang: Language) {
        // Update the state
        if (newLang.language === '') {
            toast.error('Le nom de la langue ne peut pas être vide')
            return
        }
        const newLanguages = languages
        const languageOriginalName = newLanguages[indexState].language
        newLanguages[indexState] = newLang
        setLanguages([...newLanguages])

        // Update a category
        await axios.patch(`/languages`, {
            originalName: languageOriginalName,
            ...newLang,
        })
    }

    async function addLanguage() {
        // Add a category
        const newLanguage = languages
        // if no 'new language' is added, add one
        if (
            newLanguage.length === 0 ||
            newLanguage[newLanguage.length - 1].language !== 'New language'
        ) {
            newLanguage.push({ language: 'New language' })
            setLanguages([...newLanguage])
            await axios.post(`/languages`, {
                language: 'New language',
            })
        } else {
            toast.error('Une nouvelle langue est déjà en cours de création')
        }
    }
    return (
        <>
            <h3>Langues</h3>
            <div
                style={{
                    fontStyle: 'italic',
                    fontSize: 10,

                    marginBottom: 10,
                }}
            >
                Ajoutez et modifiez des catégories (Il n'est pas possible de
                supprimer une langue !){' '}
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    // gap: 10,
                }}
            >
                {languages.map((lang, i) => (
                    <div
                        style={{
                            display: 'flex',
                            gap: 10,
                        }}
                        key={i}
                    >
                        <Input
                            value={lang.language}
                            onChange={(e) =>
                                updateLanguage(i, {
                                    ...lang,
                                    language: e.target.value,
                                })
                            }
                        />
                    </div>
                ))}
                <button
                    className={'buttons-blue blue-admin button-input-size'}
                    onClick={() => addLanguage()}
                >
                    Nouvelle langue
                </button>
            </div>
        </>
    )
}
export default LanguagesConfig
