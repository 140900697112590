import axios from '../axios/axios'
import { PageObject } from '../components/Table'
import { typeFilterFormat } from '../types/IfilterFormat'

export function getUsers(
    pages: PageObject,
    filterPrimary: typeFilterFormat,
    filterSecondary: typeFilterFormat,
    selectedSession: typeFilterFormat,
    year: number
) {
    return axios.get(
        '/users?limit=15&page=' +
            (pages.actual ?? '1') +
            (filterPrimary ? '&sortBy=' + filterPrimary.value + ':ASC' : '') +
            (filterSecondary
                ? '&sortBy=' + filterSecondary.value + ':ASC'
                : '') +
            (selectedSession && selectedSession?.value !== ''
                ? '&filter.results.questionnaireId=' + selectedSession.value
                : '') +
            (year ? '&filter.registrations.year=' + year : '')
    )
}
