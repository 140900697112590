import { Navigate } from "react-router-dom"
import AuthService from "./AuthService"


// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ProtectedRoute(props: any) {
  const tokenPayload = AuthService.decodedToken()
  //console.log("protect")

  if (!props.allowedRoles.includes(tokenPayload[1]) && tokenPayload[1] !== "undefined") {
    return <Navigate to="/error" />
  } else if (tokenPayload && props.allowedRoles.includes(tokenPayload[1])) {
    return props.children
  } else {
    localStorage.setItem("redirectTo", window.location.pathname)
    return <Navigate to="/Connexion" />
  }
}
export default ProtectedRoute