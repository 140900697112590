import './modal.css'
//eslint-disable-next-line
function Modal({
    isActive,
    children,
    setActive,
    style,
}: {
    isActive: boolean
    style?: React.CSSProperties
    children: string | JSX.Element | JSX.Element[] | null
    setActive: (arg: boolean) => void
}) {
    if (isActive) {
        return (
            <div id="modal-window" style={style}>
                <div id="modal-content">
                    <svg
                        style={{ cursor: 'pointer', top: 15 }}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="3"
                        stroke="currentColor"
                        id="cross-modal"
                        onClick={() => setActive(false)}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                    {children}
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default Modal
