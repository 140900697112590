import axios from '../../axios/axios'
import { useEffect, useState } from 'react'
import NavbarAdmin from '../../components/NavbarAdmin'
import Table, { TableStyle, PageObject } from '../../components/Table'
import './adminEtablissement.css'
import UpdateExamCenter from '../../components/modals/UpdateExamCenter'
import Modal from '../../components/Modal'
import * as XLSX from 'xlsx'
import AddExamCenter from '../../components/modals/AddExamCenter'
import { iSchoolEditUser, iUser } from '../../types/user'
import { eroles } from '../../types/enums'
import AssignUsersToExamCenters from '../../components/modals/AssignUsersToExamCenters'
import IQuestionnaire from '../../types/IQuestionnaire'
export type ExamCenterExport = {
    name: string
    seats: number
    city: string
    zipCode: string
    rneCode: string
    email: string
}
export type ExamCenter = {
    id?: string
    name?: string
    rneCode?: string
    street?: string
    city?: string
    zipCode?: string
    country?: string
    seats?: number
    typeCenter?: {
        year?: number
        center?: number
    }
    typeCenters: {
        year: number
        examCenterId: string
        center: number
    }[]
    contact: {
        firstname: string
        lastname: string
        email: string
        phone: string
    }
    user?: iSchoolEditUser
}

export async function getAllExamCenters() {
    return axios
        .get('/examcenter?limit=0')
        .then((res) => {
            return res.data.data
        })
        .catch((error) => {
            console.error(error)
            return {}
        })
}

function AdminExam() {
    const [pages, setPages] = useState<PageObject>({
        pages: 1,
        actual: 1,
    })

    const [triggerEffect, setTriggerEffect] = useState(false)
    const [search, setSearch] = useState('')

    useEffect(() => {
        document.title = "Centres d'épreuve"

        axios
            .get(
                `/examcenter?limit=10&page=` +
                    (pages.actual ?? '1') +
                    (search !== '' ? '&search=' + search : '')
            )
            .then((res) => {
                setPages({
                    pages: res.data.meta.totalPages,
                    actual:
                        res.data.meta.totalPages >= res.data.meta.currentPage
                            ? res.data.meta.currentPage
                            : res.data.meta.totalPages,
                })
                setData(res.data.data)
                setFilteredData(res.data.data)
            })
        //console.log(data)
    }, [pages.actual, triggerEffect, search])

    const [userId, setUserId] = useState('')
    const [ModalActive, setModalActive] = useState(false)
    const [modalType, setModalType] = useState<JSX.Element | string | null>(
        null
    )
    const [data, setData] = useState<ExamCenter[] | null>(null)
    const [newExamCenter, setNewExamCenter] = useState<ExamCenter>(
        {} as ExamCenter
    )
    const [filteredData, setFilteredData] = useState<ExamCenter[] | null>(null)

    const [dataToSend, setDataToSend] = useState<ExamCenter | null>(null)

    const testTitles: TableStyle[] = [
        { name: 'Nom', isSortable: true, filters: null, index: 'name' },
        {
            name: 'nbPlaces',
            isSortable: false,
            filters: null,
            index: 'seats',
        },
        {
            name: 'Ville',
            isSortable: false,
            filters: null,
            index: 'city',
        },
        {
            name: 'Code Postal',
            isSortable: false,
            filters: null,
            index: 'zipCode',
        },
        { name: 'RNE', isSortable: true, filters: null, index: 'rneCode' },
        {
            name: 'email',
            isSortable: true,
            filters: null,
            index: 'user.contact.email',
        },
        /*{ name: 'Identifiant', isSortable: false, filters: null, index: 'id' },*/
    ]

    const testPages: PageObject = {
        pages: 1,
        actual: 1,
    }

    const Pagination = (page: number) => {
        setPages({
            pages: pages.pages,
            actual: page,
        })
    }

    const filter = (filter: string, value: string) => {
        //console.log(filter)
    }

    const addNewCenter = () => {
        setModalType('addCenter')
        setModalActive(true)
    }

    function createExamCenter(dataSent: ExamCenter) {
        createCenter(dataSent)

        const requestBody = {
            name: dataSent?.name,
            rneCode: dataSent?.rneCode,
            seats: dataSent?.seats,
            street: dataSent?.street,
            city: dataSent?.city,
            zipCode: dataSent?.zipCode,
            country: dataSent?.country,
            typeCenter: {
                year: 2023,
                center: 0,
            },
        } as ExamCenter
        setDataToSend(requestBody)

        setModalActive(false)
    }

    useEffect(() => {
        if (userId != undefined && dataToSend != undefined) {
            axios
                .post(`/examcenter/${userId}`, dataToSend)
                .then((res: { data: unknown }) => {
                    //console.log(res.data)
                    setDataToSend(null)
                }).catch
        }
        setTimeout(() => setTriggerEffect(!triggerEffect), 500)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId])
    const [questionnaires, setQuestionnaires] = useState<IQuestionnaire[]>([])
    const [actionQuestionnaire, setActionQuestionnaire] = useState<{
        value: string
        label: string
    } | null>(null)
    useEffect(() => {
        axios.get('/questionnaire/').then((res) => {
            setQuestionnaires(
                res.data.map((q: IQuestionnaire) => ({
                    ...q,
                    value: q.previousQuestionnaireId ?? q.id,
                    label: q.name,
                }))
            )
            setActionQuestionnaire({
                value: res.data[0].id,
                label: res.data[0].name,
            })
        })
    }, [])

    function createCenter(dataSent: ExamCenter) {
        const newEstablishment = {
            id: '',
            role: eroles.USER,
            contact: dataSent.contact,
        } as iUser
        axios
            .post(`/authentification/register-admin`, newEstablishment)
            .then((res) => {
                setUserId(res.data.user.id)
            }).catch
    }

    const Export = () => {
        setModalType(
            <>
                <span>
                    Voulez-vous exporter les données des centres d'examen ?
                </span>
                <div className="buttons-modal-multiple">
                    <button
                        className="buttons-border-off admin-border-off"
                        onClick={() => setModalActive(false)}
                    >
                        Annuler
                    </button>
                    <button
                        className="buttons-blue blue-admin"
                        onClick={async () => await exportExcelFile()}
                    >
                        Confirmer
                    </button>
                </div>
            </>
        )
        setModalActive(true)
    }
    const importUsers = () => {
        setModalType(
            <AssignUsersToExamCenters
                close={() => setModalActive(false)}
                importSuccess={(msg) => {
                    console.log(msg)
                    setModalActive(false)
                }}
            />
        )
        setModalActive(true)
    }

    const exportExcelFile = async () => {
        const response = await getAllExamCenters()
        const result: {
            'Identifiant unique': string
            Nom: string
            'Nombre de place': number
            Ville: string
            'Code Postal': string
            'Code RNE': string
            'Nom du Correspondant': string
            'Prénom du Correspondant': string
            'Email du Correspondant': string
            'Téléphone du Correspondant': string
        }[] = []
        response.forEach((res: ExamCenter) => {
            result.push({
                'Identifiant unique': res.id ?? '',
                Nom: res.name ?? '',
                'Nombre de place': res.seats ?? 0,
                Ville: res.city ?? '',
                'Code Postal': res.zipCode ?? '',
                'Code RNE': res.rneCode ?? '',
                'Nom du Correspondant': res.user?.contact.lastname ?? '',
                'Prénom du Correspondant': res.user?.contact.firstname ?? '',
                'Email du Correspondant': res.user?.contact.email ?? '',
                'Téléphone du Correspondant': res.user?.contact.phone ?? '',
            })
        })
        const worksheet = XLSX.utils.json_to_sheet(result)
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Centre examens')
        XLSX.writeFile(workbook, 'Centre-examens.xlsx')
        setModalActive(false)
    }

    const Modify = (establishment: ExamCenter) => {
        //console.log(establishment.user?.id)
        setModalType(
            <>
                <UpdateExamCenter
                    close={() => setModalActive(false)}
                    confirm={async (dataToSend) =>
                        await updateEstablishment(
                            establishment.user?.id,
                            dataToSend
                        )
                    }
                    data={establishment ?? ({} as ExamCenter)}
                    initialValue={establishment}
                    selectedQuestionnaire={actionQuestionnaire?.value}
                />
            </>
        )
        setModalActive(true)
    }

    const updateEstablishment = async (
        id: string | undefined,
        dataSent: ExamCenter
    ) => {
        const requestBody = {
            name: dataSent?.name,
            rneCode: dataSent?.rneCode,
            street: dataSent?.street,
            city: dataSent?.city,
            zipCode: dataSent?.zipCode,
            country: dataSent?.country,
            seats: dataSent?.seats,
        }
        //console.log(requestBody)
        if (id != undefined) {
            await axios.patch(`/examcenter/${id}`, requestBody).then((res) => {
                //console.log(res.data)
            }).catch
        }
        setModalActive(false)
        setTimeout(() => setTriggerEffect(!triggerEffect), 500)
    }

    return (
        <>
            <div className="admin-container">
                <div className="navbar">
                    <NavbarAdmin active="centresExamen" />
                </div>

                <div className="page-body" style={{ width: '100%' }}>
                    <div
                        style={{
                            display: 'flex',
                            gap: 20,
                            justifyContent: 'center',
                            alignContent: 'baseline',
                            flexWrap: 'wrap',
                        }}
                    >
                        <div className="insc-inputs" id="admin-search">
                            <input
                                className="insc-input"
                                type="text"
                                placeholder="Rechercher un centre..."
                                onChange={(e) =>
                                    setSearch(() => e.target.value)
                                }
                            />
                        </div>
                        <button
                            className="buttons-blue blue-admin"
                            onClick={addNewCenter}
                        >
                            Ajouter un Centre d'épreuve
                        </button>
                        <button
                            className="buttons-blue blue-admin"
                            onClick={importUsers}
                        >
                            Convoquer des participants dans les centres
                        </button>
                        <button
                            className="buttons-blue blue-admin"
                            onClick={Export}
                        >
                            Exporter
                        </button>
                    </div>
                    <Table
                        titles={testTitles}
                        data={filteredData}
                        color="--orange100"
                        pagination={Pagination}
                        filter={filter}
                        page={pages}
                        actions={{
                            modify: {
                                action: Modify,
                            },
                        }}
                        margin="30"
                    />
                </div>

                {modalType === 'addCenter' ? (
                    <Modal isActive={ModalActive} setActive={setModalActive}>
                        <AddExamCenter
                            close={() => setModalActive(false)}
                            setData={() => setNewExamCenter(newExamCenter)}
                            confirm={async (dataToSend) => {
                                const response = createExamCenter(dataToSend)
                            }}
                        />
                    </Modal>
                ) : (
                    <Modal
                        isActive={ModalActive}
                        setActive={setModalActive}
                        children={modalType}
                    ></Modal>
                )}
            </div>
        </>
    )
}
export default AdminExam
