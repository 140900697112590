import './timeline.css'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const circle = (
    <svg>
        <circle r="17.5" fill="#E0E0E0" />
    </svg>
)

function Timeline({ steps, active }: { steps: Array<string>; active: string }) {
    return (
        <div
            className="insc-trans mb-5"
            id={steps.length == 1 ? 'timeline-line-1' : 'timeline-line'}
        >
            {steps.map((step, i) => {
                if (step == active) {
                    return (
                        <div key={step} className="timeline-step">
                            <div className="timeline-step-circle-active"></div>
                            <p>{step}</p>
                        </div>
                    )
                } else if (steps.indexOf(active) > i) {
                    return (
                        <div key={step} className="timeline-step">
                            <div className="timeline-step-circle-pass"></div>
                            <p>{step}</p>
                        </div>
                    )
                } else if (steps.indexOf(active) < i) {
                    return (
                        <div key={step} className="timeline-step">
                            <div className="timeline-step-circle"></div>
                            <p>{step}</p>
                        </div>
                    )
                }
            })}
        </div>
    )
}
export default Timeline
