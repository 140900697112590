export default function ConfirmIcon(props: { action: () => void }) {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => props.action()}
        >
            <g id="&#240;&#159;&#166;&#134; icon &#34;tick circle&#34;">
                <g id="Group">
                    <path
                        id="Vector"
                        d="M15.0002 30C6.72583 30 0.000244141 23.2744 0.000244141 15C0.000244141 6.72558 6.72583 0 15.0002 0C23.2747 0 30.0002 6.72558 30.0002 15C30.0002 23.2744 23.2747 30 15.0002 30ZM15.0002 2.09302C7.88397 2.09302 2.09327 7.88372 2.09327 15C2.09327 22.1163 7.88397 27.907 15.0002 27.907C22.1165 27.907 27.9072 22.1163 27.9072 15C27.9072 7.88372 22.1165 2.09302 15.0002 2.09302Z"
                        fill="#292D32"
                    />
                    <path
                        id="Vector_2"
                        d="M13.0178 19.9947C12.7387 19.9947 12.4736 19.8831 12.2783 19.6877L8.32937 15.7389C7.92472 15.3342 7.92472 14.6645 8.32937 14.2598C8.73402 13.8552 9.40379 13.8552 9.80844 14.2598L13.0178 17.4691L20.1899 10.297C20.5946 9.89237 21.2643 9.89237 21.669 10.297C22.0736 10.7017 22.0736 11.3714 21.669 11.7761L13.7573 19.6877C13.562 19.8831 13.2969 19.9947 13.0178 19.9947Z"
                        fill="#292D32"
                    />
                </g>
            </g>
        </svg>
    )
}
