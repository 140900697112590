export const filterOptions = [
    { value: '', label: 'Aucun filtre' },
    { value: 'contact.lastname', label: 'Nom' },
    {
        value: 'contact.firstname',
        label: 'Prénom',
    },
    {
        value: 'registrations.category.name',
        label: 'Catégorie',
    },
    {
        value: 'registrations.status',
        label: "Status de l'adhésion",
    },
    {
        value: 'results.grade',
        label: 'Note',
    },
    {
        value: 'results.weightedGrade',
        label: 'Coefficient',
    },
]
