import { toast } from 'react-toastify'
import axios from '../../../axios/axios'
import {
    ecategories,
    eestablishements,
    elangs,
    eroles,
    eStatus,
} from '../../../types/enums'
import * as XLSX from 'xlsx'
import { iRegistration, iRegistrationWithUser } from '../../../types/user'
import {
    iParticipantWithResultsDetails,
    Participant,
} from '../../../types/IParticipant'

export async function getAllParticipants(
    year?: string
): Promise<iRegistrationWithUser[]> {
    return axios
        .get('/registrations?limit=0' + (year ? '&filter.year=' + year : ''))
        .then((res) => {
            return res.data.data
        })
        .catch((error) => {
            console.error(error)
            return {}
        })
}

export async function confirmRegistrationUser(
    year: string | number,
    userId: string
) {
    const requestBody = {
        status: 'CONFIRMED',
    }
    await axios
        .patch(`/registrations/validate/${userId}/${year}`, requestBody)
        .then((res) => {
            console.log(res.data)
            toast.success('Confirmation effectuée')
        })
        .catch((e) => toast.error('Erreur lors de la confirmation'))
}

export async function exportExcelFile(
    setModalActive: (state: boolean) => void,
    year?: string
) {
    const response = await getAllParticipants(year)

    const result: {
        "Nom d'Utilisateur": string
        Email: string
        'Nom de famille': string
        Prénom: string
        'Date de Naissance': string
        genre: string
        Catégorie: string
        Langue: string
        Ville: string
        Téléphone: string
        'Code postal': string
        Adresse: string
        Pays: string
        Statut: eStatus
        "Autorisation au droit à l'image": string
        Communication: boolean
        'Numéro FFJM': string
        Établissement: string
        'Type établissement': string
        'Code RNE': string
        'Adresse établissement': string
        'Ville établiseement': string
        'Code Postal établiseement': string
        'Pays établissement': string
        'Nom du contact': string
        'Prénom du contact': string
        'Mail du contact': string
        note: string
        qualifié: string
        "Centre d'épreuve"?: string
        "RNE Centre d'épreuve"?: string
    }[] = []
    response.forEach((res: iRegistrationWithUser) => {
        result.push({
            "Nom d'Utilisateur": res.user.username ?? '',
            'Nom de famille': res.user.contact?.lastname ?? '',
            Email: res.user.contact?.email ?? '',
            Prénom: res.user.contact?.firstname ?? '',
            'Date de Naissance': (res.user.birthday as string) ?? '',
            genre: res.user.genre ?? '',
            Catégorie: res.category?.name ?? '',
            Langue: res.user.language?.language || '',
            Ville: res.user.contact?.city ?? '',
            Téléphone: res.user.contact?.phone ?? '',
            'Code postal': res.user.contact?.zip_code ?? '',
            Pays: res.user.contact?.country ?? '',
            Adresse: res.user.contact?.address ?? '',
            Statut: res?.status ?? 'NOT FOUND',
            "Autorisation au droit à l'image": res.user.imageCapture
                ? 'TRUE'
                : 'FALSE',
            Communication: res.user.communication ? true : false,
            'Numéro FFJM': res.user.numFFJM ?? '',
            Établissement: res?.establishment?.name ?? '',
            'Type établissement': res?.establishment?.type ?? '',
            'Code RNE': res?.establishment?.rneCode ?? '',
            'Adresse établissement': res?.establishment?.street ?? '',
            'Ville établiseement': res?.establishment?.city ?? '',
            'Code Postal établiseement': res?.establishment?.zipCode ?? '',
            'Pays établissement': res?.establishment?.country ?? '',
            'Nom du contact': res?.establishment?.user?.contact?.lastname ?? '',
            'Prénom du contact':
                res?.establishment?.user?.contact?.firstname ?? '',
            'Mail du contact': res?.establishment?.user?.contact?.email ?? '',
            note:
                res.user?.results?.reduce(
                    (acc, val, index) =>
                        year && val.questionnaire?.year === parseInt(year)
                            ? acc + ' ' + (val?.grade || '')
                            : acc,
                    ''
                ) || '',
            qualifié:
                res.user?.results?.reduce(
                    (acc, val, index) =>
                        year && val.questionnaire?.year === parseInt(year)
                            ? acc + ' ' + (val?.qualified ? 'TRUE' : 'FALSE')
                            : acc,
                    ''
                ) || '',
        })
    })
    const worksheet = XLSX.utils.json_to_sheet(result)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Participants')
    XLSX.writeFile(workbook, 'Participants.xlsx')
    setModalActive(false)
}
