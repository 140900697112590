import axios from '../axios/axios'
import { iSchoolUser } from '../types/user'
import { getDate } from './getDate'

export function addParticipant(participant: iSchoolUser) {
    axios
        .post('/users/register/', {
            role: 'user',
            birthday: getDate(participant.birthday as Date),
            contact: {
                firstname: participant.contact.firstname,
                lastname: participant.contact.lastname,
                email: participant.contact.email,
                address: participant.contact.address,
                city: participant.contact.city,
                zip_code: participant.contact.zip_code,
                country: participant.contact.country,
            },
            categoryName: participant.categoryName,
            languageName: participant.languageName,
        })
        .then((res) => {
            console.log(res.data)
        })
}
