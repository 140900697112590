import { useEffect, useState } from 'react'
import axios from '../../../axios/axios'
import NavbarAdmin from '../../../components/NavbarAdmin'
import '../adminEtablissement.css'
import Input from '../../../components/forms/common/Input'
import { toast } from 'react-toastify'

function CategoryConfig() {
    interface Category {
        name: string
        canRegister?: boolean
        canSeeQuestionnaire?: boolean
        maxQuestion?: number
    }
    const [categories, setCategories] = useState<Category[]>([])
    useEffect(() => {
        getAllCategories().then((res) => {
            setCategories(res.data)
        })
    }, [])
    async function getAllCategories() {
        // Get all categories
        return await axios.get('/categories')
    }
    async function updateCategory(indexState: number, newCat: Category) {
        // Update the state
        if (newCat.name === '') {
            toast.error('Le nom de la catégorie ne peut pas être vide')
            return
        }
        const newCategories = categories
        const categoryOriginalName = newCategories[indexState].name
        newCategories[indexState] = newCat
        setCategories([...newCategories])

        // Update a category
        await axios.patch(`/categories`, {
            originalName: categoryOriginalName,
            ...newCat,
        })
    }

    async function addCategory() {
        // Add a category
        const newCategories = categories
        newCategories.push({ name: 'New category' })
        setCategories([...newCategories])
        await axios.post(`/categories`, {
            name: 'New category',
            canRegister: false,
            canSeeQuestionnaire: false,
        })
    }
    return (
        <>
            <h3>Catégories</h3>
            <div className="font-italic text-xs mb-10">
                Ajoutez et modifiez des catégories (Il n'est pas possible de
                supprimer une catégorie !)
                <br />
                Le nombre de question est le nombre de questions pris en compte
                pour le calcul des points sur un questionnaire en ligne. Toutes
                les questions dont la position est infèrieure ou égale à ce
                nombre sont prises en compte.
                <br />
                Le paramètre "Inscription" défini si les inscriptions sont
                ouvertes pour la catégorie
                <br />
                Le paramètre "En ligne" défini si les utilisateurs de la
                catégorie peuvent voir/remplir le questionnaire en ligne
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    // gap: 10,
                }}
            >
                {categories.map((cat, i) => (
                    <div
                        style={{
                            display: 'flex',
                            gap: 10,
                        }}
                        key={i}
                    >
                        <Input
                            title="Nom de la catégorie"
                            value={cat.name}
                            onChange={(e) =>
                                updateCategory(i, {
                                    ...cat,
                                    name: e.target.value,
                                })
                            }
                        />
                        <Input
                            title="Nombre de question"
                            value={cat.maxQuestion}
                            type="number"
                            onChange={(e) =>
                                updateCategory(i, {
                                    ...cat,
                                    maxQuestion: +e.target.value,
                                })
                            }
                        />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Input
                                type="checkbox"
                                title="Inscription"
                                isChecked={cat.canRegister}
                                onChange={(e) =>
                                    updateCategory(i, {
                                        ...cat,
                                        canRegister: !cat.canRegister,
                                    })
                                }
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Input
                                type="checkbox"
                                title="En ligne"
                                isChecked={cat.canSeeQuestionnaire}
                                onChange={(e) =>
                                    updateCategory(i, {
                                        ...cat,
                                        canSeeQuestionnaire:
                                            !cat.canSeeQuestionnaire,
                                    })
                                }
                            />
                        </div>
                    </div>
                ))}
                <button
                    className={'buttons-blue blue-admin button-input-size'}
                    onClick={() => addCategory()}
                >
                    Nouvelle catégorie
                </button>
            </div>
        </>
    )
}
export default CategoryConfig
