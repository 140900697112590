import axios from '../../axios/axios'
import { useEffect, useState } from 'react'
import NavbarAdmin from '../../components/NavbarAdmin'
import Table, { TableStyle, PageObject } from '../../components/Table'
import './adminEtablissement.css'
import UpdateEstablishment from '../../components/modals/UpdateEstablishment'
import Modal from '../../components/Modal'
import { eestablishements, eStatus } from '../../types/enums'
import * as XLSX from 'xlsx'
import {
    iClub,
    iClubWithControllers,
    iEstablishementUser,
    iEstablishementWContact,
    iEstablishment,
    iRegistration,
} from '../../types/user'
import tilesAdminClubTable from '../../types/tables/constAdminClubs'
import UpdateClub from '../../components/modals/UpdateClub'

function AdminClubs() {
    const [pages, setPages] = useState<PageObject>({
        pages: 1,
        actual: 1,
    })
    const [search, setSearch] = useState('')

    const [filteredData, setFilteredData] = useState<[] | null>(null)
    const [triggerEffect, setTriggerEffect] = useState(false)
    const [selectedYear, setSelectedYear] = useState<string>('')

    useEffect(() => {
        // get current year
        axios.get('configurations/ACTIVE_YEAR').then((res) => {
            setSelectedYear(res.data.value)
        })
    }, [])
    useEffect(() => {
        document.title = 'Établissements'
        if (selectedYear)
            axios
                .get(
                    '/clubs?limit=10&page=' +
                        pages.actual +
                        (search !== '' ? '&search=' + search : '')
                )
                .then((res) => {
                    setPages({
                        pages: res.data.meta?.totalPages ?? 1,
                        actual:
                            (res.data.meta?.currentPage >
                            res.data.meta?.totalPages
                                ? res.data.meta?.totalPages
                                : res.data.meta?.currentPage) ?? 1,
                    })

                    setFilteredData(
                        res.data.data.map((data: iClubWithControllers) => {
                            console.log(data)
                            return {
                                ...data,
                                user: data.controllers[0],
                                accountStatus:
                                    data.registrations?.find(
                                        (reg) => reg.year === +selectedYear
                                    )?.status ?? eStatus.PENDING,
                            }
                        })
                    )
                })
    }, [pages.actual, triggerEffect, search, selectedYear])

    const [ModalActive, setModalActive] = useState(false)
    const [modalType, setModalType] = useState<JSX.Element | null>(null)

    const Pagination = (page: number) => {
        setPages({
            pages: pages.pages,
            actual: page,
        })
    }

    const filter = (filter: string, value: string) => {
        //console.log(filter)
    }

    const modify = (club: iClubWithControllers) => {
        setModalType(
            <>
                <UpdateClub
                    close={() => {
                        setModalActive(false)
                        setTimeout(() => setTriggerEffect(!triggerEffect), 500)
                    }}
                    confirm={async (data) => await updateClub(club.id, data)}
                    initialValue={club}
                />
            </>
        )
        setModalActive(true)
    }

    const updateClub = async (id: string, dataSent: iClub) => {
        console.log(dataSent)
        const requestBody = {
            name: dataSent?.name,
            street: dataSent?.street,
            city: dataSent?.city,
            zipCode: dataSent?.zipCode,
            country: dataSent?.country,
        }
        console.log(requestBody)
        await axios.patch('/clubs/' + id, requestBody).then((res) => {
            console.log(res.data)
        }).catch
        setModalActive(false)
        setTimeout(() => setTriggerEffect(!triggerEffect), 500)
    }

    const confirmRegistrationClub = (club: iClubWithControllers) => {
        setModalType(
            <>
                <span>
                    Êtes vous sûr de vouloir confirmer l'adhésion de ce club
                    pour cette année ?
                </span>
                <div className="buttons-modal-multiple">
                    <button
                        className="buttons-border-off admin-border-off"
                        onClick={() => setModalActive(false)}
                    >
                        Annuler
                    </button>
                    <button
                        className="buttons-blue blue-admin"
                        onClick={async () => await confirmCLub(club.id)}
                    >
                        Confirmer
                    </button>
                </div>
            </>
        )
        setModalActive(true)
    }

    const confirmCLub = async (id: string) => {
        await axios
            .patch(`/registrations/validate/club/${id}/${selectedYear}`)
            .then((res) => {
                console.log(res.data)
            }).catch
        setModalActive(false)
        setTimeout(() => setTriggerEffect(!triggerEffect), 500)
    }

    return (
        <>
            <div className="admin-container">
                <div className="navbar">
                    <NavbarAdmin active="clubs" />
                </div>
                <div className="page-body" style={{ width: '100%' }}>
                    <div className="font-italic text-xs mb-10">
                        Ici vous retrouverez l'ensemble des clubs inscrits sur
                        la plateforme. Il n'est pas possible d'en supprimer.{' '}
                        <br />
                        Le bouton "Confirmer" valide l'adhésion de l'utilisateur
                        sur l'année en cours (actuellement {selectedYear}).
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            gap: 20,
                            justifyContent: 'center',
                            alignContent: 'baseline',
                            flexWrap: 'wrap',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}
                        >
                            <div className="insc-inputs" id="admin-search">
                                <input
                                    className="insc-input"
                                    type="text"
                                    placeholder="Rechercher un club"
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <Table
                        titles={tilesAdminClubTable}
                        data={filteredData}
                        color="--orange100"
                        pagination={Pagination}
                        filter={filter}
                        page={pages}
                        actions={{
                            modify: {
                                action: modify,
                            },
                            confirm: {
                                action: confirmRegistrationClub,
                                showCondition: (row) =>
                                    row.accountStatus === eStatus.PENDING,
                            },
                        }}
                        margin="30"
                    />
                </div>
                <Modal
                    isActive={ModalActive}
                    setActive={(bool: boolean) => {
                        setModalActive(bool)
                        setTimeout(() => setTriggerEffect(!triggerEffect), 500)
                    }}
                    children={modalType}
                ></Modal>
            </div>
        </>
    )
}
export default AdminClubs
